import { connect } from 'react-redux';
import { compose } from 'redux';

import subscriptionActionCreators from '_store/_actions/subscription';
import companyActionCreators from '_store/_actions/company';

import InvoiceDetails from './InvoiceDetails';

const mapStateToProps = state => ({
  subscription: state.subscription.companySubscription,
});

const mapDispatchToProps = {
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onFetchActiveCompany: companyActionCreators.getActiveCompany,
  onUpdateStoreSubscription: subscriptionActionCreators.updateStoreSubscription,
  onRefreshPaymentData: subscriptionActionCreators.refreshPaymentData,
  onFetchInvoice: subscriptionActionCreators.getInvoice,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InvoiceDetails);
