import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import { Col, Form, Row } from 'react-bootstrap';
import { LuArrowRight, LuCreditCard } from 'react-icons/lu';
import { FaBarcode } from 'react-icons/fa';
import { IoArrowBack } from 'react-icons/io5';
import classNames from 'classnames';

import { sanitizePlanName } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { Button, SimpleAlert } from '_components/_core';

import { BillingInformationModal } from '../..';
import {
  CustomCard,
  CustomCardBody,
  Value,
} from '_components/Settings/components/SettingsV2/styles';

import { ButtonToggle, Details } from '../styles';

const CYCLE_MONTHS = {
  1: 'Mensal',
  6: 'Semestral',
  12: 'Anual',
};

function SelectPaymentMethod({
  planId,
  plans,
  subscription,
  onCreateCheckout,
  onBackToPlans,
  onChangeView,
  onUpdateStoreSubscription,
}) {
  const stripe = useStripe();

  const [isLoading, setIsLoading] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('CREDIT_CARD');

  const plan = plans.find(p => p.id === planId);

  const hasDiscount = plan.cycle_months !== 1;

  const handleSubmitCheckout = useCallback(() => {
    setIsLoading(true);

    const successCallback = async data => {
      if (selectedPaymentMethod === 'CREDIT_CARD') {
        setIsLoading(false);

        stripe.redirectToCheckout({ sessionId: data.session_id });

        return;
      }

      if (selectedPaymentMethod === 'BOLETO') {
        const { invoice, subscription } = data;

        onUpdateStoreSubscription(subscription);
        onChangeView('INVOICE_DETAILS', {
          invoice,
          invoiceId: invoice.id,
          originView: 'DEFAULT',
        });

        setIsLoading(false);

        return;
      }

      setIsLoading(false);
    };

    const errorCallback = () => {
      setIsLoading(false);
    };

    const params = {
      plan_id: planId,
      payment_method: selectedPaymentMethod,
    };

    onCreateCheckout(params, successCallback, errorCallback);
  }, [onCreateCheckout, planId, selectedPaymentMethod, stripe, onChangeView]);

  const totals = useMemo(() => {
    const selectedPlan = plans.find(p => p.id === planId);

    const monthlyPlan = plans.find(
      p =>
        p.type === selectedPlan.type &&
        selectedPlan.mnemonic_short === p.mnemonic_short &&
        p.cycle_months === 1,
    );

    const totalMonthly = monthlyPlan.price * selectedPlan.cycle_months;

    const discount = plan.price - totalMonthly;
    const discountPercentage = discount / selectedPlan.price;

    return {
      totalPrice: selectedPlan.price,
      totalMonthly,
      discount,
      discountPercentage,
    };
  }, [plans, plan, planId]);

  const renderPaymentButton = useCallback(() => {
    switch (selectedPaymentMethod) {
      case 'CREDIT_CARD':
        return (
          <div className="mt-3 d-flex flex-column justify-content-center align-items-end">
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              variant="success-2"
              className="d-flex justify-content-center align-items-center"
              onClick={handleSubmitCheckout}
            >
              <LuCreditCard className="mr-2" />
              Pagar com cartão
              <LuArrowRight className="ml-2" />
            </Button>
            <p className="p-0 m-0 mt-3">
              Você será redirecionado para preencher seus dados do cartão.
            </p>
          </div>
        );
      case 'BOLETO':
        return (
          <div className="mt-3 d-flex flex-column justify-content-center align-items-end">
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              variant="success-2"
              className="d-flex justify-content-center align-items-center"
              onClick={handleSubmitCheckout}
            >
              <FaBarcode className="mr-2" />
              Pagar com boleto
              <LuArrowRight className="ml-2" />
            </Button>
            <p className="p-0 m-0 mt-3">
              Um boleto será gerado com vencimento para 3 dias.
            </p>
          </div>
        );
      default:
        return null;
    }
  }, [selectedPaymentMethod, handleSubmitCheckout, isLoading]);

  const paymentOptions = useMemo(
    () => [
      {
        id: 'CREDIT_CARD',
        title: 'Cartão de crédito',
        description: 'Aprovação imediata',
        icon: <LuCreditCard size="1.4em" className="mr-3" />,
      },
      {
        id: 'BOLETO',
        title: 'Boleto bancário',
        description: 'Aprovação em até 3 dias úteis',
        icon: <FaBarcode size="1.4em" className="mr-3" />,
      },
    ],
    [],
  );

  const isBillingInfoValid = useCallback(billing_info => {
    const {
      name,
      document_type,
      document_number,
      email_primary,
      address_street,
      address_number,
      address_district,
      address_city,
      address_city_ibge,
      address_state,
      address_state_ibge,
      address_zip_code,
    } = billing_info || {};

    if (
      !name ||
      !document_type ||
      !document_number ||
      !email_primary ||
      !address_zip_code ||
      !address_number ||
      !address_street ||
      !address_district ||
      !address_state ||
      !address_state_ibge ||
      !address_city ||
      !address_city_ibge
    ) {
      return false;
    }

    return true;
  }, []);

  const InfoItem = ({
    title,
    content,
    onClick,
    contentStyle,
    isLoading,
    isLoadingMessage,
  }) => (
    <>
      <Value className="w-100 d-flex justify-content-between align-items-center">
        {title}
        {onClick && (
          <Button
            variant="link"
            size="small"
            className={classNames({
              'm-0 p-0 ml-auto d-flex justify-content-center align-items-center': true,
              'text-muted': isLoading,
            })}
            disabled={isLoading}
            onClick={onClick}
          >
            {isLoading ? isLoadingMessage : 'Alterar'}
          </Button>
        )}
      </Value>
      <Value variant="light" className="mt-1" style={contentStyle}>
        {content}
      </Value>
    </>
  );

  return (
    <>
      <BillingInformationModal
        isVisible={isAddressModalOpen}
        onModalToggle={() => setIsAddressModalOpen(!isAddressModalOpen)}
      />
      <div className="mt-3 d-flex align-items-center">
        <Button
          variant="link"
          className="m-0 p-0 mr-3 d-flex justify-content-center align-items-center"
          onClick={onBackToPlans}
        >
          <IoArrowBack className="mr-1" />
          Voltar
        </Button>
      </div>
      <Row className="mt-3">
        <Col sm={12}>
          <h4 className="m-0 mb-3">
            Pagamento do plano {sanitizePlanName(plan.name)}{' '}
            {CYCLE_MONTHS[plan.cycle_months]}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <CustomCard>
            <CustomCardBody>
              <InfoItem
                title="Dados de cobrança"
                contentStyle={{ flex: 1, width: '100%' }}
                content={
                  <div className="w-100">
                    <span className="d-flex align-items-center justify-content-start">
                      <span style={{ flex: 1 }} className="mt-2 mb-1">
                        <span>{subscription.billing_info.name}</span>
                        <br />
                        <span>
                          {FORMATTERS.CPF_CNPJ(subscription.billing_info.document_number)}
                        </span>
                        <br />
                        {subscription.billing_info.email_primary}
                        <div
                          className="w-100 pt-2"
                          style={{
                            flex: 1,
                          }}
                        >
                          <span>
                            {subscription.billing_info.address_street},{' '}
                            {subscription.billing_info.address_number}
                            {subscription.billing_info.address_complement
                              ? ` - ${subscription.billing_info.address_complement}`
                              : ``}
                          </span>
                          <br />
                          <span>
                            {subscription.billing_info.address_city} -{' '}
                            {subscription.billing_info.address_state} <br />
                            {FORMATTERS.CEP(subscription.billing_info.address_zip_code)}
                          </span>
                        </div>
                      </span>
                    </span>
                    {!isBillingInfoValid(subscription.billing_info) && (
                      <SimpleAlert variant="warning">
                        <Button
                          variant="link"
                          className="p-0 m-0"
                          onClick={() => setIsAddressModalOpen(true)}
                        >
                          Complete os dados de cobrança para continuar.
                        </Button>
                      </SimpleAlert>
                    )}
                  </div>
                }
                onClick={() => setIsAddressModalOpen(true)}
              />
            </CustomCardBody>
          </CustomCard>
          <CustomCard className="mt-3">
            <CustomCardBody>
              <InfoItem
                title="Resumo e pagamento"
                contentStyle={{ flex: 1, width: '100%', paddingTop: '0.8rem' }}
                content={
                  <>
                    <div
                      style={{
                        backgroundColor: '#f8f9fa',
                        padding: '1.3rem',
                        borderRadius: '8px',
                        border: '1px solid #e9ecef',
                        paddingBottom: '0.4rem',
                        fontSize: '1rem',
                        color: '#252F4A',
                      }}
                    >
                      <Details>
                        <li>
                          <span className="d-flex justify-content-center align-items-center">
                            {`Plano ${sanitizePlanName(plan.name)} ${CYCLE_MONTHS[plan.cycle_months]}`}
                            <Button
                              variant="link"
                              className="m-0 p-0 d-flex justify-content-center align-items-center ml-1"
                              onClick={onBackToPlans}
                            >
                              alterar
                            </Button>
                          </span>
                          <span>{FORMATTERS.NUMBER(totals.totalMonthly)}</span>
                        </li>
                        {hasDiscount && (
                          <li>
                            <span>Desconto</span>
                            <span className="text-success">
                              {FORMATTERS.NUMBER(totals.discount)}
                            </span>
                          </li>
                        )}
                      </Details>
                      <Details>
                        <li
                          style={{ borderTop: '1px solid #dee2e6', paddingTop: '0.5rem' }}
                        >
                          <span>Total</span>
                          <span>
                            <strong>{FORMATTERS.NUMBER(plan.price)}</strong>
                          </span>
                        </li>
                      </Details>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      {paymentOptions.map((option, index) => (
                        <ButtonToggle
                          variant="default"
                          className="d-flex flex-row justify-content-start align-items-center mt-3"
                          onClick={() => setSelectedPaymentMethod(option.id)}
                          style={{
                            marginLeft: index > 0 ? '1rem' : 0,
                          }}
                        >
                          <div className="d-flex">
                            {option.icon}
                            <h6 className="m-0">{option.title}</h6>
                          </div>
                          <Form.Check
                            className="ml-auto"
                            type="radio"
                            checked={selectedPaymentMethod === option.id}
                          />
                        </ButtonToggle>
                      ))}
                    </div>
                    <div className="float-right">{renderPaymentButton()}</div>
                  </>
                }
              />
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}

SelectPaymentMethod.propTypes = {
  planId: PropTypes.string.isRequired,
  plans: PropTypes.array.isRequired,
  onCreateCheckout: PropTypes.func.isRequired,
  onBackToPlans: PropTypes.func.isRequired,
  onFetchSubscription: PropTypes.func.isRequired,
  onFetchCompanies: PropTypes.func.isRequired,
  onFetchActiveCompany: PropTypes.func.isRequired,
  onChangeView: PropTypes.func.isRequired,
  onUpdateStoreSubscription: PropTypes.func.isRequired,
};

export default SelectPaymentMethod;
