import confirmDialog from 'helpers/confirmDialog';

import alertActionCreators from '../alert.actions';
import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const removeUserFromCompany = (userId, userEmail, companyName, callback) => {
  return dispatch => {
    const message = `Tem certeza que deseja remover o usuário <strong>${userEmail}</strong> da empresa <strong>${companyName}</strong>?`;

    confirmDialog.warning({
      title: 'Remover usuário da empresa',
      message: message,
      confirmButtonText: 'Sim, remover usuário',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(userId));

        service
          .removeUserFromCompany(userId)
          .then(() => {
            dispatch(success(userId));

            dispatch(
              alertActionCreators.success(
                'O usuário foi removido da empresa com sucesso!',
              ),
            );

            if (callback) {
              callback();
            }
          })
          .catch(error => {
            failure(error);
          });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return { type: constants.REMOVE_USER_FROM_COMPANY_REQUEST, payload: { id } };
  }
  function success(id) {
    return {
      type: constants.REMOVE_USER_FROM_COMPANY_SUCCESS,
      payload: {
        id,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.REMOVE_USER_FROM_COMPANY_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default removeUserFromCompany;
