import styled from 'styled-components';

import { Button } from '_components/_core';

export const SectionHeader = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
  color: var(--accent-color);
  font-weight: 600;
`;

export const StyledFormWrapper = styled.div`
 
`;

export const StyledNavButton = styled(Button)`
  border-radius: 8px;
  padding: 14px 8px;
  font-size: 14px;

  &.main {
    border-color: transparent !important;
    background-color: #38a915 !important;
  
    &:hover {
      background-color: #237b0a !important;
    }
  }
`;

export const StyledSmallText = styled.small`
  padding-left: 8px;
`;

export const StyledLink = styled.a`
  text-decoration: underline;
`;
