import { DateTime } from 'luxon';

const getDueDateDetails = invoice => {
  if (!invoice) {
    return {};
  }

  const { due_date, status } = invoice;

  const isOverdue = status === 'overdue';
  let message = '';
  let variant = 'info';

  if (isOverdue) {
    message = `(vencida ${DateTime.fromISO(due_date).setLocale('pt-BR')})`;
    variant = 'danger';
  } else {
    message = `(vence ${DateTime.fromISO(due_date).setLocale('pt-BR').toRelative()})`;
  }

  return {
    isOverdue,
    message,
    variant,
  };
};

export default getDueDateDetails;
