const getSubscriptionMaxUsers = subscription => {
  if (!subscription) {
    return 0;
  }

  const { plan, addons } = subscription;
  const { features } = plan;
  const { max_users } = features;

  const usersAddons = addons.filter(addon => addon.product_type === 'ADDITIONAL_USER');

  const maxUsers = usersAddons.reduce((acc, addon) => acc + addon.quantity, max_users);

  return {
    total: maxUsers,
    normal: max_users,
    additional: usersAddons.reduce((acc, addon) => acc + addon.quantity, 0),
  };
};

export default getSubscriptionMaxUsers;
