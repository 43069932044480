import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FcSettings } from 'react-icons/fc';
import { IoLogOutOutline, IoSettingsOutline } from 'react-icons/io5';

import { getMenuItems } from 'helpers';
import { Button } from '_components/_core';
import { MenuItems } from './components';

import {
  CustomButton,
  CustomFilterButton,
  FilterMenu,
  Footer,
  StyledDropdown,
} from './styles';

const SettingsMenu = ({
  id,
  className,
  isMobile,
  onLogout,
  variant,
  user,
  onOpenStateChange,
  isMigrated,
  activeCompany,
}) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const isManager = useMemo(() => {
    if (!isMigrated) {
      if (!user) {
        return false;
      }

      return user.manager_id === null;
    }

    if (!activeCompany) {
      return false;
    }

    const { manager } = activeCompany;

    return manager.id === user.id;
  }, [user, activeCompany, isMigrated]);

  const isPersonalAccountType = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.manager_id === null && user.document_type === 'PF';
  }, [user]);

  const handleToggle = useCallback(
    (isOpen, _ev, metadata) => {
      if (metadata && metadata.source === 'select') {
        setOpen(true);
        return;
      }

      setOpen(isOpen);
      onOpenStateChange(isOpen);
    },
    [onOpenStateChange],
  );

  const handleNavigate = useCallback(() => {
    setOpen(false);
    onOpenStateChange(false);
  }, [onOpenStateChange]);

  const handleNavigateMobile = useCallback(() => {
    history.push('/configuracoes');
  }, [history]);

  const menuItems = useMemo(() => {
    const params = {
      user,
      isManager,
      isPersonalAccountType,
      isMigrated,
      activeCompany,
    };

    return getMenuItems(params);
  }, [user, isManager, isPersonalAccountType, isMigrated, activeCompany]);

  if (isMigrated) {
    return (
      <>
        {variant === 'navbar' && (
          <CustomFilterButton onClick={handleNavigateMobile} variant="link">
            <IoSettingsOutline size="1.3em" />
          </CustomFilterButton>
        )}
        {variant === 'sidebar' && (
          <CustomButton
            as={Dropdown.Toggle}
            variant="link"
            onClick={handleNavigateMobile}
          >
            <span
              onClick={e => {
                e.preventDefault();
              }}
            >
              <div>
                <FcSettings size="1.5em" />
              </div>
              <p>Configurações</p>
            </span>
          </CustomButton>
        )}
      </>
    );
  }

  return (
    <>
      <StyledDropdown
        id={id}
        show={open}
        onToggle={handleToggle}
        className={className}
        drop={variant === 'sidebar' ? 'right' : 'down'}
      >
        {variant === 'navbar' && (
          <CustomFilterButton variant="link" as={Dropdown.Toggle}>
            <IoSettingsOutline size="1.3em" />
          </CustomFilterButton>
        )}
        {variant === 'sidebar' && (
          <CustomButton
            as={Dropdown.Toggle}
            variant="link"
            onClick={() => {
              setOpen(!open);
              onOpenStateChange(!open);
            }}
          >
            <span
              onClick={e => {
                e.preventDefault();
              }}
            >
              <div>
                <FcSettings size="1.5em" />
              </div>
              <p>Configurações</p>
            </span>
          </CustomButton>
        )}
        <FilterMenu variant={variant} isMobile={isMobile}>
          <MenuItems
            isMobile={isMobile}
            user={user}
            menuItems={menuItems}
            onLogout={onLogout}
            onNavigate={handleNavigate}
            columns={2}
          />
          <Footer>
            <Button
              variant="link"
              className="m-0 p-0 pt-2 pb-2 d-flex justify-content-center align-items-center text-muted"
              onClick={onLogout}
            >
              <IoLogOutOutline size="1.3em" className="mr-2" />
              Sair do sistema
            </Button>
          </Footer>
        </FilterMenu>
      </StyledDropdown>
    </>
  );
};

export default SettingsMenu;

SettingsMenu.defaultProps = {
  id: null,
  className: null,
  isMobile: false,
  variant: 'navbar',
  user: null,
  onOpenStateChange: () => {},
  isMigrated: false,
};

SettingsMenu.propTypes = {
  isMobile: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['navbar', 'sidebar']),
  user: PropTypes.object,
  onOpenStateChange: PropTypes.func,
  name: PropTypes.string,
  isMigrated: PropTypes.bool,
};
