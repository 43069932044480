import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import groupBy from 'lodash/groupBy';

import {
  IconWrapper,
  MenuContainer,
  MenuItem,
  MenuItemsContainer,
  ItemTitle,
} from './styles';
import { LuArrowUpRight } from 'react-icons/lu';

const MenuItems = ({ menuItems, isMobile, columns, onNavigate }) => {
  const history = useHistory();
  const location = useLocation();

  const handleNavigate = useCallback(
    item => {
      onNavigate(item.id);
    },
    [onNavigate],
  );

  const renderIcons = useCallback(
    items => {
      const groupedBySection = groupBy(items, 'section');

      return (
        <>
          {Object.keys(groupedBySection).map((section, index) => (
            <>
              <MenuItemsContainer
                section={section}
                columns={columns}
                isMobile={isMobile}
                key={index}
              >
                {groupedBySection[section].map((item, index) => (
                  <MenuItem
                    key={index}
                    active={location.pathname.includes(item.id)}
                    onClick={() => {
                      history.push(item.link);

                      handleNavigate(item);
                    }}
                  >
                    <IconWrapper bgColor={item.iconBgColor}>
                      <item.iconComponent size="1em" color={item.iconColor} />
                    </IconWrapper>
                    <div className="d-flex flex-column ml-3">
                      <ItemTitle>{item.name}</ItemTitle>
                    </div>
                    {item.isExternal && <LuArrowUpRight className="ml-2" />}
                  </MenuItem>
                ))}
              </MenuItemsContainer>
              {index < Object.keys(groupedBySection).length - 1 && (
                <hr
                  style={{
                    flex: 1,
                    width: '100%',
                  }}
                />
              )}
            </>
          ))}
        </>
      );
    },
    [handleNavigate, history, isMobile, columns, location],
  );

  const userMenuItems = useMemo(() => {
    return menuItems.filter(item => item.entry === 'is_personal');
  }, [menuItems]);

  const companyMenuItems = useMemo(() => {
    return menuItems.filter(item => item.entry === 'is_company');
  }, [menuItems]);

  return (
    <MenuContainer>
      <h5 className="m-0 ml-3 mt-3">Sobre você</h5>
      {renderIcons(userMenuItems)}
      <h5 className="m-0 ml-3 mt-3">Sobre a empresa</h5>
      {renderIcons(companyMenuItems)}
    </MenuContainer>
  );
};

MenuItems.defaultProps = {
  isMobile: false,
  columns: 1,
};

MenuItems.propTypes = {
  variant: PropTypes.shape({
    companyIconColor: PropTypes.string,
    personalIconColor: PropTypes.string,
    companyBgColor: PropTypes.string,
    personalBgColor: PropTypes.string,
  }),
  user: PropTypes.object.isRequired,
  menuItems: PropTypes.array.isRequired,
  onNavigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MenuItems;
