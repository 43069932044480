import React from 'react';
import PropTypes from 'prop-types';

import Card from '../Card';
import Table from '../Table';

import { StyledCardBody } from './styles';

const TableLookup = React.forwardRef(
  (
    {
      data,
      columns,
      defaultColumns,
      topDistance,
      isLoading,
      pagination,
      selectedItems,
      children,
      hover,
      striped,
      ...restProps
    },
    ref,
  ) => (
    <div ref={ref}>
      <Card>
        <StyledCardBody className="table-responsive" topDistance={topDistance} noPadding>
          {children && children}
          {!children && (
            <Table
              data={data}
              columns={columns}
              defaultColumns={defaultColumns}
              responsive
              striped={striped}
              hover={hover}
              isLoading={isLoading}
              pagination={pagination}
              selectedItems={selectedItems}
              {...restProps}
            />
          )}
        </StyledCardBody>
      </Card>
    </div>
  ),
);

export default TableLookup;

TableLookup.defaultProps = {
  data: [],
  defaultColumns: null,
  topDistance: null,
  isLoading: false,
  selectedItems: [],
  children: null,
  hover: true,
  striped: true,
};

TableLookup.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  defaultColumns: PropTypes.array,
  topDistance: PropTypes.string,
  isLoading: PropTypes.bool,
  pagination: PropTypes.object,
  selectedItems: PropTypes.array,
  children: PropTypes.node,
  hover: PropTypes.bool,
  striped: PropTypes.bool,
};
