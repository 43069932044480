import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Item } from 'react-contexify';

import Button from '_components/_core/Button';

export const Footer = styled.div`
  font-weight: 500;
  border-top: 1px solid #E8E7EA;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;

  svg {
    padding: 0;
    margin: 0;
  }

`;

export const Trigger = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 8px;

  &.active {
    text-decoration: none;
    background-color: transparent;
    border: none;
  }

  &.btn-primary.dropdown-toggle {
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
      background-color: #f5f5f5;
      text-decoration: none;
    }
  }

  &.btn-primary.dropdown-toggle:focus {
    background-color: #f5f5f5;
  }

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    text-decoration: none;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 15px;
    margin-left: 0.2rem;
    color: rgb(42, 46, 52);
    
    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin-right: 0.5rem;

    &:hover {
      cursor: pointer;
      background-color: unset;
    }
  }

  div.avatar {
    height: 24px;
    width: 24px;
    margin-right: 6px;
    font-size: .875rem;
    border-radius: .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7ebe9;
    color: rgb( 48, 90, 80 );
    font-weight: 600;
  }
`;

export const MenuItem = styled(Item)`

`;

export const LogOutButton = styled(Button)`
  margin: 0;
  padding: 4px 0px;
  padding-bottom: 0px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%; 
`;

export const StyledAvatar = styled.img`
  width: 32px;
`;

export const StyledUserName = styled.h1`
  font-size: 13px;
  font-weight: 600;
  padding-left: 1rem;
  margin-bottom: 0.1rem;
`;

export const StyledUserEmail = styled.h2`
  font-size: 12px;
  font-weight: 400;
  padding-left: 1rem;
  margin-bottom: 0;
`;
