import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsPlus } from 'react-icons/bs';
import { LuLock, LuMailQuestion, LuTrash, LuUserCheck } from 'react-icons/lu';
import { FaPlus } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { isUserMigrated, useMediaQuery } from 'helpers';
import {
  Button,
  FastFilterSelect,
  PageHeader,
  TableLookup,
  Tag,
} from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { UsersModal, Permissions } from './components';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

import {
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
  StyledTable,
  UserDetails,
  UserEmail,
  UserName,
} from './styles';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'name', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function Users({
  activeCompany,
  total,
  users,
  user,
  isCompact,
  invitations,
  onFetchUsers,
  onDeleteUser,
  onFetchInvitations,
  onDeleteInvitation,
  onRemoveUserFromCompany,
}) {
  const { pagination, onPageChange, onPageSizeChange } = useTable({
    ...tableConfig,
    data: users,
    onFetchData: onFetchUsers,
  });

  const { isMobile } = useMediaQuery();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});

  const isMigrated = useMemo(() => {
    return isUserMigrated(user);
  }, [user]);

  useEffect(() => {
    if (!isMigrated) {
      return;
    }

    onFetchInvitations();
  }, [onFetchInvitations, isMigrated, activeCompany]);

  const handleDeleteUser = useCallback(
    id => {
      onDeleteUser(id, () => {
        onFetchUsers(pagination);
      });
    },
    [onDeleteUser, onFetchUsers, pagination],
  );

  const handleRemoveFromCompany = useCallback(
    userId => {
      if (!activeCompany) {
        return;
      }

      const foundUser = users.find(u => u.id === userId);
      const { email } = foundUser;

      const companyName = activeCompany.trading_name;

      const callback = () => {
        onFetchUsers(pagination);
      };

      onRemoveUserFromCompany(userId, email, companyName, callback);
    },
    [onRemoveUserFromCompany, activeCompany, users, onFetchUsers, pagination],
  );

  const handleViewPermissions = useCallback(
    userId => {
      setSelectedUserId(userId);
      setSelectedUser(users.find(u => u.id === userId));
      setIsPermissionsModalOpen(true);
    },
    [users],
  );

  const handleClearUser = useCallback(() => {
    setSelectedUserId(null);
    setSelectedUser({});

    setIsPermissionsModalOpen(!isPermissionsModalOpen);
  }, [isPermissionsModalOpen]);

  const handleDeleteInvitation = useCallback(
    id => {
      onDeleteInvitation(id);
    },
    [onDeleteInvitation],
  );

  const renderInvitationsTable = useCallback(
    items => {
      if (isEmpty(items) || !activeCompany) {
        return (
          <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
            <p className="m-0 p-0">Nenhum registro encontrado</p>
          </div>
        );
      }

      return (
        <div className={classNames({ 'table-responsive': isMobile })}>
          <StyledTable width="100%" className={classNames({ 'table-hover': true })}>
            <thead>
              <tr>
                <th>E-mail</th>
                <th width="200px" className="text-center">
                  Enviado em
                </th>
                <th width="100px" className="text-center">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map(item => {
                return (
                  <tr key={item.id}>
                    <td>{item.email}</td>
                    <td className="text-center">
                      {FORMATTERS.DATE_DDMMYYYYHHMMSS(item.created_at)}
                    </td>
                    <td className="text-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <Button
                          size="sm"
                          variant="inverse-danger"
                          className="d-flex justify-content-center align-items-center"
                          onClick={() => handleDeleteInvitation(item.id)}
                        >
                          <LuTrash />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </div>
      );
    },
    [isMobile, handleDeleteInvitation, activeCompany],
  );

  const renderUsersTable = useCallback(
    users => {
      if (isEmpty(users) || !activeCompany) {
        return (
          <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
            <p className="m-0 p-0">Nenhum registro encontrado</p>
          </div>
        );
      }

      return (
        <div className={classNames({ 'table-responsive': isMobile })}>
          <StyledTable width="100%" className={classNames({ 'table-hover': true })}>
            <thead>
              <tr>
                <th>Nome / E-mail</th>
                <th width="200px" className="text-center">
                  Último acesso
                </th>
                <th width="100px" className="text-center">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => {
                return (
                  <tr key={user.id}>
                    <td>
                      <UserDetails className="d-flex align-items-center">
                        <div>
                          <img
                            src={user.avatar_url || 'assets/img/default-avatar.svg'}
                            alt="Avatar do usuário"
                          />
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                          <UserName>{user.full_name}</UserName>
                          <UserEmail>{user.email}</UserEmail>
                        </div>
                        <div>
                          {user.is_manager && (
                            <Tag color="primary" className="ml-3">
                              Administrador
                            </Tag>
                          )}
                        </div>
                      </UserDetails>
                    </td>
                    <td className="text-center">
                      {FORMATTERS.DATE_DDMMYYYYHHMMSS(user.date_last_login)}
                    </td>
                    <td className="text-center">
                      <div className="d-flex">
                        {!user.is_manager && (
                          <FastFilterSelect
                            triggerStyle={{
                              '&:hover': {
                                backgroundColor: '#ffd !important',
                              },
                            }}
                            menuStyle={{ minWidth: '230px' }}
                            label="Ações"
                            value={null}
                            options={[
                              {
                                label: 'Editar permissões',
                                icon: (
                                  <Tag
                                    className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2"
                                    variant="primary"
                                  >
                                    <LuLock size="1.1em" />
                                  </Tag>
                                ),
                                value: 'VIEW_PERMISSIONS',
                              },
                              {
                                label: 'Remover acesso',
                                value: 'DELETE',
                                icon: (
                                  <Tag
                                    className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2"
                                    variant="danger"
                                  >
                                    <LuTrash size="1.1em" />
                                  </Tag>
                                ),
                              },
                            ]}
                            onChange={value => {
                              switch (value) {
                                case 'VIEW_PERMISSIONS': {
                                  handleViewPermissions(user.id);

                                  break;
                                }
                                case 'DELETE': {
                                  handleRemoveFromCompany(user.id);
                                  break;
                                }
                                default:
                                  break;
                              }
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </div>
      );
    },
    [isMobile, activeCompany, handleViewPermissions, handleRemoveFromCompany],
  );

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      <ReactTooltip effect="solid" />
      {!isCompact && (
        <PageHeader
          title="Usuários"
          variant="small"
          sideContent={
            <Button variant="dark" icon={<BsPlus />} onClick={() => setIsModalOpen(true)}>
              Convidar Usuário
            </Button>
          }
        />
      )}
      {isCompact && (
        <div className="d-flex justify-content-between align-items-end mb-3">
          <h4 className="m-0">Usuários</h4>
          <Button
            variant="success-2"
            onClick={() => setIsModalOpen(true)}
            className="d-flex align-items-center"
          >
            Convidar Usuário
            <FaPlus className="ml-2" />
          </Button>
        </div>
      )}
      <UsersModal
        isVisible={isModalOpen}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onInviteUserCallback={isMigrated ? onFetchInvitations : null}
      />
      <Permissions
        selectedUser={selectedUser}
        user_id={selectedUserId}
        isOpen={isPermissionsModalOpen}
        onToggleForm={handleClearUser}
        onAfterSaveCallback={handleClearUser}
      />
      {isMigrated && (
        <>
          {!isEmpty(invitations) && (
            <Row>
              <Col>
                <CustomCard>
                  <CustomCardHeader className="d-flex justify-content-between align-items-center">
                    <h3 className="d-flex justify-content-center">
                      <LuMailQuestion className="mr-2" />
                      Convites enviados
                    </h3>
                  </CustomCardHeader>
                  <CustomCardBody noPadding>
                    {renderInvitationsTable(invitations)}
                  </CustomCardBody>
                </CustomCard>
              </Col>
            </Row>
          )}
          <Row className={isEmpty(invitations) ? '' : 'mt-3'}>
            <Col>
              <CustomCard>
                <CustomCardHeader className="d-flex justify-content-between align-items-center">
                  <h3 className="d-flex justify-content-center">
                    <LuUserCheck className="mr-2" />
                    Usuários ativos
                  </h3>
                </CustomCardHeader>
                <CustomCardBody noPadding>{renderUsersTable(users)}</CustomCardBody>
              </CustomCard>
            </Col>
          </Row>
        </>
      )}
      {!isMigrated && (
        <>
          <TableLookup
            keyName="id"
            data={users}
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_COLUMNS}
            onDelete={handleDeleteUser}
          />
          <Row>
            <Col sm={4}>
              <ItemsPerPage
                itemsPerPage={pagination.itemsPerPage}
                onChange={onPageSizeChange}
                className="mt-3"
              />
            </Col>
            <Col className="d-flex flex-row-reverse">
              <Pagination {...pagination} total={total} onPageChange={onPageChange} />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

Users.defaultProps = {
  isCompact: false,
  invitations: [],
};

Users.propTypes = {
  users: PropTypes.object,
  total: PropTypes.number.isRequired,
  onFetchUsers: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
  isMobile: PropTypes.bool,
  invitations: PropTypes.array,
};

export default Users;
