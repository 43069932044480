import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const getCompanyInvitations = callback => {
  return dispatch => {
    dispatch(request());

    service
      .getCompanyInvitations()
      .then(response => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: constants.COMPANY_GET_INVITATIONS_REQUEST, payload: {} };
  }
  function success(data) {
    return {
      type: constants.COMPANY_GET_INVITATIONS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.COMPANY_GET_INVITATIONS_FAILURE,
      payload: error,
    };
  }
};

export default getCompanyInvitations;
