import { connect } from 'react-redux';

import subscriptionActionCreators from '_store/_actions/subscription';

import PaymentAlert from './PaymentAlert';

const mapStateToProps = state => ({
  paymentAlert: state.subscription.paymentAlert,
});

const mapDispatchToProps = {
  onClearPaymentAlert: subscriptionActionCreators.clearPaymentAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAlert);
