import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Tag } from '_components/_core';
import FORMATTERS from 'helpers/formatters';

const SUBSCRIPTION_STATUS = {
  active: () => 'Ativa',
  canceled: reference_date =>
    `Cancelada${reference_date ? ` em ${FORMATTERS.DATE_DDMMYYYY(reference_date)}` : ''}`,
  trialing: reference_date =>
    `Teste gratuito${reference_date ? ` até ${FORMATTERS.DATE_DDMMYYYY(reference_date)}` : ''}`,
  'trial-ended': reference_date =>
    `Teste encerrado${reference_date ? ` em ${FORMATTERS.DATE_DDMMYYYY(reference_date)}` : ''}`,
  incomplete: () => 'Incompleta',
  past_due: () => 'Vencida',
};

const SUBSCRIPTION_VARIANT = {
  active: 'success',
  canceled: 'danger',
  trialing: 'info',
  'trial-ended': 'warning',
  incomplete: 'warning',
  past_due: 'danger',
};

function SubscriptionStatusTag({ status, reference_date }) {
  if (isEmpty(status)) {
    return null;
  }

  return (
    <>
      <Tag variant={SUBSCRIPTION_VARIANT[status]}>
        {SUBSCRIPTION_STATUS[status](reference_date)}
      </Tag>
    </>
  );
}

SubscriptionStatusTag.propTypes = {
  status: '',
};

SubscriptionStatusTag.propTypes = {
  status: PropTypes.string,
  reference_date: PropTypes.string,
};

export default SubscriptionStatusTag;
