import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  &&& {
    font-size: 14px;

    thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: .625rem 1rem;
      color: #78829d;
      background-color: #fcfcfc;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr td {
      padding: .75rem 1rem;
      vertical-align: middle;
      /* border-top: 1px solid #e7eaef; */
      font-weight: 400;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr:first-child td {
      border-top: none;
    }
  }
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 1rem;
  }

  span.user-name {
    color: #071437;
    font-weight: 600;
    font-size: 1.075rem !important;
  }

  span.user-email {
    color: #99A1B7;
    font-weight: 500;
    font-size: .95rem !important;
  }
`;

export const UserName = styled.span`
  color: #071437;
  font-weight: 600;
  font-size: 13.975px;
`;

export const UserEmail = styled.span`
  color: #99A1B7;
  font-weight: 500;
  font-size: 12.35px;
`;

export const UserAvatar = styled.img`
  
`;

export const PlanName = styled.span`
  color: #071437;
  font-weight: 600;
  font-size: 14px;
`;

export const PlanDescription = styled.span`
  color: #99A1B7;
  font-weight: 400;
  font-size: 12px;
`;

export const PlanFeatures = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-bottom: .5rem;
    font-weight: 500;

    span {
      /* color: #99A1B7; */
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  svg {
    /* color: #00B8D9; */
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  border: 0px;
  border-radius: 9999px;
  box-shadow: 0 0 0 1px #ffffff;
  background-color: #ec6b00;
  gap: 0.25rem;
  transition: box-shadow 0.25s;

  ${({ status }) =>
    status === 'success' &&
    css`
      background-color: #198754;
  `}

  ${({ status }) =>
    status === 'warning' &&
    css`
  background-color: #ec6b00;
  `}

  ${({ status }) =>
    status === 'danger' &&
    css`
      background-color: #dc3545;
  `}
`;
