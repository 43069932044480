import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';

import ContabillsForm from './components/Form';
import ZenplyForm from './components/Form/components/ZenpaperForm/ZenplyForm';

const product = process.env.REACT_APP_CONTABILLS_APP;

function SignUp({
  isLoading,
  segments,
  onFetchPlans,
  onSignUp,
  onEmailConfirmation,
  onFetchMarketSegments,
}) {
  useEffect(() => {
    onFetchPlans();
    onFetchMarketSegments();
  }, [onFetchPlans, onFetchMarketSegments]);

  useEffect(() => {
    document.body.style.backgroundImage = 'url(assets/img/bg-activate-subscription.png)';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';

    return () => {
      document.body.style.backgroundImage = 'none';
    };
  }, []);

  const renderForm = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return (
        <ZenplyForm
          onSignUp={onSignUp}
          onEmailConfirmation={onEmailConfirmation}
          segments={segments}
        />
      );
    }

    return <ContabillsForm isLoading={isLoading} onSignUp={onSignUp} />;
  }, [isLoading, segments, onSignUp, onEmailConfirmation]);

  return (
    <Container
      fluid={product === 'contabills-empresas'}
      className={classNames({
        'h-100': true,
        'pl-0': product === 'contabills-empresas',
        'pr-0': product === 'contabills-empresas',
      })}
    >
      {renderForm()}
    </Container>
  );
}

SignUp.defaultProps = {
  segments: [],
};

SignUp.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  segments: PropTypes.array,
  onSignUp: PropTypes.func.isRequired,
  onFetchPlans: PropTypes.func.isRequired,
  onEmailConfirmation: PropTypes.func.isRequired,
  onFetchMarketSegments: PropTypes.func.isRequired,
};

export default SignUp;
