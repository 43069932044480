import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { LuPlus, LuTrash, LuPencil } from 'react-icons/lu';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { useMediaQuery } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { Button, CopyToClipboard } from '_components/_core';

import { CustomCard, CustomCardBody, CustomCardHeader } from '../../styles';

import { StyledTable, ActiveKeys, ExpireAt, KeyName } from './styles';

import { ApiKeysModal } from './components';
import { EmptyMessage } from '..';

function ApiKey({
  isLoading,
  apiKeys,
  onFetchKeys,
  onDeleteKey,
  onFetchIntegrations,
  bankIntegrationSettings,
  // onDeleteProvider,
  // isLoadingIntegration,
}) {
  const [isCreateKeyModalOpen, setIsCreateKeyModalOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  // const [isProviderModalOpen, setIsProviderModalOpen] = useState(false);
  // const [selectedProvider, setSelectedProvider] = useState(null);

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const { isMobile } = useMediaQuery();

  const handleDeleteKey = useCallback(
    keyId => {
      onDeleteKey(keyId, () => {
        onFetchKeys();
      });
    },
    [onDeleteKey, onFetchKeys],
  );

  const handleOpenEditModal = useCallback(apiKey => {
    setSelectedApiKey(apiKey);
    setIsCreateKeyModalOpen(true);
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    setSelectedApiKey(null);
    setIsCreateKeyModalOpen(true);
  }, []);

  // const handleOpenProviderModal = useCallback(() => {
  //   setSelectedProvider(null);
  //   setIsProviderModalOpen(true);
  // }, []);

  // const handleOpenEditProviderModal = useCallback(setting => {
  //   setSelectedProvider(setting);
  //   setIsProviderModalOpen(true);
  // }, []);

  // const handleDeleteProvider = useCallback(
  //   provider => {
  //     onDeleteProvider(provider, () => {
  //       onFetchIntegrations();
  //     });
  //   },
  //   [onDeleteProvider, onFetchIntegrations],
  // );

  useEffect(() => {
    if (!isDataLoaded) {
      onFetchKeys();
      onFetchIntegrations();
      setIsDataLoaded(true);
    }
  }, [onFetchKeys, isDataLoaded, onFetchIntegrations]);

  // Temporary solution to list all the providers using a map
  // in the case of new providers being added in the future
  bankIntegrationSettings.all_settings = [bankIntegrationSettings.default_settings];

  return (
    <Container>
      <Row>
        <Col>
          <ApiKeysModal
            isVisible={isCreateKeyModalOpen}
            onModalToggle={() => setIsCreateKeyModalOpen(!isCreateKeyModalOpen)}
            apiKey={selectedApiKey}
          />
          {/* <ProviderModal
            isVisible={isProviderModalOpen}
            onModalToggle={() => setIsProviderModalOpen(!isProviderModalOpen)}
            provider={selectedProvider}
          /> */}
          <CustomCard>
            <CustomCardHeader className="d-flex justify-content-between align-items-center">
              <h3>Token API do Zenply</h3>
              <Button
                size="sm"
                variant="dark"
                className="d-flex justify-content-center"
                onClick={() => handleOpenCreateModal()}
              >
                Nova chave de API
                <LuPlus className="ml-1" />
              </Button>
            </CustomCardHeader>
            <CustomCardBody noPadding>
              {isEmpty(apiKeys) && <EmptyMessage />}
              {!isEmpty(apiKeys) && (
                <div className={classNames({ 'table-responsive': isMobile })}>
                  <StyledTable className={classNames({ 'table-hover': true })}>
                    <thead>
                      <tr>
                        <th className="text-left">Nome / Descrição</th>
                        <th className="text-left">Chave</th>
                        <th className="text-center">Data de expiração</th>
                        <th width="5%" className="text-center">
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {apiKeys.map(apiKey => {
                        const { _id, expire_at } = apiKey;

                        const expireDate = expire_at;
                        const relativeDate = DateTime.fromISO(expire_at)
                          .setLocale('pt-BR')
                          .toRelativeCalendar();

                        return (
                          <tr key={_id}>
                            <td>
                              <ActiveKeys className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                  <KeyName>{apiKey.name}</KeyName>
                                  <ExpireAt>
                                    {apiKey.description || <i>Sem descrição</i>}
                                  </ExpireAt>
                                </div>
                              </ActiveKeys>
                            </td>
                            <td>
                              <CopyToClipboard
                                text={apiKey.api_key}
                                emptyText="Sem documento"
                              >
                                {apiKey.api_key}
                              </CopyToClipboard>
                            </td>
                            <td className="text-center">
                              {!expireDate && 'Nunca'}
                              {expireDate !== null && (
                                <>
                                  {FORMATTERS.DATE_DDMMYYYY(expireDate)} ({relativeDate})
                                </>
                              )}
                            </td>
                            <td className="text-center">
                              <span className="d-flex justify-content-center align-items-center bg-black">
                                <Button
                                  size="sm"
                                  variant="default"
                                  className="d-flex justify-content-center align-items-center"
                                  onClick={() => handleOpenEditModal(apiKey)}
                                  isLoading={isLoading}
                                  disabled={isLoading}
                                >
                                  <LuPencil />
                                </Button>
                                <Button
                                  size="sm"
                                  variant="inverse-danger"
                                  className="ml-2 d-flex justify-content-center align-items-center"
                                  onClick={() => handleDeleteKey(_id)}
                                  disabled={isLoading}
                                  isLoading={isLoading}
                                >
                                  <LuTrash />
                                </Button>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </StyledTable>
                </div>
              )}
            </CustomCardBody>
          </CustomCard>
          {/* <CustomCard className="mt-4">
            <CustomCardHeader className="d-flex justify-content-between align-items-center">
              <h3>Provedores de pagamento</h3>
              <Button
                size="sm"
                variant="dark"
                className="d-flex justify-content-center"
                onClick={() => handleOpenProviderModal()}
              >
                Nova integração
                <LuPlus className="ml-1" />
              </Button>
            </CustomCardHeader>
            <CustomCardBody noPadding>
              {isEmpty(bankIntegrationSettings.default_settings) && <EmptyMessage />}
              {!isEmpty(bankIntegrationSettings.default_settings) && (
                <div className={classNames({ 'table-responsive': isMobile })}>
                  <StyledTable className={classNames({ 'table-hover': true })}>
                    <thead>
                      <tr>
                        <th className="text-left">Provedor</th>
                        <th className="text-left">Chave Testes</th>
                        <th className="text-left">Chave Produção</th>
                        <th width="5%" className="text-center">
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bankIntegrationSettings.all_settings.map(setting => {
                        const { provider, staging_api_key, production_api_key } =
                          setting || null;

                        return (
                          <tr key={provider}>
                            <td>{provider}</td>
                            <td>
                              <CopyToClipboard
                                text={staging_api_key}
                                emptyText="Sem documento"
                              >
                                {staging_api_key}
                              </CopyToClipboard>
                            </td>
                            <td>
                              <CopyToClipboard
                                text={production_api_key}
                                emptyText="Sem documento"
                              >
                                {production_api_key}
                              </CopyToClipboard>
                            </td>
                            <td className="text-center">
                              <span className="d-flex justify-content-center align-items-center bg-black">
                                <Button
                                  size="sm"
                                  variant="default"
                                  className="d-flex justify-content-center align-items-center"
                                  onClick={() => handleOpenEditProviderModal(setting)}
                                  isLoading={isLoadingIntegration}
                                  disabled={isLoadingIntegration}
                                >
                                  <LuPencil />
                                </Button>
                                <Button
                                  size="sm"
                                  variant="inverse-danger"
                                  className="ml-2 d-flex justify-content-center align-items-center"
                                  onClick={() => handleDeleteProvider(provider)}
                                  disabled={isLoadingIntegration}
                                  isLoading={isLoadingIntegration}
                                >
                                  <LuTrash />
                                </Button>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </StyledTable>
                </div>
              )}
            </CustomCardBody>
          </CustomCard> */}
        </Col>
      </Row>
    </Container>
  );
}

ApiKey.defaultProps = {
  apiKeys: [],
  isCompact: false,
};

ApiKey.propTypes = {
  apiKeys: PropTypes.array,
  onFetchKeys: PropTypes.func.isRequired,
  onDeleteKey: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onFetchIntegrations: PropTypes.func.isRequired,
  bankIntegrationSettings: PropTypes.array,
  onDeleteProvider: PropTypes.func.isRequired,
  isLoadingIntegration: PropTypes.bool,
  isCompact: PropTypes.bool,
};

export default ApiKey;
