import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';

import { PageHeader } from '_components/_core';

import { Download } from './components';

function ExportFinancialData({
  activeCompany,
  subscription,
  users,
  onDownloadFinancialData,
  onFetchUsers,
  isCompact,
}) {
  const props = useMemo(() => {
    return {
      activeCompany,
      subscription,
      users,
      onDownloadFinancialData,
      onFetchUsers,
    };
  }, [activeCompany, subscription, users, onDownloadFinancialData, onFetchUsers]);

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && <PageHeader title="Exportar Dados" variant="small" />}
      {isCompact && <h4 className="mt-3 mb-3">Exportar Dados</h4>}
      <Download {...props} />
    </Container>
  );
}

ExportFinancialData.defaultProps = {
  subscription: {},
  users: [],
  isCompact: false,
};

ExportFinancialData.propTypes = {
  activeCompany: PropTypes.string,
  subscription: PropTypes.object,
  users: PropTypes.array,
  onFetchUsers: PropTypes.func,
  onDownloadFinancialData: PropTypes.func,
  isCompact: PropTypes.bool,
};

export default ExportFinancialData;
