import { connect } from 'react-redux';
import { compose } from 'redux';

import subscriptionActionsCreators from '_store/_actions/subscription';

import AdditionalCompanies from './AdditionalCompanies';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  companySeatsPricing: state.subscription.companySeatsPricing,
});

const mapDispatchToProps = {
  onFetchCompanySeatsPricing: subscriptionActionsCreators.fetchCompanySeatsPricing,
  onConfirmCompanySeatsPurchase: subscriptionActionsCreators.confirmCompanySeatsPurchase,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdditionalCompanies);
