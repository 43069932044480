import { connect } from 'react-redux';
import { compose } from 'redux';

import userActionCreators from '_store/_actions/user';
import companyActionCreators from '_store/_actions/company';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Users from './Users';

const mapStateToProps = state => ({
  users: state.user.users,
  total: state.user.total,
  isLoading: state.user.isLoading,
  user: state.auth.user,
  activeCompany: state.company.activeCompany,
  invitations: state.company.invitations,
});

const mapDispatchToProps = {
  onFetchUsers: userActionCreators.getAllUsers,
  onInviteUser: userActionCreators.inviteUser,
  onDeleteUser: userActionCreators.deleteUser,
  onFetchInvitations: companyActionCreators.getCompanyInvitations,
  onDeleteInvitation: companyActionCreators.deleteCompanyInvitation,
  onRemoveUserFromCompany: companyActionCreators.removeUserFromCompany,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_users'], 'all', true),
)(Users);
