import { io } from 'socket.io-client';

class WebSocketManager {
  constructor(namespace) {
    this.namespace = namespace;
    this.socket = null;
  }

  connect() {
    if (!this.socket) {
      this.socket = io(`${process.env.REACT_APP_API_URL}/${this.namespace}`);
    }

    this.socket.on('connect', () => {
      console.log(`Conectado ao namespace: ${this.namespace}`);
    });

    this.socket.on('disconnect', () => {
      console.log(`Desconectado do namespace: ${this.namespace}`);
    });
  }

  subscribe(event, callback) {
    if (this.socket) {
      this.socket.on(event, data => {
        console.log(`Recebido evento ${event}:`, data);

        callback(data);
      });
    }
  }

  emit(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  leaveRoom(room) {
    if (this.socket) {
      this.socket.leave(room);
    }
  }

  joinRoom(room) {
    if (this.socket) {
      this.socket.emit('subscribe', room);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
}

export default WebSocketManager;
