import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import FORMATTERS from 'helpers/formatters';

import { CustomCard, CustomCardBody } from '../../../../../styles';
import { Badge, Text } from '../styles';

function InvoiceHeader({ invoice }) {
  let message = '';

  switch (invoice.status) {
    case 'draft':
      message = `Rascunho`;
      break;
    case 'open':
      message = 'Aguardando Pagamento';
      break;
    case 'overdue':
      message = `Vencida`;
      break;
    case 'paid':
      message = `Pagamento realizado em ${FORMATTERS.DATE_DDMMYYYY(invoice.paid_at)}`;
      break;
    case 'void':
      message = `Anulada`;
      break;
    case 'uncollectible':
      message = `Inválida`;
      break;
    default:
      break;
  }

  return (
    <Row className="mt-3">
      <Col>
        <CustomCard>
          <CustomCardBody className="d-flex align-items-center">
            <Badge status={invoice.status} /> <Text className="m-0 ml-3">{message}</Text>
          </CustomCardBody>
        </CustomCard>
      </Col>
    </Row>
  );
}

InvoiceHeader.defaultProps = {
  invoice: null,
};

InvoiceHeader.propTypes = {
  invoice: PropTypes.object,
};

export default InvoiceHeader;
