import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import userActionCreators from '_store/_actions/user';
import authActionCreators from '_store/_actions/auth';

import ChooseCompany from './ChooseCompany';

const mapStateToProps = state => ({
  companies: state.company.companies,
  isLoading: state.company.isLoading,
  invitations: state.user.invitations,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onActivateCompany: companyActionCreators.setActiveCompany,
  onLogout: authActionCreators.logout,
  onFetchInvitations: userActionCreators.fetchMyCompanyInvitations,
  onAcceptInvitation: userActionCreators.acceptCompanyInvitation,
  onDeclineInvitation: userActionCreators.declineCompanyInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCompany);
