import React from 'react';
import PropTypes from 'prop-types';
import { SimpleAlert } from '_components/_core';
import { FaTimes } from 'react-icons/fa';

function PaymentAlert({ paymentAlert, onClearPaymentAlert }) {
  if (!paymentAlert) {
    return null;
  }

  const { variant, title, description, dissmissable } = paymentAlert;

  return (
    <SimpleAlert variant={variant || 'info'}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h5>{title}</h5>
          <span>{description}</span>
        </div>
        <div>
          {dissmissable && (
            <FaTimes
              onClick={onClearPaymentAlert}
              size={20}
              style={{
                cursor: 'pointer',
              }}
            />
          )}
        </div>
      </div>
    </SimpleAlert>
  );
}

PaymentAlert.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func,
};

export default PaymentAlert;
