import addCompany from './addCompany';
import updateCompany from './updateCompany';
import deleteCompany from './deleteCompany';
import getActiveCompany from './getActiveCompany';
import getAllCompanies from './getAllCompanies';
import getCompanyById from './getCompanyById';
import setActiveCompany from './setActiveCompany';
import setActivePeriod from './setActivePeriod';
import searchCompany from './searchCompany';
import clearSearchedCompany from './clearSearchedCompany';
import setSelectedCompany from './setSelectedCompany';
import fetchCnpjCertificate from './fetchCnpjCertificate';
import addCompanyCredential from './addCompanyCredential';
import updateCompanyCredential from './updateCompanyCredential';
import deleteCompanyCredential from './deleteCompanyCredential';
import addCompanyFee from './addCompanyFee';
import updateCompanyFee from './updateCompanyFee';
import deleteCompanyFee from './deleteCompanyFee';
import activateInvoiceIssuing from './activateInvoiceIssuing';
import getCompanyRevenue from './getCompanyRevenue';
import saveRevenues from './saveRevenues';
import addCompanyDigitalCertificate from './addCompanyDigitalCertificate';
import deleteCompanyDigitalCertificate from './deleteCompanyDigitalCertificate';
import fetchCompaniesForSelect from './fetchCompaniesForSelect';
import addCompanyAvatar from './addCompanyAvatar';
import deleteCompanyAvatar from './deleteCompanyAvatar';
import fetchMarketSegments from './fetchMarketSegments';
import addFinancialCompany from './addFinancialCompany';
import updateFinancialCompany from './updateFinancialCompany';
import deleteFinancialCompany from './deleteFinancialCompany';
import getBlockedPeriod from './getBlockedPeriod';
import updateBlockedPeriod from './updateBlockedPeriod';
import getCompanyInvitations from './getCompanyInvitations';
import deleteCompanyInvitation from './deleteCompanyInvitation';
import leaveCompany from './leaveCompany';
import removeUserFromCompany from './removeUserFromCompany';

export default {
  addCompany,
  updateCompany,
  deleteCompany,
  getActiveCompany,
  getAllCompanies,
  getCompanyById,
  setActiveCompany,
  setActivePeriod,
  searchCompany,
  clearSearchedCompany,
  setSelectedCompany,
  fetchCnpjCertificate,
  addCompanyCredential,
  updateCompanyCredential,
  deleteCompanyCredential,
  addCompanyFee,
  updateCompanyFee,
  deleteCompanyFee,
  activateInvoiceIssuing,
  getCompanyRevenue,
  saveRevenues,
  addCompanyDigitalCertificate,
  deleteCompanyDigitalCertificate,
  fetchCompaniesForSelect,
  addCompanyAvatar,
  deleteCompanyAvatar,
  fetchMarketSegments,
  addFinancialCompany,
  updateFinancialCompany,
  deleteFinancialCompany,
  getBlockedPeriod,
  updateBlockedPeriod,
  getCompanyInvitations,
  deleteCompanyInvitation,
  leaveCompany,
  removeUserFromCompany,
};
