import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;

  ul {
    margin: 0;
  }

  ${({ variant }) =>
    variant === 'default' &&
    css`
    background-color: #F7F7F9;
    color: #6C757D;
    border-left: 3px solid #6C757D;

    h5 {
      color: #6C757D;
    }
  `}
  
  ${({ variant }) =>
    variant === 'danger' &&
    css`
    background-color: #FDF1F5;
    color: #721c24;
    border-left: 3px solid #721c24;

    h5 {
      color: #721c24;
    }
  `}

  ${({ variant }) =>
    variant === 'success' &&
    css`
    background-color: #EEFBF2;
    color: #00784e;
    border-left: 3px solid #00784e;

    h5 {
      color: #00784e;
    }
  `}

  ${({ variant }) =>
    variant === 'warning' &&
    css`
    background-color: #fff3cd;
    color: #856404;
    border-left: 3px solid #856404;

    h5 {
      color: #856404;
    }
  `}

  ${({ variant }) =>
    variant === 'info' &&
    css`
    background-color: #F0FAFC;
    color: #354168;
    border-left: 3px solid #63B3ED;

    h5 {
      color: #354168;
    }
  `}
`;
