import React from 'react';
import PropTypes from 'prop-types';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { FcInfo } from 'react-icons/fc';
import ReactTooltip from 'react-tooltip';

import { Container } from './styles';

function HintIcon({ hint, className, icon, iconColor, place }) {
  return (
    <Container className={className}>
      <ReactTooltip effect="solid" />
      {icon === 'question' && (
        <IoIosHelpCircleOutline
          color={iconColor}
          size="1.1em"
          data-place={place}
          data-tip={hint}
        />
      )}
      {icon === 'info' && (
        <FcInfo color={iconColor} size="1.1em" data-place={place} data-tip={hint} />
      )}
    </Container>
  );
}

HintIcon.defaultProps = {
  className: '',
  icon: 'question',
  iconColor: '#8c8c8c',
  place: 'top',
};

HintIcon.propTypes = {
  hint: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  place: PropTypes.string,
};

export default HintIcon;
