import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleAlert } from '_components/_core';
import { FaTimes } from 'react-icons/fa';

import { getQueryVariable } from 'helpers';

function SubscriptionQueryAlert() {
  const [showAlert, setShowAlert] = useState(false);
  const [paymentAlert, setPaymentAlert] = useState(null);

  const { variant, title, description, dissmissable } = paymentAlert || {};

  const show = useMemo(() => {
    return getQueryVariable('show');
  }, []);

  useEffect(() => {
    const title = decodeURI(getQueryVariable('title'));
    const description = decodeURI(getQueryVariable('description'));
    const dissmissable = getQueryVariable('dissmissable');
    const variant = getQueryVariable('variant');

    if (show) {
      setShowAlert(true);

      setPaymentAlert({
        title,
        description,
        dissmissable,
        variant,
      });
    }
  }, [show]);

  const handleClearPaymentAlert = useCallback(() => {
    window.history.replaceState({}, document.title, window.location.pathname);

    setShowAlert(false);
    setPaymentAlert(null);
  }, []);

  if (!showAlert) {
    return null;
  }

  return (
    <SimpleAlert variant={variant || 'info'}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h5>{title}</h5>
          <span>{description}</span>
        </div>
        <div>
          {dissmissable && (
            <FaTimes
              onClick={handleClearPaymentAlert}
              size={20}
              style={{
                cursor: 'pointer',
              }}
            />
          )}
        </div>
      </div>
    </SimpleAlert>
  );
}

SubscriptionQueryAlert.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func,
};

export default SubscriptionQueryAlert;
