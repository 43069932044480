import * as yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const BillingInformationSchema = yup.object().shape({
  name: yup.string().required(),
  document_type: yup.mixed().oneOf(['CPF', 'CNPJ']).required(),
  document_number: yup
    .string()
    .when('document_type', {
      is: 'CPF',
      then: yup
        .string()
        .test('is-cpf', 'CPF inválido', value => validateCPF(value))
        .nullable(),
      otherwise: yup
        .string()
        .test('is-cnpj', 'CNPJ inválido', value => validateCNPJ(value))
        .nullable(),
    })
    .required()
    .nullable(),
  email_primary: yup.string().email().required(),
  email_secondary: yup.string().email().nullable(),
  address_zip_code: yup.string().required().nullable(),
  address_street: yup.string().required().nullable(),
  address_number: yup.string().required().nullable(),
  address_district: yup.string().required().nullable(),
  address_complement: yup.string().max(255).nullable(),
  address_city: yup.string().required().nullable(),
  address_city_ibge: yup.string().required().nullable(),
  address_state: yup.string().required().nullable(),
  address_state_ibge: yup.string().required().nullable(),
  address_country: yup.string().required().nullable(),
});

export default BillingInformationSchema;
