import { connect } from 'react-redux';

import userActionCreators from '_store/_actions/user';
import companyActionCreators from '_store/_actions/company';

import CompanyInvitations from './CompanyInvitations';

const mapStateToProps = state => ({
  isLoading: state.user.isLoadingInvitations,
  invitations: state.user.invitations,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onAcceptInvitation: userActionCreators.acceptCompanyInvitation,
  onDeclineInvitation: userActionCreators.declineCompanyInvitation,
  onFetchInvitations: userActionCreators.fetchMyCompanyInvitations,
  onFetchCompanies: companyActionCreators.getAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInvitations);
