import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { LuPencil } from 'react-icons/lu';
import ReactTooltip from 'react-tooltip';

import FORMATTERS from 'helpers/formatters';
import { useMediaQuery } from 'helpers';
import { Button, CopyToClipboard } from '_components/_core';
import { CompanyFormModal, CompanyAvatarUpload } from '_components/_shared';
import { CustomCard, CustomCardBody, Label, Value } from '../../styles';
import { ActiveCompany } from './styles';

function Overview({ activeCompany }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isDesktopExtraLarge } = useMediaQuery();

  const COLUMN_SIZE = isDesktopExtraLarge ? 2 : 3;

  const getManagerName = useCallback(() => {
    const { first_name, last_name, document_type } = activeCompany.manager;

    if (document_type === 'PF') {
      return `${first_name} ${last_name}`;
    }

    return `${first_name}`;
  }, [activeCompany]);

  if (!activeCompany) {
    return null;
  }

  return (
    <Container>
      <ReactTooltip effect="solid" />
      <CompanyFormModal
        isVisible={isModalOpen}
        company_id={activeCompany.id}
        onToggleForm={() => setIsModalOpen(!isModalOpen)}
      />
      <CustomCard className="mb-3 p-2">
        <ActiveCompany>
          <div>
            <CompanyAvatarUpload
              key={activeCompany.id}
              company_id={activeCompany.id}
              company_avatar_url={activeCompany.company_avatar_url}
            />
          </div>
          <div className="ml-0 d-flex flex-column" style={{ flex: 1 }}>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <h4 className="mb-1">{activeCompany.trading_name}</h4>
                <small>{activeCompany.company_name}</small>
                <small className="mt-1">
                  {FORMATTERS.CPF_CNPJ(activeCompany.document_number)}
                </small>
              </div>
            </div>
          </div>
          <Button
            size="sm"
            variant="default"
            className="d-flex justify-content-center align-items-center"
            onClick={() => setIsModalOpen(true)}
          >
            <LuPencil className="mr-1" />
            Editar
          </Button>
        </ActiveCompany>
      </CustomCard>
      <CustomCard className="mt-4">
        <CustomCardBody>
          <Row>
            <Col md={COLUMN_SIZE} className="d-flex align-items-center">
              <Label className="d-flex justify-content-start align-items-center">
                Responsável (administrador)
              </Label>
            </Col>
            <Col>
              <Value
                data-place={'bottom'}
                data-tip={activeCompany.manager.email}
                style={{ borderBottom: '1px dashed', cursor: 'pointer' }}
              >
                {getManagerName()}
              </Value>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={COLUMN_SIZE}>
              <Label>Tipo</Label>
            </Col>
            {activeCompany.document_type && (
              <Col>
                <Value>
                  {activeCompany.document_type === 'PF'
                    ? 'Pessoa Física'
                    : 'Pessoa Jurídica'}
                </Value>
              </Col>
            )}
            {!activeCompany.document_type && (
              <Col>
                <Value>Não informado</Value>
              </Col>
            )}
          </Row>
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>CPF / CNPJ</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard
                  hoverOnly
                  text={activeCompany.document_number}
                  emptyText="Sem documento"
                >
                  {FORMATTERS.CPF_CNPJ(activeCompany.document_number)}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>Nome / Razão Social</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.company_name}>
                  {activeCompany.company_name}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>Sobrenome / Nome Fantasia</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.trading_name}>
                  {activeCompany.trading_name}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          {/* <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>E-mail</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.email}>
                  {activeCompany.email}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row> */}
          <hr />
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>CEP</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.address_zip_code}>
                  {activeCompany.address_zip_code}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>Logradouro</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.address_street}>
                  {activeCompany.address_street}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>Número</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.address_number}>
                  {activeCompany.address_number}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>Complemento</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.address_complement}>
                  {activeCompany.address_complement}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>Bairro</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.address_district}>
                  {activeCompany.address_district}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>Cidade</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.address_city}>
                  {activeCompany.address_city}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>Estado</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.address_state}>
                  {activeCompany.address_state}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row>
          {/* <hr />
          <Row className="mt-3">
            <Col md={COLUMN_SIZE}>
              <Label>Observações</Label>
            </Col>
            <Col>
              <Value>
                <CopyToClipboard hoverOnly text={activeCompany.notes}>
                  {activeCompany.notes}
                </CopyToClipboard>
              </Value>
            </Col>
          </Row> */}
        </CustomCardBody>
      </CustomCard>
    </Container>
  );
}

Overview.propTypes = {
  activeCompany: PropTypes.object.isRequired,
};

export default Overview;
