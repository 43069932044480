import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { LuArrowRight, LuCreditCard } from 'react-icons/lu';

import { Button, SimpleAlert } from '_components/_core';

import { Box, BoxLabel, BoxValue, Text } from '../styles';

function InvoicePaymentCard({ invoice }) {
  const { status, metadata, payment_method_description } = invoice;
  const { attempted } = metadata || {};

  const handleViewInvoice = () => {
    window.location.href = invoice.provider_payment_url;
  };

  if (status === 'draft') {
    return (
      <Row>
        <Col>
          <Text>
            A cobrança será realizada automaticamente dentro de 1 hora, na forma de
            pagamento cadastrada.
          </Text>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {attempted && (
        <SimpleAlert variant="danger">
          <p className="m-0 p-0">
            O pagamento com o cartão{' '}
            <span className="text-capitalize">{payment_method_description}</span> foi
            recusado. Verifique com seu banco o motivo ou tente novamente com as opções
            abaixo.
          </p>
        </SimpleAlert>
      )}
      <Row>
        <Col xs={6} md={3}>
          <Box>
            <BoxLabel>Situação</BoxLabel>
            <BoxValue status={attempted ? 'overdue' : status}>
              {!attempted && 'Pendente'}
              {attempted && 'Recusado'}
            </BoxValue>
          </Box>
          <Button
            variant="success-2"
            className="d-flex justify-content-center align-items-center mt-3"
            onClick={handleViewInvoice}
          >
            Pagar fatura online
            <LuArrowRight className="ml-2" />
          </Button>
        </Col>
        <Col xs={6} md={4}>
          <Box>
            <BoxLabel>Forma de pagamento</BoxLabel>
            <BoxValue status={attempted ? 'overdue' : status}>
              <span className="d-flex justify-content-start align-items-center text-capitalize">
                <LuCreditCard className="mr-2" />
                {payment_method_description}
              </span>
            </BoxValue>
          </Box>
        </Col>
        <Col xs={6} md={3}>
          &nbsp;
        </Col>
      </Row>
    </>
  );
}

InvoicePaymentCard.defaultProps = {
  invoice: null,
};

InvoicePaymentCard.propTypes = {
  invoice: PropTypes.object,
};

export default InvoicePaymentCard;
