import React from 'react';
import PropTypes from 'prop-types';
import { FaRegFileCode, FaRegFilePdf } from 'react-icons/fa6';
import { Col, Row } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import { useMediaQuery } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { Button } from '_components/_core';
import { useTableV2 } from '_components/_core/Table/utils';
import { ItemsPerPage, Pagination } from '_components/_core/Table/components';

import {
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
  CustomBreadcrumb,
  CustomBreadcrumbItem,
} from '../../../../styles';

import { StyledTable } from './styles';
import { LoadingIcon } from '_components/_shared';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'due_date', order: 'desc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function InvoiceHistory({
  onFetchSubscriptionInvoices,
  onChangeView,
  subscriptionInvoices,
  isLoading,
  withBreadcrumb,
  originView,
}) {
  const { isMobile } = useMediaQuery();

  const { pagination, onPageChange, onPageSizeChange } = useTableV2({
    ...tableConfig,
    data: subscriptionInvoices.data,
    onFetchData: onFetchSubscriptionInvoices,
    withSavedFilters: false,
    withInitialLoading: true,
  });

  return (
    <>
      {withBreadcrumb && (
        <CustomBreadcrumb>
          <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
            Assinatura
          </CustomBreadcrumbItem>
          <CustomBreadcrumbItem active>Histórico de faturas</CustomBreadcrumbItem>
        </CustomBreadcrumb>
      )}
      <Row>
        <Col>
          <CustomCard>
            <CustomCardHeader>
              <h3>Histórico de faturas</h3>
            </CustomCardHeader>
            <CustomCardBody noPadding>
              {isEmpty(subscriptionInvoices.data) && isLoading && (
                <LoadingIcon text="Carregando..." className="mt-5 mb-5" />
              )}
              {!isEmpty(subscriptionInvoices.data) && (
                <StyledTable
                  className={`table table-hover ${isMobile ? 'table-responsive' : ''} `}
                >
                  <thead>
                    <tr>
                      <th width="130px" className="text-center">
                        Vencimento
                      </th>
                      <th className="text-left">Descrição</th>
                      <th className="text-center">Valor</th>
                      <th className="text-center">Situação</th>
                      <th colSpan={2} className="text-center">
                        Nota Fiscal
                      </th>
                      <th className="text-center">Fatura</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptionInvoices.data.map(invoice => (
                      <tr key={invoice.id}>
                        <td width="130px" className="text-center">
                          {FORMATTERS.DATE_DDMMYYYY(invoice.due_date)}
                        </td>
                        <td className="text-left">{invoice.description}</td>
                        <td className="text-center">
                          <strong>{FORMATTERS.NUMBER(invoice.total_amount)}</strong>
                        </td>
                        <td className="text-center">
                          {FORMATTERS.SUBSCRIPTION_INVOICE_STATUS(invoice.status)}
                        </td>
                        <td className="text-center">
                          <a target="_blank" href={invoice.nfse_pdf_url} rel="noreferrer">
                            <FaRegFilePdf size="20" />
                          </a>
                        </td>
                        <td className="text-center">
                          <a target="_blank" href={invoice.nfse_xml_url} rel="noreferrer">
                            <FaRegFileCode size="20" />
                          </a>
                        </td>
                        <td className="text-center">
                          <Button
                            variant="link"
                            className="p-0 m-0"
                            onClick={() =>
                              onChangeView('INVOICE_DETAILS', {
                                invoice,
                                invoiceId: invoice.id,
                                originView: originView || 'INVOICE_HISTORY',
                              })
                            }
                          >
                            Ver fatura
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              )}
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
      <Row>
        <Col xs={7} className="d-flex justify-content-start">
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            noMarginsOnTotals
            className="mr-3"
            total={subscriptionInvoices.total}
            totalBeingShown={subscriptionInvoices.data.length}
            maxItemsPerPage={100}
          />
        </Col>
        <Col xs={5} className="d-flex justify-content-end">
          <Pagination
            {...pagination}
            total={subscriptionInvoices.total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </>
  );
}

InvoiceHistory.defaultProps = {
  payments: [],
  activeCompany: {},
  subscriptionInvoices: {
    total: 0,
    data: [],
  },
  isLoading: false,
  withBreadcrumb: true,
};

InvoiceHistory.propTypes = {
  payments: PropTypes.array,
  onFetchSubscriptionInvoices: PropTypes.func,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
  subscriptionInvoices: PropTypes.object,
  isLoading: PropTypes.bool,
  withBreadcrumb: PropTypes.bool,
  originView: PropTypes.string,
};

export default InvoiceHistory;
