import React from 'react';
import PropTypes from 'prop-types';
import { LuChevronDown, LuChevronLeft } from 'react-icons/lu';
import { Accordion } from 'react-bootstrap';

import { CustomCard, CustomCardBody, CustomCardHeader } from './styles';

function CustomAccordion({ defaultActiveKey, alwaysOpen, items }) {
  const [activeKey, setActiveKey] = React.useState(defaultActiveKey);

  const handleChange = key => {
    setActiveKey(key);

    if (activeKey === key && !alwaysOpen) {
      setActiveKey('');
    }
  };

  return (
    <>
      <Accordion
        defaultActiveKey={defaultActiveKey}
        activeKey={activeKey}
        as={CustomCard}
      >
        {items.map((item, index) => (
          <>
            <Accordion.Toggle
              eventKey={item.id}
              as={CustomCardHeader}
              className="d-flex justify-content-between align-items-center"
              onClick={() => handleChange(item.id)}
              style={{
                cursor: 'pointer',
              }}
              tabIndex={index}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleChange(item.id);
                }
              }}
            >
              <h3>{item.title}</h3>
              {activeKey === item.id ? (
                <LuChevronDown size="1.5em" />
              ) : (
                <LuChevronLeft size="1.5em" />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={item.id}>
              <CustomCardBody
                style={{
                  borderBottom: '1px solid #F1F1F4',
                }}
              >
                {item.content}
              </CustomCardBody>
            </Accordion.Collapse>
          </>
        ))}
      </Accordion>
    </>
  );
}

CustomAccordion.defaultProps = {
  items: [],
  defaultActiveKey: '',
  alwaysOpen: false,
};

CustomAccordion.propTypes = {
  defaultActiveKey: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.node,
      content: PropTypes.node,
    }),
  ),
  alwaysOpen: PropTypes.bool,
};

export default CustomAccordion;
