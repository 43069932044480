import constants from '../../_constants/subscription.constants';

const clearPaymentAlert = () => {
  return dispatch => {
    dispatch(request());
  };

  function request() {
    return {
      type: constants.CLEAR_PAYMENT_ALERT_REQUEST,
      payload: {},
    };
  }
};

export default clearPaymentAlert;
