import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IoCloseCircle } from 'react-icons/io5';

import { Container, Header, Body, Overlay, Footer, BeforeFooter } from './styles';

export default function FloatingCard({
  isVisible,
  title,
  footerContent,
  children,
  fullHeight,
  onToggleVisibility,
  side,
  className,
  beforeFooterContent,
  bodyClassName,
  withCloseButton,
  blockEscapeKey,
  width,
  style,
}) {
  useEffect(() => {
    const handleKeyPress = e => {
      if (e.keyCode === 27 && isVisible && !blockEscapeKey) {
        onToggleVisibility();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isVisible, onToggleVisibility, blockEscapeKey]);

  return (
    <>
      <Overlay isVisible={isVisible} onClick={onToggleVisibility} />
      <Container
        isVisible={isVisible}
        fullHeight={fullHeight}
        className={classNames('card', className)}
        side={side}
        width={width}
        style={style}
      >
        <Header className="card-header">
          <h5>{title}</h5>
          {withCloseButton && (
            <IoCloseCircle
              className="text-muted"
              size="2em"
              onClick={onToggleVisibility}
            />
          )}
        </Header>
        <Body className={classNames('card-body', bodyClassName)}>{children}</Body>
        {beforeFooterContent && <BeforeFooter>{beforeFooterContent}</BeforeFooter>}
        {footerContent && <Footer className="card-footer">{footerContent}</Footer>}
      </Container>
    </>
  );
}

export { Footer as FloatingCardFooter };

FloatingCard.defaultProps = {
  isVisible: false,
  title: '',
  footerContent: null,
  children: null,
  fullHeight: false,
  side: 'right',
  className: null,
  beforeFooterContent: null,
  bodyClassName: null,
  withCloseButton: false,
  blockEscapeKey: false,
  width: 500,
};

FloatingCard.propTypes = {
  side: PropTypes.oneOf(['left', 'right']),
  isVisible: PropTypes.bool,
  fullHeight: PropTypes.bool,
  bodyClassName: PropTypes.string,
  title: PropTypes.string,
  footerContent: PropTypes.node,
  children: PropTypes.node,
  onToggleVisibility: PropTypes.func.isRequired,
  className: PropTypes.string,
  beforeFooterContent: PropTypes.node,
  withCloseButton: PropTypes.bool,
  blockEscapeKey: PropTypes.bool,
  width: PropTypes.number,
};
