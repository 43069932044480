import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useContextMenu } from 'react-contexify';
import { IoAddOutline, IoChevronDown, IoChevronForward } from 'react-icons/io5';
import { LuSettings, LuUsers, LuCreditCard, LuChevronRight } from 'react-icons/lu';
import { FaCheckCircle } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';

import FORMATTERS from 'helpers/formatters';
import confirmDialog from 'helpers/confirmDialog';
import { Button, ContextMenu } from '_components/_core';
import { LoadingIcon, SubscriptionStatusTag } from '_components/_shared';

import {
  Footer,
  Trigger,
  ActiveCompany,
  MenuItem,
  CompanyAvatar,
  SearchMenuItem,
  CompanyListItem,
  AddCompanyButton,
  Dot,
  SubscriptionInfo,
  UserCount,
  PlanName,
} from './styles';

function CompanySwitcher({
  isLoading,
  activeCompany,
  companiesList,
  onFetchActiveCompany,
  onActivateCompany,
  onFetchCompanies,
}) {
  const history = useHistory();

  const triggerRef = useRef(null);

  const [isSwitching, setIsSwitching] = useState(false);

  const handleRedirect = useCallback(
    path => {
      history.push(path);
    },
    [history],
  );

  useEffect(() => {
    onFetchActiveCompany();
    onFetchCompanies();
  }, [onFetchActiveCompany, onFetchCompanies]);

  useEffect(() => {
    const handleStorageChange = e => {
      if (e.key !== 'active_company_id') return;

      const newActiveCompanyId = e.newValue;
      if (
        newActiveCompanyId &&
        activeCompany &&
        newActiveCompanyId !== activeCompany.id
      ) {
        confirmDialog.open({
          title: 'Nova empresa selecionada',
          message:
            'Uma nova empresa foi selecionada em outra aba, recarregue a página para atualizar os dados da sessão.',
          confirmButtonText: 'Recarregar',
          onConfirm: () => {
            window.location.reload();
          },
          onCancel: () => {
            window.location.reload();
          },
          showCancelButton: false,
          allowOutsideClick: false,
          icon: 'warning',
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [activeCompany]);

  const { trading_name } = activeCompany || {};

  const MENU_ID = 'company-switcher';

  const { show } = useContextMenu({ id: MENU_ID });

  const handleMenuOpen = useCallback(
    e => {
      const buttonPosition = triggerRef.current.getBoundingClientRect();

      show(e, {
        position: {
          x: buttonPosition.left,
          y: buttonPosition.bottom + 5,
        },
      });
    },
    [show],
  );

  const handleEditCompany = useCallback(() => {
    history.push('/configuracoes/dados-empresa');
  }, [history]);

  const renderAvatar = useCallback((company, size = 'normal') => {
    if (!company) {
      return null;
    }

    if (company.company_avatar_url) {
      return <CompanyAvatar src={company.company_avatar_url} size={size} />;
    }

    return (
      <div className="avatar">{`${company.trading_name.charAt(0).toUpperCase()}`}</div>
    );
  }, []);

  const handleActivateCompany = useCallback(
    company => {
      setIsSwitching(true);

      onActivateCompany(company, () => {
        setIsSwitching(false);
        localStorage.setItem('active_company_id', company.id);
      });
    },
    [onActivateCompany],
  );

  const currentCompany = useMemo(() => {
    if (!activeCompany) {
      return null;
    }

    return companiesList.find(c => c.id === activeCompany.id);
  }, [companiesList, activeCompany]);

  const otherCompanies = useMemo(() => {
    if (!activeCompany) {
      return [];
    }

    return companiesList.filter(c => c.id !== activeCompany?.id);
  }, [companiesList, activeCompany]);

  const myCompanies = useMemo(() => {
    if (!activeCompany) {
      return [];
    }

    return otherCompanies.filter(c => c.is_manager);
  }, [otherCompanies, activeCompany]);

  const guestCompanies = useMemo(() => {
    if (!activeCompany) {
      return [];
    }

    return otherCompanies.filter(c => !c.is_manager);
  }, [otherCompanies, activeCompany]);

  const relatedActiveCompany = useMemo(() => {
    if (!activeCompany) {
      return null;
    }

    return companiesList.find(c => c.id === activeCompany.id);
  }, [companiesList, activeCompany]);

  const renderList = useCallback(
    companies => {
      return (
        <>
          {companies.map(company => (
            <CompanyListItem
              key={company.id}
              className="p-2 d-flex align-items-center"
              onClick={() => handleActivateCompany(company)}
              active={activeCompany.id === company.id}
            >
              {renderAvatar(company)}
              <div
                className="ml-2 d-flex flex-column align-items-start justify-content-center"
                style={{ flex: 1 }}
              >
                <strong className="d-flex justify-content-center align-items-center">
                  {company.trading_name.toLowerCase()}
                </strong>
                <SubscriptionInfo>
                  <UserCount status={company.subscription_status}>
                    {FORMATTERS.SUBSCRIPTION_STATUS(company.subscription_status)}
                  </UserCount>
                  <Dot>•</Dot>
                  <PlanName>{company.subscription_plan_name.split(' ')[0]}</PlanName>
                  <Dot>•</Dot>
                  <UserCount>
                    {`${company.user_count} ${pluralize('usuário', company.user_count)}`}
                  </UserCount>
                </SubscriptionInfo>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                {activeCompany.id !== company.id && (
                  <LuChevronRight size={16} className="text-muted" />
                )}
                {activeCompany.id === company.id && (
                  <FaCheckCircle
                    size="1.2em"
                    className="text-success"
                    data-tip="Esta é a empresa selecionada no momento"
                    data-place="bottom"
                  />
                )}
              </div>
            </CompanyListItem>
          ))}
        </>
      );
    },
    [handleActivateCompany, activeCompany, renderAvatar],
  );

  return (
    <>
      {(isLoading || isSwitching) && (
        <div>
          <LoadingIcon text="" />
        </div>
      )}
      {!isLoading && !isSwitching && (
        <Trigger ref={triggerRef} onClick={handleMenuOpen}>
          {renderAvatar(activeCompany, 'small')}
          <h4>{trading_name && trading_name.toLowerCase()}</h4>
          <IoChevronDown
            className="ml-2 mr-1"
            size="0.8em"
            color="var(--color-text-primary)"
          />
        </Trigger>
      )}
      <ContextMenu
        onClick={e => {
          e.preventDefault();
        }}
        theme="company-switcher"
        menuId={MENU_ID}
      >
        <ActiveCompany onClick={() => handleEditCompany(activeCompany.id)}>
          {renderAvatar(activeCompany)}
          <div className="ml-2 d-flex flex-column">
            <strong>{relatedActiveCompany?.trading_name.toLowerCase()}</strong>
            <small>
              <SubscriptionInfo>
                <UserCount status={relatedActiveCompany?.subscription_status}>
                  {FORMATTERS.SUBSCRIPTION_STATUS(
                    relatedActiveCompany?.subscription_status,
                  )}
                </UserCount>
                <Dot>•</Dot>
                <PlanName>
                  {relatedActiveCompany?.subscription_plan_name.split(' ')[0]}
                </PlanName>
                <Dot>•</Dot>
                <UserCount>
                  {`${relatedActiveCompany?.user_count} ${pluralize('usuário', relatedActiveCompany?.user_count)}`}
                </UserCount>
              </SubscriptionInfo>
            </small>
          </div>
          <div className="ml-auto d-flex justify-content-center">
            <LuSettings size={24} />
          </div>
        </ActiveCompany>
        <MenuItem onClick={() => handleRedirect('/configuracoes/dados-empresa')}>
          <LuSettings size={14} className="mr-2" />
          Configurações
        </MenuItem>
        <MenuItem onClick={() => handleRedirect('/configuracoes/assinatura')}>
          <LuCreditCard size={14} className="mr-2" />
          Assinatura
          {currentCompany && (
            <span className="ml-auto">
              <SubscriptionStatusTag status={currentCompany.subscription_status} />
            </span>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleRedirect('/configuracoes/usuarios')}>
          <LuUsers size={14} className="mr-2" />
          Usuários
        </MenuItem>
        {!isEmpty(otherCompanies) && (
          <div className="mt-2 mb-2">
            <SearchMenuItem>
              <small>Minhas Empresas (responsável)</small>
              <span>
                <Button
                  variant="link"
                  size="sm"
                  className="p-0 m-0 d-flex justify-content-center"
                  onClick={() => {
                    handleRedirect('/configuracoes/empresas');
                  }}
                >
                  Ver todas
                  <IoChevronForward size={12} className="ml-1" />
                </Button>
              </span>
            </SearchMenuItem>
            {renderList(myCompanies)}
            {!isEmpty(guestCompanies) && (
              <>
                <SearchMenuItem>
                  <small>Outras empresas (convidado)</small>
                </SearchMenuItem>
                {renderList(guestCompanies)}
              </>
            )}
          </div>
        )}
        <Footer>
          <AddCompanyButton
            variant="link"
            onClick={() => handleRedirect('/configuracoes/empresas')}
          >
            <IoAddOutline size={20} className="mr-2" />
            Adicionar outra conta
          </AddCompanyButton>
        </Footer>
      </ContextMenu>
    </>
  );
}

CompanySwitcher.propTypes = {
  isLoading: false,
  companiesList: [],
  activeCompany: {},
};

CompanySwitcher.propTypes = {
  activeCompany: PropTypes.object,
  isLoading: PropTypes.bool,
  companiesList: PropTypes.array,

  onFetchActiveCompany: PropTypes.func.isRequired,
  onFetchMyCompanies: PropTypes.func.isRequired,
  onActivateCompany: PropTypes.func.isRequired,
  onFetchCompanies: PropTypes.func.isRequired,
};

export default CompanySwitcher;
