import constants from '../../_constants/subscription.constants';

const updateStoreSubscription = (updatedSubscription, paymentAlert) => {
  return dispatch => {
    dispatch(request(updatedSubscription, paymentAlert));
  };

  function request(updatedSubscription, paymentAlert) {
    return {
      type: constants.UPDATE_STORE_SUBSCRIPTION_REQUEST,
      payload: {
        updatedSubscription: updatedSubscription,
        paymentAlert: paymentAlert,
      },
    };
  }
};

export default updateStoreSubscription;
