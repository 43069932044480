import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import { PageHeader, TableLookup } from '_components/_core';

import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';
import CostCenterForm from './CostCenterForm';

function CostsCenter({
  activeCompany,
  costsCenter,
  onFetchCostsCenter,
  onDeleteCostCenter,
  onCreateCostCenter,
  onUpdateCostCenter,
  isCompact,
}) {
  const [selectedItem, setSelectedItem] = useState(null);

  const [sorting, setSorting] = useState({
    field: 'description',
    order: 'asc',
  });

  useEffect(() => {
    onFetchCostsCenter();
  }, [onFetchCostsCenter, activeCompany]);

  const handleEdit = useCallback(
    id => {
      setSelectedItem(null);

      const item = costsCenter.find(item => item.id === id);

      setSelectedItem(item);
    },
    [costsCenter],
  );

  const handleClear = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const handleDelete = useCallback(
    id => {
      onDeleteCostCenter(id);
    },
    [onDeleteCostCenter],
  );

  const handleSorting = useCallback(({ field, order }) => {
    setSorting({
      field,
      order,
    });
  }, []);

  const formProps = useMemo(
    () => ({
      costCenter: selectedItem,
      onCreateCostCenter,
      onUpdateCostCenter,
      onClearCostCenter: handleClear,
    }),
    [handleClear, onCreateCostCenter, selectedItem, onUpdateCostCenter],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const costCenterId = urlParams.get('cost_center_id');

    if (costCenterId) {
      handleEdit(costCenterId);
    }
  }, [handleEdit]);

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && <PageHeader title="Centros de Custo" variant="small" />}
      <h4 className="mb-3 mt-3">
        {selectedItem ? 'Editar Centro de Custo' : 'Cadastrar Centro de Custo'}
      </h4>
      <CostCenterForm {...formProps} />
      <TableLookup
        keyName="id"
        data={costsCenter}
        onEdit={handleEdit}
        onDelete={handleDelete}
        sorting={sorting}
        onSorting={handleSorting}
        columns={TABLE_COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
      />
    </Container>
  );
}

CostsCenter.defaultProps = {
  costsCenter: [],
  isCompact: false,
};

CostsCenter.propTypes = {
  activeCompany: PropTypes.object,
  costsCenter: PropTypes.any,
  onFetchCostsCenter: PropTypes.func.isRequired,
  onDeleteCostCenter: PropTypes.func.isRequired,
  onCreateCostCenter: PropTypes.func.isRequired,
  onUpdateCostCenter: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
};

export default CostsCenter;
