import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';

import alertActionCreators from '../alert.actions';

const declineCompanyInvitation = (params, successCallback, errorCallback) => {
  return async dispatch => {
    confirmDialog.warning({
      title: 'Recusar convite',
      message:
        'Tem certeza que deseja recusar o convite? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, recusar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(params));

        service
          .declineInvitation(params)
          .then(() => {
            dispatch(success(params));

            if (successCallback) {
              successCallback(params);
            }

            dispatch(alertActionCreators.success('Convite recusado com sucesso!'));
          })
          .catch(error => {
            if (errorCallback) {
              errorCallback(error);
            }

            dispatch(failure(error));
          });
      },
      onCancel: () => {},
    });
  };

  function request(params) {
    return {
      type: constants.DECLINE_COMPANY_INVITATION_REQUEST,
      payload: params,
    };
  }
  function success(params) {
    return {
      type: constants.DECLINE_COMPANY_INVITATION_SUCCESS,
      payload: params,
    };
  }
  function failure(error) {
    return {
      type: constants.DECLINE_COMPANY_INVITATION_FAILURE,
      payload: { error },
    };
  }
};

export default declineCompanyInvitation;
