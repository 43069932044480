import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import { PageHeader, TableLookup } from '_components/_core';

import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';
import TagForm from './TagForm';

function Tags({
  activeCompany,
  tags,
  onFetchTags,
  onDeleteTag,
  onCreateTag,
  onUpdateTag,
  isCompact,
}) {
  const [selectedItem, setSelectedItem] = useState(null);

  const [sorting, setSorting] = useState({
    field: 'description',
    order: 'asc',
  });

  useEffect(() => {
    onFetchTags();
  }, [onFetchTags, activeCompany]);

  const handleEdit = useCallback(
    id => {
      setSelectedItem(null);

      const item = tags.find(item => item.id === id);

      setSelectedItem(item);
    },
    [tags],
  );

  const handleClear = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const handleDelete = useCallback(
    id => {
      onDeleteTag(id);
    },
    [onDeleteTag],
  );

  const handleSorting = useCallback(({ field, order }) => {
    setSorting({
      field,
      order,
    });
  }, []);

  const formProps = useMemo(
    () => ({
      tag: selectedItem,
      onCreateTag,
      onUpdateTag,
      onClearTag: handleClear,
    }),
    [handleClear, onCreateTag, selectedItem, onUpdateTag],
  );

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && <PageHeader title="Marcadores (Tags)" variant="small" />}
      <h4 className="mb-3 mt-3">{selectedItem ? 'Editar' : 'Criar'} Tag</h4>
      <TagForm {...formProps} />
      <TableLookup
        keyName="id"
        data={tags}
        onEdit={handleEdit}
        onDelete={handleDelete}
        sorting={sorting}
        onSorting={handleSorting}
        columns={TABLE_COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
      />
    </Container>
  );
}

Tags.defaultProps = {
  tags: [],
  isCompact: false,
};

Tags.propTypes = {
  activeCompany: PropTypes.object,
  tags: PropTypes.any,
  onFetchTags: PropTypes.func.isRequired,
  onDeleteTag: PropTypes.func.isRequired,
  onCreateTag: PropTypes.func.isRequired,
  onUpdateTag: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
};

export default Tags;
