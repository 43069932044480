import styled, { css } from 'styled-components';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Item } from 'react-contexify';

import Button from '_components/_core/Button';
import Tag from '_components/_core/Tag/Tag';

export const Dropdown = styled(BootstrapDropdown)`
  &&& {
    
  }
`;

export const Body = styled.div`
  width: auto;
  min-width: 315px;
  padding: 8px;
  z-index: 990;
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  overflow-y: auto;
  overflow-x: hidden;
  color: rgb(42, 46, 52);

  ${({ variant }) =>
    variant === 'navbar' &&
    css`
    top: 40px !important;
    right: 0 !important;
    left: auto !important;
  `};  

  ${({ isMobile }) =>
    isMobile &&
    css`
    min-width: unset;
    margin: 0px;
    width: 100vw;
    left: 0 !important;
    right: 0 !important;
    top: 65px !important;
    position: fixed;
    z-index: 999999 !important;
  `}
`;

export const GroupHeader = styled.div`
  color: rgb(101, 111, 125);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  padding: 8px 16px 4px 16px;
`;

export const Heading = styled.div`
  color: rgb(42, 46, 52);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 1px;
  border-bottom: 1px solid #E8E7EA;
  padding: 16px 16px;
`;

export const Footer = styled.div`
  font-weight: 500;
  border-top: 1px solid #E8E7EA;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;

  svg {
    padding: 0;
    margin: 0;
  }

`;

export const Trigger = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 8px;

  &.active {
    text-decoration: none;
    background-color: transparent;
    border: none;
  }

  &.btn-primary.dropdown-toggle {
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
      background-color: #f5f5f5;
      text-decoration: none;
    }
  }

  &.btn-primary.dropdown-toggle:focus {
    background-color: #f5f5f5;
  }

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    text-decoration: none;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 15px;
    margin-left: 0.2rem;
    color: rgb(42, 46, 52);
    
    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin-right: 0.5rem;

    &:hover {
      cursor: pointer;
      background-color: unset;
    }
  }

  div.avatar {
    height: 24px;
    width: 24px;
    margin-right: 6px;
    font-size: .875rem;
    border-radius: .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7ebe9;
    color: rgb( 48, 90, 80 );
    font-weight: 600;
  }
`;

export const ActiveCompany = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 8px;

  strong {
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  small {
    font-size: 12px;
    color: rgb(101, 111, 125);
  }

  svg {
    margin-right: 8px;
    color: rgb(101, 111, 125);
  }

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 6px;
  }

  div.avatar {
    height: 38px;
    width: 38px;
    margin-right: 6px;
    font-size: .875rem;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7ebe9;
    color: rgb( 48, 90, 80 );
    font-weight: 600;
  }
`;

export const MenuItem = styled(Item)`
  /* color: rgb(42, 46, 52);
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  height: 32px;
  border-radius: 6px;
  padding-inline: 11px;
  gap: 6px;

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  } */

`;

export const SubMenu = styled(BootstrapDropdown)`
 
`;

export const SubMenuLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CompanyAvatar = styled.img`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  margin-right: 8px;

  ${({ size }) =>
    size === 'small' &&
    css`
    width: 20px;
    height: 20px;
  `}
`;

export const AddCompanyIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dddaff;

  svg {
    color: #3f3f3f;
  }
`;

export const SearchMenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  position: sticky;
  top: 0;
  text-transform: uppercase;

  /* border-top: 1px solid #E8E7EA; */

  small {
    width: 100%;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    line-height: 12px;
    color: rgb( 101, 111, 125 );
  }
`;

export const CompanyListItem = styled.div`
  height: 50px;
  border-radius: 6px;
  padding: 8px;
  margin-top: 0px;

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer !important;
  }

  ${({ active }) =>
    active &&
    css`
    background-color: #f5f5f5;  
  `}

  div.avatar {
    height: 32px;
    width: 32px;
    margin-right: 6px;
    font-size: .875rem;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7ebe9;
    color: rgb( 48, 90, 80 );
    font-weight: 600;
  }

  strong {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  small {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #99A1B7;
  }
`;

export const AddCompanyListItem = styled.div`
  height: 50px;
  border-radius: 6px;
  padding: 8px;
  margin-top: 0px;

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  div.avatar {
    height: 32px;
    width: 32px;
    margin-right: 6px;
    font-size: .875rem;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7ebe9;
    color: rgb( 48, 90, 80 );
    font-weight: 600;
  }

  strong {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  small {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #99A1B7;
  }
`;

export const CustomFilterButton = styled(Button)`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 500;
  color: #0D0E10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 !important;
  margin-right: 8px;
  background-color: #F9FBFC;
  border: 1px solid #E8E7EA;
  border-radius: 8px;

  :hover, :focus {
    border: 1px solid #E8E7EA;
    text-decoration: none;
    color: #0D0E10;
  }

  &&& {
    .dropleft .dropdown-toggle::before {
      display: none;
    }

    &::before {
      display: none !important;
    }
  }

  &::after {
    content: none !important;
  }
`;

export const CustomButton = styled.li`
  padding: 16px;
  width: 100%;
  color: var(--title-color);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &::after {
    content: none !important;
  }

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  span {
    opacity: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.4rem;
    width: 100%;
    position: relative;
    text-decoration: none !important;
    color: var(--title-color);

    div {
      display: flex;
      margin-right: 16px;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    p {
      max-width: 500px;
      will-change: max-width, opacity;
      overflow: hidden;
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      padding: 0;
      text-decoration: none !important;
    }
  }
`;

export const InputContainer = styled.div`
  padding: 16px 16px;
  color: #393A3D;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 400;
    color: #393A3D;

    li {
      padding: 4px 0;

      &.title {
        color: #393A3D;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: 1px;
        margin-bottom: 4px;
      }

      a {
        color: #192331fc;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
    padding-top: 0;
    /* padding: 0;
    height: calc(100vh - 66px - 45px - 60px);
    overflow-x: hidden;
    overflow-y: auto; */


    ul {
      li {

        &.title:first-child {
          padding-top: 8px;
        }

        &.title {       
          padding-top: 16px;
          font-size: 14px;
          line-height: 1.5;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
  
  `}
`;

export const SubscriptionInfo = styled.small`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PlanName = styled.span`

`;

export const UserCount = styled.span`

${({ status }) =>
  status === 'active' &&
  css`
      color: #00ab6f;
  `}

  ${({ status }) =>
    status === 'trialing' &&
    css`
      color: #0997d9;
  `}

  ${({ status }) =>
    status === 'trial-ended' &&
    css`
      color: #d46b08;
  `}

  ${({ status }) =>
    status === 'canceled' &&
    css`
      color: #b82a23;
  `}
`;

export const Dot = styled.span`
  padding-left: 3px;
  padding-right: 3px;
`;

export const RoleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RoleName = styled(Tag)`
  padding: 2px 4px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

export const AddCompanyButton = styled(Button)`
  margin: 0;
  padding: 4px 0px;
  padding-bottom: 0px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 0.5rem;
  height: 0.5rem;
  border: 0px;
  border-radius: 9999px;
  box-shadow: 0 0 0 1px #ffffff;
  background-color: #ec6b00;
  gap: 0.25rem;
  transition: box-shadow 0.25s;
  margin-left: 0.5rem;

  ${({ status }) =>
    status === 'active' &&
    css`
      background-color: #00ab6f;
  `}

  ${({ status }) =>
    status === 'trialing' &&
    css`
      background-color: #0997d9;
  `}

  ${({ status }) =>
    status === 'trial-ended' &&
    css`
      background-color: #d46b08;
  `}

  ${({ status }) =>
    status === 'canceled' &&
    css`
      background-color: #b82a23;
  `}
`;

export const SmallHeader = styled.small`
  text-transform: uppercase;
  font-weight: 600;
  color: darkgray;
  letter-spacing: .5px;
`;
