const sanitizePlanName = name => {
  if (!name) return name;

  return String(name)
    .replace('Mensal', '')
    .replace('Semestral', '')
    .replace('Anual', '')
    .trim();
};

export default sanitizePlanName;
