import { connect } from 'react-redux';
import { compose } from 'redux';

import paymentsActionsCreators from '_store/_actions/payments';
import subscriptionActionCreators from '_store/_actions/subscription';
import companyActionCreators from '_store/_actions/company';

import CancelSubscription from './CancelSubscription';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchPayments: paymentsActionsCreators.fetchPayments,
  onFetchPlans: subscriptionActionCreators.fetchSubscriptionPlans,
  onFetchSubscription: subscriptionActionCreators.getUserSubscriptionStatus,
  onCancelSubscription: subscriptionActionCreators.cancelSubscription,
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onFetchActiveCompany: companyActionCreators.getActiveCompany,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CancelSubscription);
