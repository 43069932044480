import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  position: fixed;
  top: 49px;
  left: 0;
  right: 0;
  z-index: 994;

  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
    --actions-bar-height: 49px;
  `}

  ${({ isVisible }) =>
    !isVisible &&
    css`
    --actions-bar-height: 0px;
  `}


  ${({ variant }) =>
    variant === 'trialing' &&
    css`
    background-color: #ebf4fa;
    border-bottom: 0.2px solid #0069b4;

    p {
      color: #002b64;    
    }
  `}

  ${({ variant }) =>
    variant === 'trial-ended' &&
    css`
    background-color: #faf5dc;
    border-bottom: 0.2px solid #fcca00;

    p {
      color: #703d00;    
    }
  `}
  
  ${({ variant }) =>
    variant === 'canceled' &&
    css`
    background-color: #fcedeb;
    border-bottom: 0.2px solid #e64d43;

    p {
      color: #9c150e;
    }
  `}

  ${({ variant }) =>
    variant === 'incomplete' &&
    css`
    background-color: #fcedeb;
    border-bottom: 0.2px solid #e64d43;

    p {
      color: #9c150e;
    }
  `}

  ${({ variant }) =>
    variant === 'past_due' &&
    css`
    background-color: #fcedeb;
    border-bottom: 0.2px solid #e64d43;

    p {
      color: #9c150e;
    }
  `}


`;
