import confirmDialog from 'helpers/confirmDialog';

import alertActionCreators from '../alert.actions';
import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const leaveCompany = (companyId, companyName, callback) => {
  return dispatch => {
    confirmDialog.warning({
      title: 'Sair da empresa',
      message: `Tem certeza que deseja sair da empresa <strong>${companyName}</strong>? Você não terá mais acesso a essa empresa e todas as informações relacionadas a ela.`,
      confirmButtonText: 'Sim, sair!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(companyId));

        service
          .leaveCompany(companyId)
          .then(() => {
            dispatch(success(companyId));

            dispatch(alertActionCreators.success('Você saiu da empresa com sucesso!'));

            if (callback) {
              callback();
            }
          })
          .catch(error => {
            failure(error);
          });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return { type: constants.LEAVE_COMPANY_REQUEST, payload: { id } };
  }
  function success(id) {
    return {
      type: constants.LEAVE_COMPANY_SUCCESS,
      payload: {
        id,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.LEAVE_COMPANY_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default leaveCompany;
