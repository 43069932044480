import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';
import { FaCheckCircle, FaFilter, FaInbox } from 'react-icons/fa';

import { Button } from '_components/_core';

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: start;
  border: 2px solid var(--td-border-color);
  padding: 20px 30px;
  margin-bottom: 10px;
  width: 350px;
  border-radius: 6px;
  color: var(--color-text-primary);
  background-color: #ffffff;

  h5 {
    font-size: 14px;
  }

  :hover {
    border: 2px solid var(--accent-color);
    color: var(--color-text-primary);
    background-color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    svg {
      color: var(--accent-color);
    }

    h5 {
      color: var(--accent-color);
    }
  }

  :active {
    color: var(--color-text-primary) !important;
    background-color: #ffffff !important;
  }
`;

export const StyledForm = styled(Form)`  
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: unset !important;
  }
`;

export const SectionHeader = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
  color: var(--accent-color);
  font-weight: 600;
`;

export const Container = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 500px auto;
  gap: 32px;
  /* background-color: #fff; */
  

  div.left-panel {
    height: 100%;
    background-color: #FAFAFA;
    padding: 32px;
    position: relative;
  }

  div.right-panel {
    height: 100%;
    padding: 32px;
    position: relative;
    align-items: center;
  }

  .header-links-right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .footer-links-left {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
  
  .footer-links-right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
  }

  ${({ isMobile }) => isMobile && css`
    grid-template-columns: 1fr;

    div.left-panel {
      display: none;
    }
  `}
`;

export const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  top: 8%;
  position: relative;
  background-color: #fff;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

export const StepsGuide = styled.ul`
  list-style: none;
  border-left: 3px solid #e0e0e0;
  margin-top: 64px;
  margin-left: 24px;
`;

export const Step = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  margin-left: -40px;

  ${({ normalMarging }) => normalMarging && css`
    margin-left: 0;
    margin-bottom: 0px;
  `}

  div.icon-box {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 43px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e0e0e0;
  }
    
  div.step-info {
    margin-left: 16px;
  }

  h5 {
    font-size: 16px;
    color: #A0A0A0;
    margin-bottom: 0px;
  }

  p {
    margin-bottom: 0px;
    color: #A0A0A0;
  }

  svg {
    color: #A0A0A0;
    font-size: 18px;
  }

  ${({ active }) => active && css`
    h5, p, svg {
      color: #333;
    }
  `}
`;

export const Title = styled.h1`
  font-size: 22px;
  margin-bottom: 8px;
  text-align: left;
  color: #333;
`;

export const Text = styled.p`
  font-size: 14px;
  color: grey;
  margin-bottom: 0;
  text-align: left;
  color: #333;
`;

export const EmptyIcon = styled(FaInbox)`
  font-size: 48px;
  color: #2980b9;
  margin-bottom: 16px;
`;

export const FilterIcon = styled(FaFilter)`
  font-size: 48px;
  color: #2980b9;
  margin-bottom: 16px;
`;

export const SuccessIcon = styled(FaCheckCircle)`
  font-size: 48px;
  color: #2ecc71;
  margin-bottom: 16px;
`;
