import { push } from 'connected-react-router';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const isActiveCompanyError = error => {
  const { response } = error;
  const { status, data } = response || {};
  const { metadata } = data || {};
  const { reason } = metadata || {};

  return status === 422 && ['COMPANY_REMOVED', 'NO_COMPANY_SELECTED'].includes(reason);
};

const getActiveCompany = () => {
  return dispatch => {
    dispatch(request());

    const product = process.env.REACT_APP_CONTABILLS_APP;

    const params = {
      product,
    };

    service.getActive(params).then(
      company => {
        dispatch(success(company));
      },
      error => {
        if (isActiveCompanyError(error)) {
          dispatch(push('/selecionar-empresa'));

          return;
        }

        dispatch(failure(error));
      },
    );
  };

  function request() {
    return {
      type: constants.COMPANY_GET_ACTIVE_REQUEST,
      payload: {},
    };
  }
  function success(company) {
    return {
      type: constants.COMPANY_GET_ACTIVE_SUCCESS,
      payload: { company: company.data },
    };
  }
  function failure(error) {
    return {
      type: constants.COMPANY_GET_ACTIVE_FAILURE,
      payload: { error },
    };
  }
};

export default getActiveCompany;
