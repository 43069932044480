import styled, { css } from 'styled-components';

import { Card } from '_components/_core';

export const CustomCard = styled(Card)`
  border: 1px solid #F1F1F4;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding-bottom: 0;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
`;

export const CustomCardHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #F1F1F4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 16px;
    margin-bottom: 0;
    color: #071437;
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
    cursor: pointer;

    :hover {
      background-color: #F9F9FB;
    }
  `}
`;

export const CustomCardBody = styled.div`
  padding: 16px;

  ${({ noPadding }) =>
    noPadding &&
    css`
    padding: 0;
  `}

`;

export const CustomCardFooter = styled.div`
  padding: 8px 16px;
  border-top: 1px solid #F1F1F4;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTable = styled.table`  
  &&& {
    font-size: 12.8px;

    /* thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: 1rem .75rem;
    } */

    thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: .625rem 1rem;
      color: #78829d;
      background-color: #fcfcfc;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr td {
      padding: 1rem .75rem;
      vertical-align: middle;
      border-top: 1px solid #e7eaef;
      font-weight: 400;
    }

    tbody tr:first-child td {
      border-top: none;
    }
  }
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 1rem;
  }

  span.user-name {
    color: #071437;
    font-weight: 600;
    /* font-size: 1.075rem !important; */
  }

  span.user-email {
    color: #99A1B7;
    font-weight: 500;
    /* font-size: .95rem !important; */
  }
`;

export const UserName = styled.span`
 color: #192939;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`;

export const UserEmail = styled.span`
color: #6b7c93;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

export const UserAvatar = styled.img`
  
`;
