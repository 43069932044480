import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import { Card, CardHeader, CardBody, Button, PageHeader } from '_components/_core';

function DeleteFinancialData({
  isLoading,
  activeCompany,
  financialData,
  onFetchFinancialDataForDeletion,
  onDeleteFinancialData,
  isCompact,
}) {
  const { company_name } = activeCompany || {};

  useEffect(() => {
    onFetchFinancialDataForDeletion();
  }, [onFetchFinancialDataForDeletion, activeCompany]);

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && <PageHeader title="Excluir dados" variant="small" />}
      <Card>
        <CardHeader
          title="Apagar dados da empresa"
          description="Ao confirmar todos os dados financeiros da empresa serão apagados."
        />
        <CardBody>
          <strong className="font-weight-bold">{company_name}</strong>
          <table className="mt-3 font-weight-normal w-50">
            <tbody>
              <tr>
                <td width="20%">Contas bancárias</td>
                <td>{financialData.accounts}</td>
              </tr>
              <tr>
                <td width="20%">Transações</td>
                <td>{financialData.transactions}</td>
              </tr>
              <tr>
                <td width="20%">Transferências</td>
                <td>{financialData.transfers}</td>
              </tr>
              <tr>
                <td width="20%">Categorias</td>
                <td>{financialData.categories}</td>
              </tr>
              <tr>
                <td width="20%">Contatos</td>
                <td>{financialData.contacts}</td>
              </tr>
              <tr>
                <td width="20%">Centros de Custo</td>
                <td>{financialData.cost_centers}</td>
              </tr>
              <tr>
                <td width="20%">Tags</td>
                <td>{financialData.tags}</td>
              </tr>
              <tr>
                <td width="20%">Arquivos / Comprovantes</td>
                <td>{financialData.files}</td>
              </tr>
            </tbody>
          </table>
          <Button
            isLoading={isLoading}
            variant="danger"
            className="mt-5"
            onClick={() => onDeleteFinancialData()}
          >
            Excluir todos os dados
          </Button>
        </CardBody>
      </Card>
    </Container>
  );
}

DeleteFinancialData.defaultProps = {
  isLoading: false,
  financialData: {
    accounts: 0,
    transactions: 0,
    categories: 0,
    contacts: 0,
    cost_centers: 0,
    tags: 0,
  },
  isCompact: false,
};

DeleteFinancialData.propTypes = {
  activeCompany: PropTypes.func.isRequired,
  financialData: PropTypes.object,
  onFetchFinancialDataForDeletion: PropTypes.func.isRequired,
  onDeleteFinancialData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isCompact: PropTypes.bool,
};

export default DeleteFinancialData;
