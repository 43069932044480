import { connect } from 'react-redux';
import { compose } from 'redux';

import paymentsActionsCreators from '_store/_actions/payments';
import subscriptionActionsCreators from '_store/_actions/subscription';

import AdditionalUsers from './AdditionalUsers';

const mapStateToProps = state => ({
  payments: state.payments.payments,
  activeCompany: state.company.activeCompany,
  userSeatsPricing: state.subscription.userSeatsPricing,
});

const mapDispatchToProps = {
  onFetchPayments: paymentsActionsCreators.fetchPayments,
  onFetchUserSeatsPricing: subscriptionActionsCreators.fetchUserSeatsPricing,
  onConfirmUserSeatsPurchase: subscriptionActionsCreators.confirmUserSeatsPurchase,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdditionalUsers);
