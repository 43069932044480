import {
  LuLandmark,
  LuTags,
  LuFolderTree,
  LuScrollText,
  LuCreditCard,
  LuStickyNote,
  LuBookmark,
  LuCalendarX,
  LuContact2,
  LuDatabaseBackup,
  LuBell,
  LuUser2,
  LuBuilding2,
  LuLock,
  LuTrash,
  LuBuilding,
} from 'react-icons/lu';

const getMenuItems = params => {
  const { user, isManager, isPersonalAccountType, isMigrated } = params;

  let items = [
    {
      iconComponent: LuBuilding,
      id: 'dados-empresa',
      name: 'Dados da empresa',
      section: 'Main',
      description: 'Razão Social, CNPJ, Endereço',
      link: '/configuracoes/dados-empresa',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    {
      iconComponent: LuCreditCard,
      id: 'assinatura',
      name: 'Assinatura',
      section: 'Main',
      description: 'Plano, faturas, dados de cobrança',
      link: '/configuracoes/assinatura',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    {
      iconComponent: LuContact2,
      id: 'usuarios',
      name: 'Usuários',
      section: 'Main',
      description: 'Convidar, remover usuários',
      link: '/configuracoes/usuarios',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    {
      iconComponent: LuLock,
      id: 'permissoes',
      name: 'Permissões',
      section: 'Main',
      description: 'Permissões de acesso',
      link: '/permissoes',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },

    {
      iconComponent: LuLandmark,
      id: 'contas-bancarias',
      name: 'Contas bancárias',
      section: 'Cadastros',
      description: 'Ex: Conta corrente, poupança',
      link: '/configuracoes/contas-bancarias',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
    },
    {
      iconComponent: LuBookmark,
      id: 'categorias',
      name: 'Categorias',
      section: 'Cadastros',
      description: 'Ex: Serviços, aluguel, salários',
      link: '/configuracoes/categorias',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
    },
    {
      iconComponent: LuFolderTree,
      name: 'Centros de custo',
      id: 'centros-de-custo',
      section: 'Cadastros',
      description: 'Ex: Departamentos, projetos',
      link: '/configuracoes/centros-de-custo',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
    },
    {
      iconComponent: LuTags,
      name: 'Marcadores (tags)',
      id: 'tags',
      section: 'Cadastros',
      description: 'Tags personalizadas',
      link: '/configuracoes/tags',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
    },
    {
      iconComponent: LuStickyNote,
      id: 'recibos',
      name: 'Recibos personalizados',
      section: 'Cadastros',
      description: 'Ex: Recebimentos, pagamentos',
      link: 'recibos',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
      isExternal: true,
    },
    {
      iconComponent: LuCalendarX,
      name: 'Bloquear período',
      id: 'bloquear-periodo',
      section: 'Main',
      description: 'Evite alterações em um período',
      link: '/configuracoes/bloquear-periodo',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    // {
    //   iconComponent: LuUnplug,
    //   name: 'API e Integrações',
    //   section: 'Main',
    //   id: 'integracoes',
    //   description: 'Chaves de API, integrações',
    //   link: '/configuracoes/integracoes',
    //   entry: 'is_company',
    //   iconColor: '#097BB7',
    //   iconBgColor: '#E1F5FE',
    // },
    {
      iconComponent: LuScrollText,
      name: 'Logs de atividades',
      section: 'Main',
      id: 'logs',
      description: 'Histórico de alterações na empresa',
      link: '/configuracoes/logs',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },

    {
      iconComponent: LuDatabaseBackup,
      id: 'exportar-dados',
      name: 'Exportar dados',
      section: 'Main',
      description: 'Backup de dados em Excel',
      link: '/configuracoes/exportar-dados',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    {
      iconComponent: LuTrash,
      id: 'excluir-dados',
      name: 'Excluir dados',
      section: 'Main',
      description: 'Apagar todos os dados da empresa',
      link: '/configuracoes/excluir-dados',
      entry: 'is_company',
      iconColor: '#b82a23',
      iconBgColor: 'rgba(222,68,54,.1)',
    },

    {
      iconComponent: LuUser2,
      name: 'Meu perfil',
      section: 'User',
      id: 'dados-pessoais',
      description: 'Dados pessoais, e-mail, senha',
      link: '/configuracoes/dados-pessoais',
      entry: 'is_personal',
      iconColor: '#11924F',
    },
    {
      iconComponent: LuBell,
      name: 'Preferências',
      id: 'preferencias',
      section: 'User',
      description: 'Suas preferências no sistema',
      link: '/configuracoes/preferencias',
      entry: 'is_personal',
      iconColor: '#11924F',
    },
    {
      iconComponent: LuBuilding2,
      name: 'Minhas empresas',
      id: 'empresas',
      section: 'User',
      description: 'Empresas que você tem acesso',
      link: '/configuracoes/empresas',
      entry: 'is_personal',
      iconColor: '#11924F',
    },
  ];

  if (isMigrated) {
    items = items.filter(item => item.id !== 'permissoes');
  } else {
    if (isPersonalAccountType) {
      const idsToRemove = ['USERS', 'PERMISSIONS', 'API_INTEGRATIONS', 'BLOCK_PERIOD'];

      items = items.filter(item => !idsToRemove.includes(item.id));
    }

    if (!isManager) {
      items = items.filter(item => item.id !== 'excluir-dados');
    }

    if (user.document_type === 'PF') {
      items = items.filter(item => item.id !== 'permissoes');
    }

    items = items.filter(item => item.id !== 'dados-empresa');
  }

  return items;
};

export default getMenuItems;
