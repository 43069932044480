import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Search from './Search';

const mapStateToProps = state => ({
  isSearching: state.companyTransactions.isSearching,
  activeCompany: state.company.activeCompany,
  searchResults: state.companyTransactions.searchResults,
  blockedPeriod: state.company.blockedPeriod,
  accounts: state.companyTransactions.accounts,
});

const mapDispatchToProps = {
  onUploadZeropaperSpreadsheet:
    companyTransactionsActionCreators.uploadZeropaperSpreadsheet,
  onSearchResults: companyTransactionsActionCreators.search,
  onUpdateTag: companyTransactionsActionCreators.updateTag,
  onDeleteTag: companyTransactionsActionCreators.deleteTag,
  onDeleteContact: companyTransactionsActionCreators.deleteContact,
  onFetchAccounts: companyTransactionsActionCreators.fetchAllAccounts,
  onFetchCategories: companyTransactionsActionCreators.fetchAllCategories,
  onFetchRecipients: companyTransactionsActionCreators.fetchAllRecipients,
  onFetchTags: companyTransactionsActionCreators.fetchAllTags,
  onFetchCostCenters: companyTransactionsActionCreators.fetchAllCostsCenter,
  onClearSearchResults: companyTransactionsActionCreators.clearSearchResults,
  onDeleteMultipleTransactions:
    companyTransactionsActionCreators.deleteMultipleTransactions,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_search']),
)(Search);
