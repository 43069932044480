import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { PERMISSIONS } from 'helpers';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';

import { Button, FastFilterSelect, FloatingCard } from '_components/_core';
import { LoadingIcon, PermissionsGate } from '_components/_shared';

import { StyledFormCheckbox } from './styles';

function Permissions({
  user_id,
  selectedUser,
  isOpen,
  onToggleForm,
  allUserPermissions,
  onUpdateUserPermissions,
  onFetchUserPermissions,
  onAfterSaveCallback,
  activeCompany,
}) {
  const userPermissions = useSelector(
    state => state.userPermissions.permissions[state.auth.user.id],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (!user_id) {
      return;
    }

    setIsLoading(true);

    onFetchUserPermissions(user_id, () => {
      setIsLoading(false);
    });
  }, [user_id, onFetchUserPermissions, isOpen]);

  const canManagePermissions = useMemo(
    () =>
      hasPermissions({
        permissions: ['manager_permissions'],
        userPermissions,
        type: 'all',
      }),
    [userPermissions],
  );

  const handleSubmit = useCallback(
    values => {
      const { user_id } = values;
      const permissions = values;

      delete permissions.user;
      delete permissions.allUserPermissions;
      delete permissions.user_id;

      setIsSubmitting(true);

      onUpdateUserPermissions(user_id, permissions, () => {
        if (onAfterSaveCallback) {
          onAfterSaveCallback();
        }

        setIsSubmitting(false);
      });
    },
    [onUpdateUserPermissions, onAfterSaveCallback],
  );

  const handlePermissionsChange = (permission, setFieldValue, values) => {
    setFieldValue(permission, !values[permission]);
  };

  const handleClearFields = useCallback(setFieldValue => {
    PERMISSIONS.forEach(permission => {
      setFieldValue(permission.key, false);
    });
  }, []);

  const handleGrantAll = useCallback(setFieldValue => {
    PERMISSIONS.forEach(permission => {
      setFieldValue(permission.key, true);
    });
  }, []);

  const renderPermissions = (values, setFieldValue, type = null) => {
    let validPermissions = [];

    if (type) {
      validPermissions = PERMISSIONS.filter(p => p.type === type && !p.hidden);
    } else {
      validPermissions = PERMISSIONS.filter(p => !p.type && !p.hidden);
    }

    return validPermissions.map(permission => (
      <ListGroup.Item key={permission.key} className="p-0 pl-3">
        <StyledFormCheckbox
          checked={values[permission.key]}
          type="checkbox"
          id={permission.key}
          label={permission.label}
          className={classNames({
            'text-muted': !values[permission.key],
          })}
          onChange={() => handlePermissionsChange(permission.key, setFieldValue, values)}
        />
      </ListGroup.Item>
    ));
  };

  const renderFooter = useCallback(
    handleSubmit => {
      if (!canManagePermissions) {
        return null;
      }

      return (
        <>
          <div className="d-flex">
            <Button
              variant="dark"
              onClick={handleSubmit}
              className="ml-2 w-100"
              isLoading={isSubmitting}
            >
              Salvar alterações
            </Button>
            <Button className="w-100" variant="default" onClick={onToggleForm}>
              Cancelar
            </Button>
          </div>
        </>
      );
    },
    [canManagePermissions, isSubmitting, onToggleForm],
  );

  const renderBeforeFooter = useCallback(
    setFieldValue => {
      if (!canManagePermissions) {
        return null;
      }

      return (
        <div className="p-2">
          <FastFilterSelect
            label="Ações rápidas"
            options={[
              { value: 'all', label: 'Conceder todas' },
              { value: 'none', label: 'Remover todas' },
            ]}
            onChange={value => {
              if (value === 'all') {
                handleGrantAll(setFieldValue);
              } else {
                handleClearFields(setFieldValue);
              }
            }}
          />
        </div>
      );
    },
    [canManagePermissions, handleClearFields, handleGrantAll],
  );

  const initialValues = useMemo(() => {
    if (!user_id) {
      return {};
    }

    return {
      user: null,
      allUserPermissions: allUserPermissions[user_id],
      user_id,
      ...allUserPermissions[user_id],
    };
  }, [user_id, allUserPermissions]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ handleSubmit, values, setFieldValue }) => (
        <FloatingCard
          title={
            <div className="d-flex flex-column">
              <span>Permissões de {selectedUser.full_name}</span>
              {activeCompany && (
                <small className="mt-1">Na empresa: {activeCompany.trading_name}</small>
              )}
            </div>
          }
          fullHeight
          isVisible={isOpen}
          onToggleVisibility={onToggleForm}
          footerContent={renderFooter(handleSubmit)}
          withCloseButton
          bodyClassName="p-4"
          beforeFooterContent={renderBeforeFooter(setFieldValue)}
        >
          <PermissionsGate permissions={['manager_permissions']} fallback type="all">
            <>
              {isLoading && (
                <div className="h-100 d-flex justify-content-center">
                  <LoadingIcon text="Aguarde ..." />
                </div>
              )}
              {!isLoading && (
                <>
                  <h4>Tela Transações - Abas</h4>
                  <Form.Row className="mt-4" as={Row}>
                    <Col xl={12} sm={12}>
                      <h5>
                        <strong className="text-success">Recebimentos</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'INCOME')}
                      </ListGroup>
                    </Col>
                    <Col xl={12} sm={12} className="mt-3">
                      <h5>
                        <strong className="text-danger">Despesas Fixas</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'FIXED_EXPENSE')}
                      </ListGroup>
                    </Col>
                    <Col xl={12} sm={12} className="mt-3">
                      <h5>
                        <strong className="text-danger">Despesas Variáveis</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'VARIABLE_EXPENSE')}
                      </ListGroup>
                    </Col>
                    <Col xl={12} sm={12} className="mt-3">
                      <h5>
                        <strong className="text-danger">Pessoas</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'PEOPLE')}
                      </ListGroup>
                    </Col>
                    <Col xl={12} sm={12} className="mt-3">
                      <h5>
                        <strong className="text-danger">Impostos</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'TAXES')}
                      </ListGroup>
                    </Col>
                    <Col xl={12} sm={12} className="mt-3">
                      <h5>
                        <strong className="text-info">Transferências</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'TRANSFER')}
                      </ListGroup>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xl={12} sm={12} className="mt-3">
                      <h5>
                        <strong>Outras seções</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'TRANSACTIONS_1')}
                        {renderPermissions(values, setFieldValue, 'TRANSACTIONS_2')}
                      </ListGroup>
                    </Col>
                  </Form.Row>
                  <hr className="mt-4 mb-4" />
                  <h4>Outras operações no Zenply</h4>
                  <Form.Row className="mt-4" as={Row}>
                    <Col xl={12} sm={12}>
                      <h5>
                        <strong className="text-gray">Recursos principais</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'VIEW_DATA')}
                      </ListGroup>
                    </Col>
                    <Col xl={12} sm={12} className="mt-3">
                      <h5>
                        <strong className="text-gray">Cadastros</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'CORE')}
                      </ListGroup>
                    </Col>
                    <Col xl={12} sm={12} className="mt-3">
                      <h5>
                        <strong className="text-gray">Arquivos</strong>
                      </h5>
                      <ListGroup>
                        {renderPermissions(values, setFieldValue, 'FILES')}
                      </ListGroup>
                    </Col>
                    <Col xl={12} sm={12} className="mt-3">
                      <h5>
                        <strong className="text-gray">Outras</strong>
                      </h5>
                      <ListGroup>{renderPermissions(values, setFieldValue)}</ListGroup>
                    </Col>
                  </Form.Row>
                </>
              )}
            </>
          </PermissionsGate>
        </FloatingCard>
      )}
    </Formik>
  );
}

Permissions.defaultProps = {
  allUserPermissions: {},
  selectedUser: {},
};

Permissions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  user_id: PropTypes.string.isRequired,
  selectedUser: PropTypes.object,
  allUserPermissions: PropTypes.object,
  onToggleForm: PropTypes.func.isRequired,
  onFetchUserPermissions: PropTypes.func.isRequired,
  onUpdateUserPermissions: PropTypes.func.isRequired,
  onAfterSaveCallback: PropTypes.func,
};

export default Permissions;
