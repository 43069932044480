import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import companySelectors from '_store/_selectors/company';

import ActionsBar from './ActionsBar';

const mapStateToProps = (state) => ({
  activeCompany: companySelectors.activeCompanySelector(state),
});

const mapDispatchToProps = {
  onFetchActiveCompany: companyActionCreators.getActiveCompany,
  onFetchCompanies: companyActionCreators.getAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsBar);
