import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import { Button, FormTextField } from '_components/_core';

import { UsersSchema } from '../../utilities';
import { StyledModal, ModalFooter } from './styles';

const UsersModal = ({
  isVisible,
  isLoading,
  onModalToggle,
  onInviteUser,
  onInviteUserCallback,
}) => {
  const handleInviteUser = useCallback(
    (values, { resetForm }) => {
      onInviteUser(values, () => {
        resetForm();
        onModalToggle();

        if (onInviteUserCallback) {
          onInviteUserCallback();
        }
      });
    },
    [onInviteUser, onModalToggle, onInviteUserCallback],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid) => (
      <ModalFooter>
        <Button variant="secondary" onClick={onModalToggle}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="dark"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
        >
          Enviar Convite
        </Button>
      </ModalFooter>
    ),
    [onModalToggle, isLoading],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        email: null,
      }}
      validationSchema={UsersSchema}
      onSubmit={handleInviteUser}
      enableReinitialize
    >
      {({ handleSubmit, isValid }) => (
        <>
          <StyledModal
            title="Convidar Usuário"
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>E-mail</Form.Label>
                  <FormTextField
                    name="email"
                    placeholder="E-mail"
                    type="email"
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default UsersModal;

UsersModal.defaultProps = {
  isVisible: false,
  isLoading: false,
  onInviteUserCallback: null,
};

UsersModal.propTypes = {
  isVisible: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  isLoading: PropTypes.func,
  onInviteUser: PropTypes.func,
  onInviteUserCallback: PropTypes.func,
};
