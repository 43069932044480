import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { FaRegFileCode, FaRegFilePdf } from 'react-icons/fa6';

import { Button } from '_components/_core';
import { CustomCard, CustomCardBody, CustomCardHeader } from '../../../../../styles';
import { Badge, Text } from '../styles';

function InvoiceNotaFiscal({ invoice }) {
  const { status, nfse_status, nfse_pdf_url, nfse_xml_url } = invoice;

  const isReady = ['ISSUED_SUCCESS', 'SENT_TO_CUSTOMER'].includes(nfse_status);

  return (
    <Row className="mt-3">
      <Col>
        <CustomCard>
          <CustomCardHeader>
            <h3>NFS-e da fatura</h3>
          </CustomCardHeader>
          <CustomCardBody>
            <Row>
              <Col className="d-flex justify-content-start align-items-center">
                {isReady && (
                  <>
                    <Button
                      variant="default"
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => {
                        window.open(nfse_pdf_url, '_blank');
                      }}
                    >
                      <FaRegFilePdf className="mr-2" />
                      Baixar PDF
                    </Button>
                    <Button
                      variant="default"
                      className="d-flex justify-content-center align-items-center ml-2"
                      onClick={() => {
                        window.open(nfse_xml_url, '_blank');
                      }}
                    >
                      <FaRegFileCode className="mr-2" />
                      Baixar XML
                    </Button>
                  </>
                )}
                {!isReady && (
                  <span className="d-flex justify-content-center align-items-center">
                    <Badge status="open" />
                    <Text className="ml-3">
                      {status === 'paid' && 'A Nota fiscal está na fila de emissão.'}
                      {status !== 'paid' &&
                        'A Nota fiscal será emitida após o pagamento.'}
                    </Text>
                  </span>
                )}
              </Col>
            </Row>
          </CustomCardBody>
        </CustomCard>
      </Col>
    </Row>
  );
}

InvoiceNotaFiscal.defaultProps = {
  invoice: null,
};

InvoiceNotaFiscal.propTypes = {
  invoice: PropTypes.object,
};
export default InvoiceNotaFiscal;
