import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';

import { useMediaQuery } from 'helpers';
import WebSocketManager from 'helpers/WebSocketManager';
import { WEBSOCKET_NAMES, WEBSOCKET_EVENTS } from 'helpers/constants';
import {
  AvatarMenu,
  ActivatedCompany,
  TrialCounter,
  CompanySwitcher,
  AvatarMenuV2,
} from '_components/_shared';

import Logo from './components/Logo';
import LogoSmall from './components/LogoSmall';
import LogoZenpaper from './components/LogoZenpaper';

import {
  StyledNavbar,
  NavbarContent,
  StyledButton,
  StyledNavbarBrandDesktop,
  NavbarBrandContainerDesktop,
} from './styles';
import SearchIcon from './components/SearchIcon/SearchIcon';
import LogoZenpaperSmall from './components/LogoZenpaperSmall';
import SettingsMenu from './components/SettingsMenu/SettingsMenuContainer';
import isUserMigrated from 'helpers/isUserMigrated';

export default function NavBar({
  activeCompany,
  collapsed,
  user,
  onSidebarToggle,
  onLoadUserPermissions,
  onLogout,
  onFetchBlockedPeriod,
  onFetchInvitations,
  onFetchCompanies,
  onFetchActiveCompany,
}) {
  const { isMobile, isTablet } = useMediaQuery();
  const location = useLocation();

  const [pageTitle, setPageTitle] = useState(document.title);

  const isMigrated = useMemo(() => {
    return isUserMigrated(user);
  }, [user]);

  useEffect(() => {
    onLoadUserPermissions(user.id);

    if (!location.pathname.includes('/configuracoes')) {
      onFetchBlockedPeriod();
    }

    const handleRouteChange = () => {
      setTimeout(() => {
        setPageTitle(
          document.title.replace(' - Zenply', '').replace('Extrato Bancário', ''),
        );
      }, 0);
    };

    handleRouteChange();
    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [
    location,
    user,
    activeCompany,
    onLoadUserPermissions,
    onFetchBlockedPeriod,
    location.pathname,
  ]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const { id } = user;

    const wsManager = new WebSocketManager(WEBSOCKET_NAMES.USER_EVENTS);

    wsManager.connect();

    const params = {
      user_id: id,
    };

    wsManager.joinRoom(params);

    wsManager.joinRoom({ company_id: activeCompany?.id });

    wsManager.subscribe(WEBSOCKET_EVENTS.USER_EVENTS.USER_INVITATION_CREATED, () => {
      onFetchInvitations();
    });

    wsManager.subscribe(WEBSOCKET_EVENTS.USER_EVENTS.USER_REMOVED_FROM_COMPANY, () => {
      onFetchCompanies();
      onFetchActiveCompany();
    });

    wsManager.subscribe(WEBSOCKET_EVENTS.USER_EVENTS.USER_ACTIVE_COMPANY_DELETED, () => {
      onFetchCompanies();
      onFetchActiveCompany();
    });

    return () => {
      wsManager.disconnect();
    };
  }, [onFetchInvitations, user, activeCompany, onFetchCompanies, onFetchActiveCompany]);

  const renderLogo = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <LogoZenpaper />;
    }

    if (collapsed && !isMobile && !isTablet) {
      return <LogoSmall />;
    }

    return <Logo />;
  }, [collapsed, isMobile, isTablet]);

  const renderCenterLogo = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <LogoZenpaperSmall />;
    }

    return <Logo />;
  }, []);

  const getPropertyRecursive = useCallback((items, pathname) => {
    let result = null;

    items.forEach(item => {
      if (item.path === pathname) {
        result = item.title;
      }

      if (item.children) {
        result = getPropertyRecursive(item.children, pathname);
      }
    });

    return result;
  }, []);

  if (isMobile) {
    return (
      <StyledNavbar isMobile={isMobile} expand="lg" fixed="top" className="no-print">
        <h6 className="m-0 text-uppercase">{pageTitle}</h6>
        <div className="d-flex justify-content-center align-items-center">
          <ActivatedCompany widgetType="NAVBAR" />
          <SettingsMenu user={user} isMobile={isMobile || isTablet} onLogout={onLogout} />
        </div>
      </StyledNavbar>
    );
  }

  return (
    <StyledNavbar expand="lg" fixed="top" className="no-print">
      {!isMobile && !isTablet && (
        <>
          <NavbarBrandContainerDesktop className="mr-3" collapsed={collapsed}>
            <StyledNavbarBrandDesktop href="#dashboard">
              {renderLogo()}
            </StyledNavbarBrandDesktop>
          </NavbarBrandContainerDesktop>
          {!isMigrated && <ActivatedCompany widgetType="NAVBAR" />}
          {isMigrated && <CompanySwitcher />}
        </>
      )}
      <NavbarContent isMobile={isMobile || isTablet}>
        {!isMobile && !isTablet && <span>&nbsp;</span>}
        {(isMobile || isTablet) && (
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <StyledButton className="p-0 mr-3" variant="link" onClick={onSidebarToggle}>
              {!collapsed && <AiOutlineMenuFold size="1.5rem" />}
              {collapsed && <AiOutlineMenuUnfold size="1.5rem" />}
            </StyledButton>
            {renderCenterLogo()}
          </span>
        )}
        <div className="row mr-0 mr-lg-4 d-flex justify-content-center align-items-center">
          {(isMobile || isTablet) && <ActivatedCompany widgetType="NAVBAR" />}
          <TrialCounter />
          <SearchIcon isMobile={isMobile || isTablet} />
          {!isMigrated && <AvatarMenu />}
          {isMigrated && <AvatarMenuV2 widgetType="NAVBAR" />}
          <SettingsMenu
            user={user}
            isMobile={isMobile || isTablet}
            onLogout={onLogout}
            isMigrated={isMigrated}
          />
        </div>
      </NavbarContent>
    </StyledNavbar>
  );
}

NavBar.defaultProps = {
  user: {},
};

NavBar.propTypes = {
  activeCompany: PropTypes.object,
  user: PropTypes.object,
  collapsed: PropTypes.bool.isRequired,
  onSidebarToggle: PropTypes.func.isRequired,
  onLoadUserPermissions: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
