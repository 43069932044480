import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import isEmpty from 'lodash/isEmpty';
import ReactTooltip from 'react-tooltip';

import { SPECIAL_COLUMNS } from './utils';
import { Header, Body, Footer, ColumnToggle } from './components';

function CustomTable({
  columns,
  data,
  footerData,
  keyName,
  className,
  defaultColumns,
  showHeader,
  showFooter,
  showColumnToggle,
  isLoading,
  selectedItems,
  sorting,
  pagination,
  onEdit,
  editHint,
  onDelete,
  deleteHint,
  onView,
  viewHint,
  onRefresh,
  refreshHint,
  onConfirm,
  confirmHint,
  onFavorite,
  favoriteHint,
  onItemSelected,
  onSorting,
  onSelectAll,
  name,
  onRowClick,
  onArchive,
  onUnarchive,
  isArchived,
  archiveHint,
  unarchiveHint,
  ...restProps
}) {
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const filteredColumns =
      JSON.parse(localStorage.getItem(`${name}_columnFilters`)) || [];
    const filteredColumnsNames = filteredColumns.map(column => column.keyName);

    if (showColumnToggle && !isEmpty(filteredColumnsNames) && columns) {
      return columns.filter(column => filteredColumnsNames.includes(column.keyName));
    }
    if (defaultColumns && columns) {
      return columns.filter(column => defaultColumns.includes(column.keyName));
    }

    return columns;
  });
  const handleVisibleColumnsChange = useCallback(newColumns => {
    setVisibleColumns(newColumns);
  }, []);

  const handleSorting = useCallback(
    (field, order) => {
      if (onSorting) {
        onSorting({
          field,
          order,
        });
      }
    },
    [onSorting],
  );

  return (
    <>
      <ReactTooltip html />
      <Table className={className} {...restProps}>
        {showColumnToggle && (
          <ColumnToggle
            data={data}
            name={name}
            filename={name}
            columns={columns}
            visibleColumns={visibleColumns}
            onVisibleColumnsChange={handleVisibleColumnsChange}
          />
        )}
        {showHeader && (
          <Header
            visibleColumns={visibleColumns}
            sorting={sorting}
            onSorting={handleSorting}
            onItemSelected={onItemSelected}
            selectedItems={selectedItems}
            onSelectAll={onSelectAll}
            data={data}
          />
        )}
        <Body
          keyName={keyName}
          data={data}
          columns={visibleColumns}
          selectedItems={selectedItems}
          onEdit={onEdit}
          editHint={editHint}
          onDelete={onDelete}
          deleteHint={deleteHint}
          onView={onView}
          viewHint={viewHint}
          onRefresh={onRefresh}
          refreshHint={refreshHint}
          onConfirm={onConfirm}
          confirmHint={confirmHint}
          onFavorite={onFavorite}
          favoriteHint={favoriteHint}
          onItemSelected={onItemSelected}
          onRowClick={onRowClick}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
          isArchived={isArchived}
          archiveHint={archiveHint}
          unarchiveHint={unarchiveHint}
        />
        {showFooter && (
          <Footer
            data={data}
            footerData={footerData}
            keyName={keyName}
            selectedItems={selectedItems}
            visibleColumns={visibleColumns}
            onItemSelected={onItemSelected}
            onSelectAll={onSelectAll}
          />
        )}
      </Table>
    </>
  );
}

export { SPECIAL_COLUMNS, ColumnToggle };

CustomTable.defaultProps = {
  data: [],
  footerData: [],
  onItemSelected: null,
  showHeader: true,
  showFooter: false,
  showColumnToggle: false,
  defaultColumns: null,
  onSorting: null,
  onSelectAll: null,
  sorting: null,
  selectedItems: [],
  isLoading: false,
  pagination: null,
  editHint: null,
  deleteHint: null,
  viewHint: null,
  refreshHint: null,
  confirmHint: null,
  onRowClick: null,
  onArchive: null,
  onUnarchive: null,
  isArchived: false,
  archiveHint: null,
  unarchiveHint: null,
};

CustomTable.propTypes = {
  className: PropTypes.string,
  keyName: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  defaultColumns: PropTypes.array,
  data: PropTypes.array,
  footerData: PropTypes.array,
  selectedItems: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onView: PropTypes.func,
  onRefresh: PropTypes.func,
  onConfirm: PropTypes.func,
  onFavorite: PropTypes.func,
  onItemSelected: PropTypes.func,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showColumnToggle: PropTypes.bool,
  onSorting: PropTypes.func,
  onSelectAll: PropTypes.func,
  sorting: PropTypes.object,
  isLoading: PropTypes.bool,
  pagination: PropTypes.object,
  name: PropTypes.string.isRequired,
  editHint: PropTypes.string,
  deleteHint: PropTypes.string,
  viewHint: PropTypes.string,
  refreshHint: PropTypes.string,
  confirmHint: PropTypes.string,
  favoriteHint: PropTypes.string,
  onRowClick: PropTypes.func,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  isArchived: PropTypes.bool,
  archiveHint: PropTypes.string,
  unarchiveHint: PropTypes.string,
};

export default CustomTable;
