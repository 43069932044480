/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { LuArrowLeftRight, LuCheckCircle2, LuReceipt, LuUserPlus2 } from 'react-icons/lu';

import FORMATTERS from 'helpers/formatters';
import { sanitizePlanName, useMediaQuery } from 'helpers';
import { Button } from '_components/_core';

import { CustomCard, CustomCardBody, CustomCardHeader } from '../../../../../styles';
import { PlanDescription, PlanFeatures, PlanName, Badge } from '../styles';

const STATUS_BADGES = {
  active: 'success',
  trialing: 'warning',
  past_due: 'danger',
  canceled: 'danger',
};

const CYCLE_MONTHS = {
  1: 'mensal',
  6: 'semestral',
  12: 'anual',
};

const CYCLE_MONTHS2 = {
  1: 'mês',
  6: 'semestre',
  12: 'anos',
};

function SubscriptionItemsCard({ subscription, onChangeView }) {
  const { isMobile } = useMediaQuery();

  const { max_users, max_custom_receipts, max_storage_size_megabytes } =
    subscription.plan.features;

  const FeatureItem = ({ feature, description }) => (
    <li>
      <div className="d-flex align-items-center">
        <LuCheckCircle2 size="1.1rem" />
        <span className="ml-2">
          {feature}
          {description && <small>{description}</small>}
        </span>
      </div>
    </li>
  );

  const features = [
    'Fluxo de Caixa Completo',
    `${max_users} Usuários`,
    `${max_custom_receipts} Recibos Personalizados`,
    `${max_storage_size_megabytes / 1000} GB de armazenamento`,
  ];

  const additional_items = subscription.addons.map(addon => {
    return {
      name: addon.product_name,
      price: addon.unit_amount,
      quantity: addon.quantity,
      total: addon.total_amount,
      purchase_date: addon.purchase_date,
    };
  });

  const subscriptionMessage = useMemo(() => {
    if (!subscription) {
      return '';
    }

    const { status, dates, total_amount } = subscription;
    const { renew_at, trial_end_at, canceled_at } = dates || {};

    let message = '';

    if (status === 'trialing') {
      message = `Teste gratuito até ${FORMATTERS.DATE_DDMMYYYY(trial_end_at)}`;
    }

    if (status === 'trial-ended') {
      message = `Teste gratuito encerrado em ${FORMATTERS.DATE_DDMMYYYY(trial_end_at)}`;
    }

    if (status === 'active') {
      message = `Próxima cobrança: ${FORMATTERS.NUMBER(total_amount)} em ${FORMATTERS.DATE_DDMMYYYY(renew_at)}`;
    }

    if (status === 'canceled') {
      message = `Cancelada em ${FORMATTERS.DATE_DDMMYYYY(canceled_at)}`;
    }

    return message;
  }, [subscription]);

  const showSwitchPlansButton = useMemo(() => {
    if (!subscription) {
      return false;
    }

    return false;

    return ['active'].includes(subscription.status);
  }, [subscription]);

  return (
    <CustomCard>
      <CustomCardHeader>
        <div className="d-flex justify-content-center align-items-center">
          <Badge status={STATUS_BADGES[subscription.status]} className="mr-3" />
          <div className="d-flex flex-column justify-content-center align-items-start">
            <PlanName>
              Zenply {sanitizePlanName(subscription.plan.name)} (
              {CYCLE_MONTHS[subscription.plan.cycle_months]})
            </PlanName>
            <PlanDescription>{subscriptionMessage}</PlanDescription>
          </div>
        </div>
        <div>
          <div className="d-flex align-items-center">
            {showSwitchPlansButton && (
              <Button
                className="d-flex align-items-center"
                size="sm"
                variant="default"
                onClick={() => onChangeView('CHANGE_PLAN')}
              >
                <LuArrowLeftRight className="mr-2" />
                {isMobile ? 'Plano' : 'Alterar plano'}
              </Button>
            )}
          </div>
        </div>
      </CustomCardHeader>
      <CustomCardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <PlanFeatures>
              {features.map((feature, index) => (
                <FeatureItem key={index} feature={feature} />
              ))}
            </PlanFeatures>
          </div>
          <div>
            {/* <Value variant="medium">{FORMATTERS.NUMBER(subscription.plan.price)}</Value> */}
          </div>
        </div>
        {/* {!isEmpty(additional_items) && (
          <>
            {additional_items.map((item, index) => (
              <>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <span className="">{item.name}</span>
                    <small className="text-muted">
                      Desde {FORMATTERS.DATE_DDMMYYYYHHMM(item.purchase_date)}
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      type="text"
                      disabled
                      size="sm"
                      className="mr-3 text-center"
                      style={{ maxWidth: '50px' }}
                      value={item.quantity}
                    />
                    <div
                      style={{
                        width: '100px',
                      }}
                      className="d-flex justify-content-end"
                    >
                      <Value variant="medium">{FORMATTERS.NUMBER(item.total)}</Value>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </>
        )} */}
        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <Button
              size="sm"
              variant="link"
              className="d-flex justify-content-between align-items-center m-b p-0"
              onClick={() => onChangeView('ADDITIONAL_USERS')}
            >
              <LuUserPlus2 className="mr-2" /> Adquirir usuários
            </Button>
            &nbsp;|&nbsp;
            <Button
              size="sm"
              variant="link"
              className="d-flex justify-content-between align-items-center m-b p-0"
              onClick={() => onChangeView('INVOICE_HISTORY')}
            >
              <LuReceipt className="mr-2" />
              Histórico de faturas
            </Button>
          </div>
          <div>
            {/* <Value
              variant="large"
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '1.2rem',
              }}
            >
              Total: {FORMATTERS.NUMBER(subscription.total_amount)}
              <small className="ml-1">
                /{CYCLE_MONTHS2[subscription.plan.cycle_months]})
              </small>
            </Value> */}
          </div>
        </div>
      </CustomCardBody>
    </CustomCard>
  );
}

SubscriptionItemsCard.defaultProps = {
  subscription: null,
};

SubscriptionItemsCard.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func.isRequired,
};

export default SubscriptionItemsCard;
