import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import {
  IoArrowDown,
  IoArrowUp,
  IoSwapHorizontalOutline,
} from 'react-icons/io5';
import { IoMdPeople } from 'react-icons/io';
import { HiReceiptTax } from 'react-icons/hi';
import { FaCheck } from 'react-icons/fa';

import { typeSubtypeStringToObject } from 'helpers';
import confirmDialog from 'helpers/confirmDialog';

import useRecipients from '_store/_hooks/useRecipients';
import useCategories from '_store/_hooks/useCategories';

import {
  CategorySelect,
  TypeSelect,
  RecipientSelect,
  TransferSelect,
  StyledButton,
} from './styles';

const type_sub_type_options = [
  {
    label: 'Receita',
    value: 'INCOME::',
    icon: <IoArrowUp className="text-success mr-2" />,
  },
  {
    label: 'Despesa Fixa',
    value: 'EXPENSE::FIXED_EXPENSE',
    icon: <IoArrowDown className="text-danger mr-2" />,
  },
  {
    label: 'Despesa Variável',
    value: 'EXPENSE::VARIABLE_EXPENSE',
    icon: <IoArrowDown className="text-danger mr-2" />,
  },
  {
    label: 'Pessoas',
    value: 'EXPENSE::PEOPLE',
    icon: <IoMdPeople className="text-danger mr-2" />,
  },
  {
    label: 'Impostos',
    value: 'EXPENSE::TAXES',
    icon: <HiReceiptTax className="text-danger mr-2" />,
  },
  {
    label: 'Transferência',
    value: 'TRANSFER::',
    icon: <IoSwapHorizontalOutline className="text-info mr-2" />,
  },
];

function MultipleFieldsTab({
  selectedItems,
  allItems,
  recipients,
  accounts,
  transactions,
  bank_statement_account_id,
  onUpdateMultipleTransactions,
  onReconcileTransactions,
  onUpdateTransactionsTransferDetails,
  showIcon,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const {
    options: recipientsOptions,
  } = useRecipients({
    recipients,
  });

  const {
    onLoadSuggestions: onLoadCategoriesSuggestions,
  } = useCategories({});

  const accountsOptions = useMemo(() => {
    const formatted = accounts.map((account) => ({
      value: account.id,
      label: account.description,
    }));

    return formatted;
  }, [accounts]);

  const handleSubmitForm = useCallback((values, { resetForm }) => {
    const performUpdate = () => {
      setIsLoading(true);

      if (values.type_sub_type) {
        const { type, sub_type } = typeSubtypeStringToObject(values.type_sub_type);

        values.type = type;
        values.sub_type = sub_type;
        values.temp_type = type;
        values.temp_sub_type = sub_type;

        const ids = isEmpty(selectedItems) ? allItems : selectedItems;

        const selectedTransactions = transactions.filter(
          (transaction) => ids.includes(transaction.id),
        );

        const hasIncome = selectedTransactions.some((transaction) => transaction.bank_statement_transaction_type === 'INCOME');
        const hasExpense = selectedTransactions.some((transaction) => transaction.bank_statement_transaction_type === 'EXPENSE');

        if (type === 'INCOME' && hasExpense) {
          confirmDialog.showError({
            title: 'Não permitido',
            message: 'Só é permitido marcar lançamentos como <strong>Receitas</strong> se no Extrato eles tiverem o tipo <strong>Entrada</strong>. <br /> Por favor, verifique e tente novamente.',
            confirmButtonText: 'Entendi',
            showCancelButton: false,
            onConfirm: () => {
              setIsLoading(false);
            },
            onCancel: () => {
              setIsLoading(false);
            },
          });

          return;
        }

        if (type === 'EXPENSE' && hasIncome) {
          confirmDialog.showError({
            title: 'Não permitido',
            message: 'Só é permitido marcar lançamentos como <strong>Despesas</strong> se no Extrato eles tiverem o tipo <strong>Saída</strong>. <br /> Por favor, verifique e tente novamente.',
            confirmButtonText: 'Entendi',
            showCancelButton: false,
            onConfirm: () => {
              setIsLoading(false);
            },
            onCancel: () => {
              setIsLoading(false);
            },
          });

          return;
        }
      }

      delete values.type_sub_type;

      const payload = omitBy(values, isEmpty);

      if (values.type === 'TRANSFER') {
        onUpdateTransactionsTransferDetails({
          ids: isEmpty(selectedItems) ? allItems : selectedItems,
          bank_statement_account_id,
          ...payload,
        }, () => {
          setIsLoading(false);
          resetForm();
          onReconcileTransactions();
        });

        return;
      }

      delete payload.bank_account_id;

      onUpdateMultipleTransactions({
        ids: isEmpty(selectedItems) ? allItems : selectedItems,
        ...payload,
      }, () => {
        setIsLoading(false);
        onReconcileTransactions();
      });
    };

    if (isEmpty(selectedItems)) {
      confirmDialog.warning({
        title: `Alterar campos em ${allItems.length} itens`,
        message: `Você não selecionou nenhum item. <br /> Deseja atualizar todos os ${allItems.length} itens exibidos?`,
        onConfirm: performUpdate,
        onCancel: () => {

        },
      });
    } else {
      performUpdate();
    }
  }, [
    allItems,
    selectedItems,
    transactions,
    bank_statement_account_id,
    onUpdateMultipleTransactions,
    onUpdateTransactionsTransferDetails,
    onReconcileTransactions,
  ]);

  return (
    <div style={{
      paddingLeft: 18,
      paddingBottom: 18,
      paddingRight: 18,
    }}
    >
      <Formik
        initialValues={{
          type_sub_type: null,
          recipient_id: null,
          category_id: null,
        }}
        onSubmit={handleSubmitForm}
        enableReinitialize
      >

        {({ handleSubmit, values, setFieldValue }) => (
          <Form>
            <div
              className="d-flex align-items-center"
              style={{
                flexWrap: 'wrap',
              }}
            >
              {showIcon && <strong style={{ fontWeight: 600, width: '130px' }}>Categorizar</strong> }
              <TypeSelect
                isClearable
                name="type_sub_type"
                options={type_sub_type_options}
                placeholder="Aplicar o tipo..."
                disabled={isEmpty(allItems) && isEmpty(selectedItems)}
                customStyleName="small"
                onChange={(option) => {
                  setFieldValue('type_sub_type', option ? option.value : null);
                }}
              />
              {values.type_sub_type !== 'TRANSFER::' && (
                <>
                  <RecipientSelect
                    isClearable
                    name="recipient_id"
                    options={recipientsOptions}
                    placeholder="Recebido de / Pago a..."
                    creatable="recipient"
                    disabled={(isEmpty(allItems) && isEmpty(selectedItems)) || values.type_sub_type === 'TRANSFER::'}
                    loadingMessage={() => 'Carregando...'}
                    onCreateCallback={(created_recipient) => {
                      if (created_recipient) {
                        setFieldValue('recipient_id', created_recipient.id);
                      }
                    }}
                    customStyleName="small"
                    onChange={(option) => {
                      setFieldValue('recipient_id', option ? option.value : null);

                      if (!option) {
                        return;
                      }

                      const {
                        type,
                        sub_type,
                      } = typeSubtypeStringToObject(values.type_sub_type) || {};

                      const params = {
                        suggestion_for: 'category_id',
                        recipient_id: option.value,
                        type,
                        sub_type,
                      };

                      onLoadCategoriesSuggestions(params, (found) => {
                        const [first] = found || [];

                        if (first && !values.category_id) {
                          setFieldValue('category_id', first.value);
                        }
                      });
                    }}
                  />
                  <CategorySelect
                    isClearable
                    name="category_id"
                    placeholder="Aplicar a categoria..."
                    onChange={(value) => setFieldValue('category_id', value)}
                    value={values.category_id}
                    typeSubType={values.type_sub_type}
                    disabled={isEmpty(allItems) && isEmpty(selectedItems)}
                    prioritizeGroup={values.type_sub_type}
                    smallStyling
                    showNoOptionsCustomItems
                  />
                </>
              )}
              {values.type_sub_type === 'TRANSFER::' && (
                <TransferSelect
                  isClearable
                  name="bank_account_id"
                  options={accountsOptions}
                  placeholder="Conta de Origem/Destino"
                  creatable="bank_account"
                  loadingMessage={() => 'Carregando...'}
                  onCreateCallback={(created) => {
                    if (created) {
                      setFieldValue('bank_account_id', created.id);
                    }
                  }}
                  customStyleName="small"
                />
              )}
              <StyledButton
                variant="success-2"
                onClick={handleSubmit}
                isLoading={isLoading}
                disabled={!values.type_sub_type && !values.category_id && !values.recipient_id}
              >
                <FaCheck />
              </StyledButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

MultipleFieldsTab.defaultProps = {
  recipients: [],
  accounts: [],
  selectedItems: [],
  allItems: [],
  bank_statement_account_id: null,
  showIcon: false,
  transactions: [],
};

MultipleFieldsTab.propTypes = {
  showIcon: PropTypes.bool,
  selectedItems: PropTypes.array,
  transactions: PropTypes.array,
  allItems: PropTypes.array,
  recipients: PropTypes.array,
  accounts: PropTypes.array,
  bank_statement_account_id: PropTypes.string,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
  onReconcileTransactions: PropTypes.func.isRequired,
  onUpdateTransactionsTransferDetails: PropTypes.func.isRequired,
};

export default MultipleFieldsTab;
