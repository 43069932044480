import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';

const fetchMyCompanyInvitations = () => {
  return dispatch => {
    dispatch(request());

    service
      .fetchMyCompanyInvitations()
      .then(response => {
        dispatch(success(response.data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: constants.FETCH_MY_COMPANY_INVITATIONS_REQUEST, payload: {} };
  }
  function success(data) {
    return {
      type: constants.FETCH_MY_COMPANY_INVITATIONS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_MY_COMPANY_INVITATIONS_FAILURE,
      payload: error,
    };
  }
};

export default fetchMyCompanyInvitations;
