import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '_components/_core';

import UpdateEmailModal from './components/UpdateEmailModal';

function UpdateEmail({ onUpdateEmailRequest, onUpdateEmailConfirm, onLogout, isMobile }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <UpdateEmailModal
        isVisible={isModalOpen}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onUpdateEmailRequest={onUpdateEmailRequest}
        onUpdateEmailConfirm={onUpdateEmailConfirm}
        onLogout={onLogout}
      />
      <div>
        <Button
          className={classNames({
            'p-0 m-0 mt-2 ml-3': !isMobile,
            'p-0 m-0': isMobile,
          })}
          variant="link"
          onClick={() => setIsModalOpen(true)}
        >
          Precisa alterar seu e-mail? Clique aqui.
        </Button>
      </div>
    </>
  );
}

UpdateEmail.propTypes = {
  isMobile: PropTypes.bool,
  onUpdateEmailRequest: PropTypes.func.isRequired,
  onUpdateEmailConfirm: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

UpdateEmail.defaultProps = {
  isMobile: false,
};

export default UpdateEmail;
