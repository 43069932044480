import { DateTime } from 'luxon';
import * as XLSX from 'sheetjs-style';

import FORMATTERS from 'helpers/formatters';

const typeColors = {
  income_text: '000000',
  income_background: 'ffffff',
  income_value: '007A47',
  expense_text: '000000',
  expense_background: 'ffffff',
  expense_value: 'B71C1C',
  transfer_text: '000000',
  transfer_background: 'ffffff',
  transfer_value: 'bacdf5',
  transfer_received: '007A47',
  transfer_sent: 'B71C1C',
  header: 'DBDCDD',
};

const cellTypes = {
  INCOME: {
    font: { color: { rgb: typeColors.income_text } },
  },
  EXPENSE: {
    font: { color: { rgb: typeColors.expense_text } },
  },
  TRANSFER_RECEIVED: {
    font: { bold: true, color: { rgb: typeColors.transfer_received } },
  },
  TRANSFER_SENT: {
    font: { bold: true, color: { rgb: typeColors.transfer_sent } },
  },
  HEADERS: {
    fill: { fgColor: { rgb: typeColors.header } },
    font: { bold: true },
    border: {
      bottom: { style: 'thin', color: { auto: 1 } },
      top: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
    },
  },
  VALUE_INCOME: {
    font: { bold: true, color: { rgb: typeColors.income_value } },
  },
  VALUE_EXPENSE: {
    font: { bold: true, color: { rgb: typeColors.expense_value } },
  },
  VALUE_TRANSFER: {
    font: { bold: true, color: { rgb: typeColors.transfer_value } },
  },
};

const exportToExcel = ({ searchResults, accounts }) => {
  const results = searchResults.transactions;

  const spreadsheetData = [
    [
      'Pago?',
      'Data',
      'Tipo',
      'Descrição',
      'Recebido de / Pago a',
      'Categoria',
      'Conta Bancária',
      'Valor',
    ],
  ];

  const transactionsType = [null, 'HEADERS'];

  for (const transaction of results) {
    const isExpense = transaction.type === 'EXPENSE' || transaction.sub_type === 'SENT';
    const amount = isExpense ? transaction.amount * -1 : transaction.amount;

    const bank_account = transaction.account?.description || '-';

    const {
      recipient = {},
    } = transaction;

    let recipient_name = '-';

    if (transaction.type !== 'TRANSFER') {
      recipient_name = recipient.name || '-';
    }

    if (transaction.type === 'TRANSFER' && transaction.transfer_details) {
      recipient_name = FORMATTERS.REPORT_CASH_FLOW_TRANSFER(transaction, accounts, false);
    }

    spreadsheetData.push([
      transaction.paid ? 'Sim' : 'Não',
      transaction.event_date
        ? DateTime.fromISO(transaction.event_date, {
            zone: 'utc',
          })
            .set({ hour: 12 })
            .setLocale('pt-BR')
            .toJSDate()
        : '-',
      FORMATTERS.TRANSACTION_TYPE_STRING_SEARCH_PAGE(transaction.type, transaction.sub_type),
      transaction.description || '-',
      recipient_name,
      FORMATTERS.REPORT_CATEGORY_NAME(transaction, '-'),
      bank_account,
      amount,
    ]);

    transactionsType.push(transaction.type);
  }

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

  ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'].forEach(col => {
    worksheet[`${col}1`].s = { ...cellTypes.HEADERS };
  });

  worksheet['!cols'] = [
    { width: 7 },
    { width: 12 },
    { width: 24 },
    { width: 35 },
    { width: 24 },
    { width: 24 },
    { width: 16 },
    { width: 12 },
  ];

  for (let i = 1; i <= spreadsheetData.length; i += 1) {
    if (i !== 1) {
      const style = cellTypes[transactionsType[i]] || {};
      ['A', 'B', 'C', 'D', 'E', 'F', 'G'].forEach(col => {
        worksheet[`${col}${i}`].s = { ...style };
      });

      const amount = spreadsheetData[i - 1][7];
      worksheet[`H${i}`].s =
        amount > 0
          ? { ...cellTypes.VALUE_INCOME }
          : { ...cellTypes.VALUE_EXPENSE };
      worksheet[`H${i}`].t = 'n';
      worksheet[`H${i}`].z = '#,##0.00';
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Resultado_da_Pesquisa');

  XLSX.writeFile(workbook, 'Resultado_da_Pesquisa.xlsx', {
    type: 'array',
    bookType: 'xlsx',
  });
};

export default exportToExcel;
