import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const confirmCompanySeatsPurchase = (params, callback, errorCallback) => {
  return dispatch => {
    dispatch(request());

    service
      .confirmCompanySeatsPurchase(params)
      .then(response => {
        const { data } = response;

        dispatch(success(data));

        if (callback) {
          callback(data);
        }
      })
      .catch(error => {
        dispatch(failure(error));

        if (errorCallback) {
          errorCallback(error);
        }
      });
  };

  function request() {
    return {
      type: constants.CONFIRM_COMPANY_SEATS_PURCHASE_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.CONFIRM_COMPANY_SEATS_PURCHASE_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CONFIRM_COMPANY_SEATS_PURCHASE_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default confirmCompanySeatsPurchase;
