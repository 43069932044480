import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { LuBuilding2, LuUsers2 } from 'react-icons/lu';

import { CustomCard, Label, Value } from '../../../../../styles';

function SubscriptionUsageCards({ subscription }) {
  const { type, companies } = subscription;

  if (!subscription) {
    return null;
  }

  return (
    <>
      <Row className="mt-3">
        <Col sm={12}>
          <CustomCard className="p-3 d-flex justify-content-center align-items-start">
            <div className="d-flex justify-content-center align-items-center">
              <LuUsers2 size={32} className="mr-2 text-muted" />
              <div className="ml-1">
                <Label>Usuários</Label>
                <Value>
                  {`${subscription.users.used} de ${subscription.users.max}`} utilizados
                </Value>
              </div>
            </div>
          </CustomCard>
        </Col>
        {type === 'MANAGER' && companies.max > 1 && (
          <Col className="mt-3" sm={12}>
            <CustomCard className="p-3 d-flex justify-content-center align-items-start">
              <div className="d-flex justify-content-center align-items-center">
                <LuBuilding2 size={32} className="mr-2 text-muted" />
                <div className="ml-1">
                  <Label>Empresas</Label>
                  <Value>
                    {`${subscription.companies.used} de ${subscription.companies.max}`}{' '}
                    utilizadas
                  </Value>
                </div>
              </div>
            </CustomCard>
          </Col>
        )}
      </Row>
    </>
  );
}

SubscriptionUsageCards.defaultProps = {
  subscription: null,
};

SubscriptionUsageCards.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func.isRequired,
};

export default SubscriptionUsageCards;
