import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import {
  FormTextField,
  FormPasswordField,
  FormSelectField,
  FormSwitchField,
} from '_components/_core';

import { ZENPLY_TERMS_URL, ZENPLY_PRIVACY_POLICY_URL } from 'helpers';

import FORMATTERS from 'helpers/formatters';

import { zenpaperSignUpFormSchema } from '_components/SignUp/components/Form/utilities/validationSchema';
import { AddressFormFields } from '_components/_shared';

import { StyledNavButton, StyledSmallText, StyledLink } from './styles';

function SignUnForm({ onSignUp, segments, step, onChangeStep }) {
  const history = useHistory();

  const [isSigningUp, setIsSigningUp] = useState(false);

  const formattedSegments = useMemo(
    () =>
      segments.map(segment => ({
        value: segment.id,
        label: segment.description,
      })),
    [segments],
  );
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const utmSource = query.get('utm_source');
  const utmMedium = query.get('utm_medium');
  const utmCampaign = query.get('utm_campaign');
  const utmTerm = query.get('utm_term');
  const utmContent = query.get('utm_content');
  const utmId = query.get('utm_id');

  const handleCreateAccount = useCallback(
    values => {
      setIsSigningUp(true);

      const user = {
        ...values,
        document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
        product: 'contabills-empresas',
        utmSource,
        utmCampaign,
        utmMedium,
        utmTerm,
        utmContent,
        utmId,
      };

      const successCallback = () => {
        setIsSigningUp(false);

        history.push(`/confirmar-email?email=${encodeURIComponent(values.email)}`);
      };
      const failureCallback = () => {
        setIsSigningUp(false);
      };

      onSignUp(user, successCallback, failureCallback);
    },
    [onSignUp, history, utmSource, utmCampaign, utmMedium, utmTerm, utmContent, utmId],
  );

  const isStepValid = useCallback((stepNumber, values) => {
    if (stepNumber === 1) {
      return (
        values.first_name &&
        values.last_name &&
        values.email &&
        values.email_confirmation &&
        values.password &&
        values.password_confirmation
      );
    }

    if (stepNumber === 2) {
      return (
        values.company_name &&
        values.address_city &&
        values.address_state &&
        values.address_city_ibge &&
        values.address_state_ibge &&
        values.accepted_terms &&
        values.market_segment_id
      );
    }

    if (stepNumber === 3) {
      return values.accepted_terms;
    }

    return false;
  }, []);

  const handleChangeStep = useCallback(
    (e, values) => {
      e.preventDefault();

      if (step === 2) {
        handleCreateAccount(values);

        return;
      }

      onChangeStep(step + 1);
    },
    [step, handleCreateAccount, onChangeStep],
  );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: '',
          last_name: '',
          document_type: 'PF',
          document_number: '00000000000',
          email: '',
          email_confirmation: '',
          password: '',
          password_confirmation: '',
          market_segment_id: null,
          accepted_terms: false,
        }}
        validationSchema={zenpaperSignUpFormSchema}
        onSubmit={handleCreateAccount}
      >
        {({ values }) => (
          <>
            <div>
              {step === 1 && (
                <>
                  <Form.Row className="mt-4">
                    <Form.Group as={Col} xs={6} lg={6}>
                      <Form.Label>Primeiro nome</Form.Label>
                      <FormTextField name="first_name" placeholder="João" />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={6}>
                      <Form.Label>Sobrenome</Form.Label>
                      <FormTextField name="last_name" placeholder="Silva" />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} lg={12}>
                      <Form.Label>E-mail</Form.Label>
                      <FormTextField
                        name="email"
                        placeholder="nome@email.com.br"
                        type="email"
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} lg={12}>
                      <Form.Label>Repetir e-mail</Form.Label>
                      <FormTextField
                        name="email_confirmation"
                        placeholder="nome@email.com.br"
                        type="email"
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} lg={12}>
                      <Form.Label>Senha</Form.Label>
                      <FormPasswordField
                        name="password"
                        autoComplete="off"
                        type="password"
                        placeholder="Criar uma senha"
                      />
                    </Form.Group>
                    <Form.Group as={Col} lg={12}>
                      <Form.Label>Repetir a senha</Form.Label>
                      <FormPasswordField
                        name="password_confirmation"
                        autoComplete="off"
                        type="password"
                        placeholder="Confirme a senha"
                      />
                    </Form.Group>
                  </Form.Row>
                </>
              )}
              {step === 2 && (
                <>
                  <Form.Row>
                    <Form.Group as={Col} xs={12} lg={12}>
                      <Form.Label>Nome da empresa / negócio</Form.Label>
                      <FormTextField
                        name="company_name"
                        placeholder="Ex: Restaurante da Tia Ju"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Ramo de atuação</Form.Label>
                      <FormSelectField
                        name="market_segment_id"
                        placeholder="Selecione..."
                        options={formattedSegments}
                      />
                    </Form.Group>
                  </Form.Row>
                  <AddressFormFields variant="STATE_AND_CITY" />
                  <Form.Row className="mb-4">
                    <Col>
                      <FormSwitchField
                        name="accepted_terms"
                        sideContent={
                          <StyledSmallText>
                            {'Li e aceito os '}
                            <StyledLink
                              href={ZENPLY_TERMS_URL}
                              target="_blank"
                              tabIndex={-1}
                              rel="noreferrer"
                            >
                              Termos de Serviço
                            </StyledLink>
                            {' e as '}
                            <StyledLink
                              href={ZENPLY_PRIVACY_POLICY_URL}
                              target="_blank"
                              tabIndex={-1}
                              rel="noreferrer"
                            >
                              Políticas de Privacidade
                            </StyledLink>
                          </StyledSmallText>
                        }
                      />
                    </Col>
                  </Form.Row>
                </>
              )}
            </div>
            <Row className="justify-content-center mt-2">
              <Col>
                <div className="d-flex align-items-center justify-content-center w-100">
                  {step > 1 && (
                    <StyledNavButton
                      variant="inverse-dark"
                      type="button"
                      className="d-flex align-items-center justify-content-center w-100"
                      onClick={() => onChangeStep(step - 1)}
                      disabled={isSigningUp}
                    >
                      <IoArrowBack className="mr-2" />
                      Voltar
                    </StyledNavButton>
                  )}
                  <StyledNavButton
                    type="button"
                    className={classNames({
                      'd-flex align-items-center justify-content-center': true,
                      'w-100': step === 1 || step === 2,
                      'ml-3': step === 2,
                      main: true,
                    })}
                    onClick={e => handleChangeStep(e, values)}
                    disabled={!isStepValid(step, values) || isSigningUp}
                    isLoading={isSigningUp}
                    variant="dark"
                  >
                    Avançar
                    <IoArrowForward className="ml-2" />
                  </StyledNavButton>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </>
  );
}

SignUnForm.defaultProps = {
  segments: [],
};

SignUnForm.propTypes = {
  step: PropTypes.number.isRequired,
  segments: PropTypes.array,
  onSignUp: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func.isRequired,
};

export default SignUnForm;
