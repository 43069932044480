import React from 'react';
import PropTypes from 'prop-types';
import { LuAlertCircle } from 'react-icons/lu';
import ReactTooltip from 'react-tooltip';

import { sanitizePlanName } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { SubscriptionStatusTag } from '_components/_shared';

import {
  CustomCard,
  CustomCardBody,
  CustomCardFooter,
  Label,
  Value,
} from '../../../../../styles';

const CYCLE = {
  1: 'mensal',
  6: 'semestral',
  12: 'anual',
};

function SubscriptionAmountCard({ subscription }) {
  if (!subscription) {
    return null;
  }

  return (
    <CustomCard>
      <ReactTooltip effect="solid" />
      <CustomCardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Label>Plano {sanitizePlanName(subscription.plan.name)}</Label>
            <Value variant="large" className="mt-2">
              {FORMATTERS.NUMBER(subscription.total_amount)}&nbsp;
              <small>{CYCLE[subscription.cycle_months]}</small>
            </Value>
          </div>
          <div>
            <SubscriptionStatusTag status={subscription.status} />
          </div>
        </div>
      </CustomCardBody>
      {subscription.status === 'incomplete' && (
        <CustomCardFooter>
          <small className="text-muted">Aguardando pagamento</small>
          <LuAlertCircle
            style={{
              cursor: 'pointer',
            }}
            data-place="bottom"
            data-tip="Conclua o pagamento da fatura em aberto para que sua assinatura seja efetivada."
            className="text-info"
          />
        </CustomCardFooter>
      )}
    </CustomCard>
  );
}

SubscriptionAmountCard.defaultProps = {
  subscription: null,
};

SubscriptionAmountCard.propTypes = {
  subscription: PropTypes.object,
};

export default SubscriptionAmountCard;
