import { connect } from 'react-redux';
import { compose } from 'redux';

import userActionCreators from '_store/_actions/user';

import Permissions from './Permissions';

const mapStateToProps = (state) => ({
  isLoading: state.fiscal.isLoading,
  activeCompany: state.company.activeCompany,
  allUserPermissions: state.userPermissions.permissions,
  isLoadingPermissions: state.userPermissions.isLoading,
});

const mapDispatchToProps = {
  onFetchUsers: userActionCreators.fetchUsersForSelect,
  onFetchUserPermissions: userActionCreators.fetchUsersPermissions,
  onUpdateUserPermissions: userActionCreators.updateUserPermissions,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Permissions);
