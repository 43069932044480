import { connect } from 'react-redux';

import paymentsActionsCreators from '_store/_actions/payments';
import subscriptionActionCreators from '_store/_actions/subscription';

import InvoiceHistory from './InvoiceHistory';

const mapStateToProps = state => ({
  payments: state.payments.payments,
  activeCompany: state.company.activeCompany,
  subscriptionInvoices: state.subscription.subscriptionInvoices,
  isLoading: state.subscription.isLoadingInvoices,
});

const mapDispatchToProps = {
  onFetchPayments: paymentsActionsCreators.fetchPayments,
  onFetchSubscriptionInvoices: subscriptionActionCreators.fetchSubscriptionInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceHistory);
