import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useContextMenu } from 'react-contexify';
import { IoLogOutOutline } from 'react-icons/io5';
import { LuUser, LuBell, LuBuilding2 } from 'react-icons/lu';

import { ContextMenu } from '_components/_core';
import { LoadingIcon } from '_components/_shared';

import {
  Trigger,
  Footer,
  AvatarWrapper,
  StyledAvatar,
  StyledUserName,
  StyledUserEmail,
  MenuItem,
  LogOutButton,
} from './styles';

function AvatarMenuV2({ isLoading, user, onFetchUser, onLogout }) {
  const history = useHistory();

  const triggerRef = useRef(null);

  const handleRedirect = useCallback(
    path => {
      history.push(path);
    },
    [history],
  );

  useEffect(() => {
    onFetchUser();
  }, [onFetchUser]);

  const MENU_ID = 'avatar-menu';

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const handleMenuOpen = useCallback(
    e => {
      const buttonPosition = triggerRef.current.getBoundingClientRect();

      show(e, {
        position: {
          x: buttonPosition.left,
          y: buttonPosition.bottom + 5,
        },
      });
    },
    [show],
  );

  return (
    <>
      {isLoading && (
        <div>
          <LoadingIcon text="" />
        </div>
      )}
      {!isLoading && (
        <Trigger className="ml-2 mr-2" ref={triggerRef} onClick={handleMenuOpen}>
          <div className="d-flex justify-content-center align-items-center">
            <AvatarWrapper>
              <StyledAvatar
                src={
                  user && user.avatar_url
                    ? user.avatar_url
                    : 'assets/img/default-avatar.svg'
                }
                alt="user-avatar"
              />
            </AvatarWrapper>
            <div>
              <StyledUserName>{user.full_name}</StyledUserName>
              <StyledUserEmail>{user.email}</StyledUserEmail>
            </div>
          </div>
        </Trigger>
      )}
      <ContextMenu
        onClick={e => {
          e.preventDefault();
        }}
        theme="avatar-menu"
        menuId={MENU_ID}
      >
        <MenuItem onClick={() => handleRedirect('/configuracoes/dados-pessoais')}>
          <LuUser size={14} className="mr-2" />
          Meu perfil
        </MenuItem>
        <MenuItem onClick={() => handleRedirect('/configuracoes/preferencias')}>
          <LuBell size={14} className="mr-2" />
          Preferências
        </MenuItem>
        <MenuItem onClick={() => handleRedirect('/configuracoes/empresas')}>
          <LuBuilding2 size={14} className="mr-2" />
          Minhas empresas
        </MenuItem>
        <Footer>
          <LogOutButton variant="link text-muted" onClick={onLogout}>
            <IoLogOutOutline size="1.3em" className="text-muted mr-2" />
            Sair do sistema
          </LogOutButton>
        </Footer>
      </ContextMenu>
    </>
  );
}

AvatarMenuV2.propTypes = {
  isLoading: false,
  user: null,
};

AvatarMenuV2.propTypes = {
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  onFetchUser: PropTypes.func,
  onLogout: PropTypes.func.isRequired,
};

export default AvatarMenuV2;
