import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';

import alertActionCreators from '../alert.actions';

const acceptCompanyInvitation = (params, successCallback, errorCallback) => {
  return async dispatch => {
    dispatch(request(params));

    service
      .acceptInvitation(params)
      .then(response => {
        dispatch(success(response));

        if (successCallback) {
          successCallback(response.data);
        }

        dispatch(
          alertActionCreators.success(
            'Convite aceito com sucesso! Você já pode acessar a empresa.',
          ),
        );
      })
      .catch(error => {
        dispatch(failure(error));

        if (errorCallback) {
          errorCallback(error);
        }
      });
  };

  function request(params) {
    return {
      type: constants.ACCEPT_COMPANY_INVITATION_REQUEST,
      payload: params,
    };
  }
  function success(response) {
    return {
      type: constants.ACCEPT_COMPANY_INVITATION_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.ACCEPT_COMPANY_INVITATION_FAILURE,
      payload: { error },
    };
  }
};

export default acceptCompanyInvitation;
