import styled from 'styled-components';

export const EditIcon = styled.img`
  display: inline-flex;
  width: 100px;
  border-radius: 50%;
  margin-right: -100px;
  opacity: 0;

  &:hover {
    opacity: 0.6;
  }
`;

export const AvatarWrapper = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
`;

export const StyledAvatar = styled.img`
  width: 100px;
  max-height: 100px;
`;

export const StyledInput = styled.input.attrs({
  type: 'file',
})`
 display: none;
`;

export const StyledLabel = styled.label`
  cursor: pointer;
`;

export const StyledDeleteButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
