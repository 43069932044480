import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Badge, Container } from 'react-bootstrap';
import { LuChevronRight, LuPlus } from 'react-icons/lu';
import { IoLogOutOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import FORMATTERS from 'helpers/formatters';
import { sanitizePlanName } from 'helpers';
import { WEBSOCKET_NAMES, WEBSOCKET_EVENTS } from 'helpers/constants';
import WebSocketManager from 'helpers/WebSocketManager';
import { Button } from '_components/_core';
import { CompanyFormModal, LoadingIcon } from '_components/_shared';

import {
  CustomCard,
  CustomCardBody,
} from '_components/Settings/components/SettingsV2/styles';

import {
  AddCompanyIcon,
  AddCompanyListItem,
  CompanyAvatar,
  CompanyListItem,
  Dot,
  PlanName,
  SmallHeader,
  SubscriptionInfo,
} from './styles';

function ChooseCompany({
  isLoading,
  companies,
  invitations,
  onFetchCompanies,
  onActivateCompany,
  onLogout,
  onFetchInvitations,
  onAcceptInvitation,
  onDeclineInvitation,
  user,
}) {
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    onFetchCompanies();
    onFetchInvitations();
  }, [onFetchCompanies, onFetchInvitations]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const { id } = user;

    const wsManager = new WebSocketManager(WEBSOCKET_NAMES.USER_EVENTS);

    wsManager.connect();

    const params = {
      user_id: id,
    };

    wsManager.joinRoom(params);

    wsManager.subscribe(WEBSOCKET_EVENTS.USER_EVENTS.USER_INVITATION_CREATED, () => {
      onFetchInvitations();
    });

    return () => {
      wsManager.disconnect();
    };
  }, [onFetchInvitations, user]);

  const handleActivateCompany = useCallback(
    company => {
      onActivateCompany(company, () => {
        history.push('/dashboard');
      });
    },
    [onActivateCompany, history],
  );

  const renderAvatar = useCallback((company, size = 'normal') => {
    if (!company) {
      return null;
    }

    if (company.company_avatar_url) {
      return <CompanyAvatar src={company.company_avatar_url} size={size} />;
    }

    return (
      <div className="avatar">{`${company.trading_name.charAt(0).toUpperCase()}`}</div>
    );
  }, []);

  const myCompanies = useMemo(() => {
    return companies.filter(c => c.is_manager);
  }, [companies]);

  const guestCompanies = useMemo(() => {
    return companies.filter(c => !c.is_manager);
  }, [companies]);

  const renderList = useCallback(
    (companies, withMarginTop = false) => {
      if (isEmpty(companies)) {
        return <small className="text-muted">Nenhuma empresa encontrada</small>;
      }

      return (
        <div className={withMarginTop ? 'mt-1' : 'mt-0'}>
          {companies.map(company => (
            <CompanyListItem
              key={company.id}
              className="p-2 d-flex align-items-center"
              onClick={() => handleActivateCompany(company)}
            >
              {renderAvatar(company)}
              <div
                className="ml-2 d-flex flex-column align-items-start justify-content-center"
                style={{ flex: 1 }}
              >
                <strong className="d-flex justify-content-center align-items-center">
                  {company.trading_name.toLowerCase()}
                </strong>
                <SubscriptionInfo>
                  <PlanName>{FORMATTERS.CPF_CNPJ(company.document_number)}</PlanName>
                  <Dot>•</Dot>
                  <PlanName>{sanitizePlanName(company.subscription_plan_name)}</PlanName>
                </SubscriptionInfo>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Badge
                  variant="default-new"
                  className={classNames({
                    'mr-1': true,
                    'text-warning': company.is_manager,
                    'text-info': !company.is_manager,
                  })}
                >
                  {company.is_manager ? 'Administrador' : 'Convidado'}
                </Badge>
                <LuChevronRight size={16} className="text-muted" />
              </div>
            </CompanyListItem>
          ))}
        </div>
      );
    },
    [handleActivateCompany, renderAvatar],
  );

  const handleAcceptInvitation = useCallback(
    invitation => {
      const params = {
        token: invitation.token,
      };

      const successCallback = () => {
        onFetchCompanies();
        onFetchInvitations();
      };

      const errorCallback = () => {};

      onAcceptInvitation(params, successCallback, errorCallback);
    },
    [onAcceptInvitation, onFetchInvitations, onFetchCompanies],
  );

  const handleDeclineInvitation = useCallback(
    invitation => {
      const successCallback = () => {
        onFetchInvitations();
      };

      const errorCallback = () => {};

      onDeclineInvitation(invitation.id, successCallback, errorCallback);
    },
    [onDeclineInvitation, onFetchInvitations],
  );

  const renderInvitations = useCallback(() => {
    if (isEmpty(invitations)) {
      return <small className="text-muted">Nenhum convite encontrado</small>;
    }

    return (
      <div className="mt-1">
        {invitations.map(invitation => (
          <CompanyListItem key={invitation.id} className="p-2 d-flex align-items-center">
            {renderAvatar(invitation)}
            <div
              className="ml-2 d-flex flex-column align-items-start justify-content-center"
              style={{ flex: 1 }}
            >
              <strong className="d-flex justify-content-center align-items-center">
                {invitation.trading_name.toLowerCase()}
              </strong>
              <SubscriptionInfo>
                <PlanName>
                  Expira{' '}
                  {DateTime.fromISO(invitation.expire_at).setLocale('pt-BR').toRelative()}
                </PlanName>
              </SubscriptionInfo>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <Button
                onClick={() => handleAcceptInvitation(invitation)}
                variant="success-2"
                size="sm"
                isLoading={isLoading}
              >
                Aceitar
              </Button>
              <Button
                onClick={() => handleDeclineInvitation(invitation)}
                variant="inverse-danger"
                size="sm"
                className="ml-2"
                isLoading={isLoading}
              >
                Recusar
              </Button>
            </div>
          </CompanyListItem>
        ))}
        <hr />
      </div>
    );
  }, [
    invitations,
    isLoading,
    handleAcceptInvitation,
    handleDeclineInvitation,
    renderAvatar,
  ]);

  return (
    <Container className="h-100 d-flex justify-content-center align-items-center">
      <CompanyFormModal
        isVisible={isCompanyModalOpen}
        company_id={null}
        selectedCompany={null}
        onToggleForm={() => setIsCompanyModalOpen(!isCompanyModalOpen)}
        onAfterSaveCallbackWithReset={() => {
          setIsCompanyModalOpen(false);

          onFetchCompanies();
        }}
      />
      <div className="w-100">
        <div className="d-flex justify-content-center align-items-center">
          <img
            src="/assets/img/zenply-logo-m.png"
            alt="Logo"
            width="145"
            className="mb-4"
          />
        </div>
        <CustomCard>
          <CustomCardBody>
            <h4 className="mt-1">Selecionar empresa</h4>
            <p className="text-muted">
              Clique na empresa que deseja acessar ou adicione uma nova.
            </p>
            <hr />
            {isLoading && <LoadingIcon />}
            {!isEmpty(invitations) && (
              <>
                <SmallHeader>Convites</SmallHeader>
                {renderInvitations()}
              </>
            )}

            <SmallHeader>Empresas</SmallHeader>
            {!isEmpty(myCompanies) && <>{renderList(myCompanies, true)}</>}
            {!isEmpty(guestCompanies) && <>{renderList(guestCompanies)}</>}
            <hr />
            <AddCompanyListItem
              className="p-2 d-flex align-items-center mt-1"
              onClick={() => setIsCompanyModalOpen(true)}
            >
              <AddCompanyIcon>
                <LuPlus />
              </AddCompanyIcon>
              <div
                className="ml-2 d-flex flex-column align-items-start justify-content-center"
                style={{ flex: 1 }}
              >
                <strong className="d-flex justify-content-start align-items-center w-100">
                  Criar outra empresa
                </strong>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <LuChevronRight size={16} className="text-muted" />
              </div>
            </AddCompanyListItem>
          </CustomCardBody>
        </CustomCard>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            variant="link"
            className="m-0 p-0 mt-3 d-flex justify-content-center align-items-center text-muted"
            onClick={onLogout}
          >
            <IoLogOutOutline size="1.3em" className="mr-2" />
            Sair do sistema
          </Button>
        </div>
      </div>
    </Container>
  );
}

ChooseCompany.defaultProps = {
  isLoading: false,
  companies: [],
  invitations: [],
};

ChooseCompany.propTypes = {
  isLoading: PropTypes.bool,
  companies: PropTypes.array,
  invitations: PropTypes.array,
  onFetchCompanies: PropTypes.func.isRequired,
  onActivateCompany: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onFetchInvitations: PropTypes.func.isRequired,
  onAcceptInvitation: PropTypes.func.isRequired,
  onDeclineInvitation: PropTypes.func.isRequired,
};

export default ChooseCompany;
