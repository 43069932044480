import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const refreshPaymentData = (params, callback, errorCallback) => {
  return dispatch => {
    dispatch(request());

    service
      .refreshPaymentData(params)
      .then(response => {
        const { data } = response;

        dispatch(success({ data }));

        if (callback) {
          callback(data);
        }
      })
      .catch(error => {
        if (errorCallback) {
          errorCallback(error);
        }

        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: constants.REFRESH_PAYMENT_DATA_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.REFRESH_PAYMENT_DATA_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.REFRESH_PAYMENT_DATA_FAILURE,
      payload: { error },
    };
  }
};

export default refreshPaymentData;
