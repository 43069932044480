import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';

import Button from '_components/_core/Button';

export const CustomFilterButton = styled(Button)`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 500;
  color: #0D0E10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 !important;
  margin-right: 8px;
  background-color: #F9FBFC;
  border: 1px solid #E8E7EA;
  border-radius: 8px;

  :hover, :focus {
    border: 1px solid #E8E7EA;
    text-decoration: none;
    color: #0D0E10;
  }

  &&& {
    .dropleft .dropdown-toggle::before {
      display: none;
    }

    &::before {
      display: none !important;
    }
  }

  &::after {
    content: none !important;
  }
`;

export const CustomButton = styled.li`
  padding: 16px;
  width: 100%;
  color: var(--title-color);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none !important;

  &::after {
    content: none !important;
  }

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  span {
    opacity: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.4rem;
    width: 100%;
    position: relative;
    text-decoration: none !important;
    color: var(--title-color);

    div {
      display: flex;
      margin-right: 16px;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    p {
      max-width: 500px;
      will-change: max-width, opacity;
      overflow: hidden;
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      padding: 0;
      text-decoration: none !important;
    }
  }
`;

export const FilterMenu = styled(Dropdown.Menu)`
  width: auto;
  min-width: 900px;
  padding: 0;
  z-index: 990;
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  overflow-y: auto;
  overflow-x: hidden;

  ${({ variant }) =>
    variant === 'navbar' &&
    css`
    top: 40px !important;
    right: 0 !important;
    left: auto !important;
  `};  

  ${({ isMobile }) =>
    isMobile &&
    css`
    min-width: unset;
    margin: 0px;
    width: 100vw;
    left: 0 !important;
    right: 0 !important;
    top: 65px !important;
    position: fixed;
    z-index: 999999 !important;
  `}
`;

export const FilterDescription = styled.div`
  color: #393A3D;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 1px;
  border-bottom: 1px solid #E8E7EA;
  padding: 16px 16px;
`;

export const Footer = styled.div`
  color: #393A3D;
  font-weight: 500;
  border-top: 1px solid #E8E7EA;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px;

  svg {
    padding: 0;
    margin: 0;
  }

`;

export const InputContainer = styled.div`
  padding: 16px 16px;
  color: #393A3D;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 400;
    color: #393A3D;

    li {
      padding: 4px 0;

      &.title {
        color: #393A3D;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: 1px;
        margin-bottom: 4px;
      }

      a {
        color: #192331fc;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
    padding-top: 0;
    /* padding: 0;
    height: calc(100vh - 66px - 45px - 60px);
    overflow-x: hidden;
    overflow-y: auto; */


    ul {
      li {

        &.title:first-child {
          padding-top: 8px;
        }

        &.title {       
          padding-top: 16px;
          font-size: 14px;
          line-height: 1.5;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
  
  `}
`;

export const StyledDropdown = styled(Dropdown)`
  /* .dropdown-menu {
    position: fixed;
    top: 48px;
    right: 1px;
    left: auto;
    width: auto;
    max-width: 500px;
    box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
    overflow-y: auto;
    overflow-x: hidden;
  } */
`;

export const StyledWrapper = styled.div`
  border-radius: 8px;
  width: auto;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;

export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%; 
`;

export const StyledAvatar = styled.img`
  width: 32px;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

export const StyledUserName = styled.h1`
  font-size: 13px;
  font-weight: 600;
  padding-left: 1rem;
  margin-bottom: 0.1rem;
`;

export const StyledUserRole = styled.h2`
  font-size: 12px;
  font-weight: 400;
  padding-left: 1rem;
  margin-bottom: 0;
`;
