import { subscriptionConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  plans: [],
  subscription: null,
  companySubscription: null,
  isLoadingSubscription: false,
  subscriptionPlans: [],
  isLoadingPlans: false,
  isLoadingInvoices: false,
  userSeatsPricing: {},
  companySeatsPricing: {},
  subscriptionInvoices: {
    data: [],
  },
  paymentAlert: null,
};

export function subscription(state = INITIAL_STATE, action) {
  switch (action.type) {
    case subscriptionConstants.FETCH_SUBSCRIPTION_PLANS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_PLANS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        plans: data,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_PLANS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_STATUS_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_STATUS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        subscription: data,
        start: data.current_period_start,
        end: data.current_period_end,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_STATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.CREATE_AUTHORIZED_CHECKOUT_SESSION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.CREATE_AUTHORIZED_CHECKOUT_SESSION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.CREATE_AUTHORIZED_CHECKOUT_SESSION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.UPDATE_PLAN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.UPDATE_PLAN_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        subscription: data,
        isLoading: false,
      };
    }
    case subscriptionConstants.UPDATE_PLAN_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.CREATE_CHECKOUT_SESSION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.CREATE_CHECKOUT_SESSION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.CREATE_CHECKOUT_SESSION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        isLoadingSubscription: true,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SUBSCRIPTION_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        companySubscription: data,
        isLoadingSubscription: false,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isLoadingSubscription: false,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SUBSCRIPTION_PLANS_REQUEST: {
      return {
        ...state,
        isLoadingPlans: true,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SUBSCRIPTION_PLANS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        subscriptionPlans: data,
        isLoadingPlans: false,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SUBSCRIPTION_PLANS_FAILURE: {
      return {
        ...state,
        isLoadingPlans: false,
      };
    }
    case subscriptionConstants.CANCEL_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.CANCEL_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.CANCEL_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SUBSCRIPTION_INVOICES_REQUEST: {
      return {
        ...state,
        isLoadingInvoices: true,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SUBSCRIPTION_INVOICES_SUCCESS: {
      return {
        ...state,
        isLoadingInvoices: false,
        subscriptionInvoices: action.payload,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SUBSCRIPTION_INVOICES_FAILURE: {
      return {
        ...state,
        isLoadingInvoices: false,
      };
    }

    case subscriptionConstants.FETCH_USER_SEATS_PRICING_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.FETCH_USER_SEATS_PRICING_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        isLoading: false,
        userSeatsPricing: data,
      };
    }
    case subscriptionConstants.FETCH_USER_SEATS_PRICING_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case subscriptionConstants.FETCH_COMPANY_SEATS_PRICING_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SEATS_PRICING_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        isLoading: false,
        companySeatsPricing: data,
      };
    }
    case subscriptionConstants.FETCH_COMPANY_SEATS_PRICING_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case subscriptionConstants.UPDATE_STORE_SUBSCRIPTION_REQUEST: {
      const { updatedSubscription, paymentAlert } = action.payload;

      return {
        ...state,
        companySubscription: updatedSubscription,
        paymentAlert,
      };
    }
    case subscriptionConstants.CLEAR_PAYMENT_ALERT_REQUEST: {
      return {
        ...state,
        paymentAlert: null,
      };
    }
    default:
      return state;
  }
}
