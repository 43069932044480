import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IoFileTrayOutline } from 'react-icons/io5';
import classNames from 'classnames';

import ActionsColumn from './components/ActionsColumn';
import RedirectColumn from './components/RedirectColumn';
import { SPECIAL_COLUMNS } from '../../index';
import StyledFormCheck from '../StyledFormCheck';

import styles from '../../styles.module.scss';

function Body({
  data,
  columns,
  onEdit,
  editHint,
  onDelete,
  deleteHint,
  onView,
  viewHint,
  onRefresh,
  refreshHint,
  onConfirm,
  confirmHint,
  onFavorite,
  favoriteHint,
  onItemSelected,
  selectedItems,
  keyName,
  onRowClick,
  onArchive,
  onUnarchive,
  archiveHint,
  unarchiveHint,
}) {
  const renderCellValue = (column, row) => {
    const { formatter, specialType } = column;

    if (!isEmpty(specialType)) {
      if (specialType === SPECIAL_COLUMNS.ACTIONS) {
        return (
          <ActionsColumn
            id={row[column.keyName]}
            onEdit={onEdit}
            editHint={editHint}
            onDelete={onDelete}
            deleteHint={deleteHint}
            onView={onView}
            viewHint={viewHint}
            onRefresh={onRefresh}
            refreshHint={refreshHint}
            onConfirm={onConfirm}
            confirmHint={confirmHint}
            onFavorite={onFavorite}
            favoriteHint={favoriteHint}
            onArchive={onArchive}
            onUnarchive={onUnarchive}
            isArchived={!!row.archived_at}
            archiveHint={archiveHint}
            unarchiveHint={unarchiveHint}
          />
        );
      }
      if (specialType === SPECIAL_COLUMNS.REDIRECT) {
        const { openNewTab, styleOnHover, useQueryParams, storedFilter } =
          column.redirectOptions || {};

        let newPath = '';

        if (useQueryParams) {
          newPath = `${column.redirectPath}?${column.redirectKey}=${row[column.redirectKey]}`;

          if (storedFilter) {
            const storedFilterValue = new URLSearchParams(
              JSON.parse(localStorage.getItem(storedFilter)),
            ).toString();

            newPath = `${newPath}&${storedFilterValue}`;
          }
        } else {
          newPath = column.redirectPath.replace('[key]', row[column.redirectKey]);
        }

        return (
          <RedirectColumn
            id={row[column.redirectKey]}
            path={newPath}
            keyName={row[column.keyName].toUpperCase()}
            openNewTab={openNewTab}
            styleOnHover={styleOnHover}
          />
        );
      }
    }

    if (formatter && formatter instanceof Function) {
      return <span>{column.formatter(row[column.keyName], row)}</span>;
    }

    return <span>{isEmpty(row[column.keyName]) ? '-' : row[column.keyName]}</span>;
  };

  const handleItemSelected = useCallback(
    (e, selectedItem) => {
      e.stopPropagation();

      onItemSelected(selectedItem);
    },
    [onItemSelected],
  );

  const handleRowClick = useCallback(
    row => {
      if (onRowClick) {
        onRowClick(row[keyName]);
      }
    },
    [onRowClick, keyName],
  );

  return (
    <tbody>
      {isEmpty(data) && (
        <tr>
          <td className={styles.emptyColumn} colSpan={columns.length} align="center">
            <div className={styles.empty}>
              <IoFileTrayOutline size="4rem" />
              <p className={styles.emptyMessage}>Nenhum registro encontrado.</p>
            </div>
          </td>
        </tr>
      )}
      {data.map((row, rowIndex) => {
        const isActive = selectedItems.includes(row[keyName]);

        return (
          <tr key={`tr-${rowIndex}`} onClick={() => handleRowClick(row)}>
            {onItemSelected && (
              <td
                width="2%"
                className={classNames(
                  styles.cell,
                  styles.noPrint,
                  isActive ? styles.active : '',
                )}
              >
                <span>
                  <StyledFormCheck
                    type="checkbox"
                    checked={isActive}
                    onClick={e => handleItemSelected(e, row[keyName])}
                  />
                </span>
              </td>
            )}
            {columns.map((column, columnIndex) => (
              <td
                width={`${column.width}%`}
                align={column.align}
                key={`td-${rowIndex}-${columnIndex}`}
                className={classNames(
                  styles.cell,
                  isActive ? styles.active : '',
                  column.cellClass ? column.cellClass(column, row) : '',
                )}
              >
                {renderCellValue(column, row)}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
}

Body.defaultProps = {
  data: [],
  onItemSelected: null,
  onRowClick: null,
  selectedItems: [],
  editHint: null,
  deleteHint: null,
  viewHint: null,
  refreshHint: null,
  confirmHint: null,
  favoriteHint: null,
};

Body.propTypes = {
  keyName: PropTypes.string.isRequired,
  selectedItems: PropTypes.array,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onView: PropTypes.func,
  onRefresh: PropTypes.func,
  onConfirm: PropTypes.func,
  onFavorite: PropTypes.func,
  onItemSelected: PropTypes.func,
  editHint: PropTypes.string,
  deleteHint: PropTypes.string,
  viewHint: PropTypes.string,
  refreshHint: PropTypes.string,
  confirmHint: PropTypes.string,
  favoriteHint: PropTypes.string,
  onRowClick: PropTypes.func,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
};

export default Body;
