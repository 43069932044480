import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { FaExclamationCircle } from 'react-icons/fa';

import { Card } from '_components/_core';
import { LoadingIcon } from '_components/_shared';

function Fallback({ isLoading, variant }) {
  const isInSettingsPage = window.location.pathname.includes('/configuracoes');

  if (isLoading) {
    return (
      <Container id="permission-loading-message" fluid className="content-wrapper">
        <LoadingIcon text="Carregando..." />
      </Container>
    );
  }

  if (variant === 'component') {
    return (
      <>
        <Card className="pt-5 pb-4 h-80 pl-3 pr-3 mt-3">
          <div className="text-center">
            <FaExclamationCircle size={30} className="text-yellow" />
            <h4 className="mt-3">Não autorizado</h4>
            <p>Você não tem permissão para acessar este recurso.</p>
          </div>
        </Card>
        <p className="text-muted pt-3">
          Se você acredita estar vendo essa mensagem incorretamente, fale conosco em
          contato@zenply.com.br
        </p>
      </>
    );
  }

  return (
    <Container
      fluid
      className={{
        'content-wrapper': isInSettingsPage ? false : true,
      }}
    >
      <Card className="pt-5 pb-4 h-80 pl-3 pr-3">
        <div className="text-center">
          <FaExclamationCircle size={30} className="text-yellow" />
          <h4 className="mt-3">Não autorizado</h4>
          <p>Você não tem permissão para acessar este recurso.</p>
        </div>
      </Card>
      <p className="text-muted pt-3">
        Se você acredita estar vendo essa mensagem incorretamente, fale conosco em
        contato@zenply.com.br
      </p>
    </Container>
  );
}

Fallback.defaultProps = {
  isLoading: false,
  variant: 'page',
};

Fallback.propTypes = {
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOf(['page', 'component']),
};

export default Fallback;
