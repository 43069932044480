import { connect } from 'react-redux';
import { compose } from 'redux';

import subscriptionActionCreators from '_store/_actions/subscription';
import companyActionCreators from '_store/_actions/company';

import Checkout from './Checkout';

const mapStateToProps = state => ({
  isLoading: state.subscription.isLoadingSubscription,
  subscription: state.subscription.companySubscription,
  plans: state.subscription.subscriptionPlans,
});

const mapDispatchToProps = {
  onFetchPlans: subscriptionActionCreators.getCompanySubscriptionPlans,
  onFetchSubscription: subscriptionActionCreators.getCompanySubscription,
  onCreateCheckout: subscriptionActionCreators.createCheckout,
  onUpdateStoreSubscription: subscriptionActionCreators.updateStoreSubscription,
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onFetchActiveCompany: companyActionCreators.getActiveCompany,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Checkout);
