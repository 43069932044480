import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { FaBarcode } from 'react-icons/fa6';
import {
  LuArrowRight,
  LuComponent,
  LuCopy,
  LuCreditCard,
  LuPrinter,
} from 'react-icons/lu';

import FORMATTERS from 'helpers/formatters';
import { Button } from '_components/_core';

import {
  CustomCard,
  CustomCardBody,
  CustomCardFooter,
  Label,
  Value,
} from '../../../../../styles';

const PAYMENT_METHOD_ICON = {
  CREDIT_CARD: <LuCreditCard className="mr-2" />,
  PIX: <LuComponent className="mr-2" />,
  BOLETO: <FaBarcode className="mr-2" />,
};

const PAYMENT_METHOD_NAME = {
  CREDIT_CARD: 'Cartão de crédito',
  PIX: 'PIX',
  BOLETO: 'Boleto bancário',
};

function SubscriptionRenewalDateCard({ subscription, onChangeView }) {
  const renewalDate = DateTime.fromISO(subscription.dates.renew_at)
    .setLocale('pt-BR')
    .toFormat('dd/MM/yyyy');

  const info = useMemo(() => {
    const { status, pending_invoice } = subscription;

    let title = '';
    let content = () => '';
    let sideContent = () => '';
    let footerContent = () => null;

    let hasPendingInvoice = pending_invoice && pending_invoice.id;

    switch (status) {
      case 'active':
        title = 'Próximo vencimento';
        content = () => (
          <>
            {renewalDate}
            <small
              className="ml-2"
              style={{
                fontSize: '12px',
              }}
            >
              (
              {DateTime.fromISO(subscription.dates.renew_at)
                .setLocale('pt-BR')
                .toRelative()}
              ) &nbsp;
            </small>
          </>
        );
        break;
      case 'canceled':
        title = 'Cancelada em';
        content = () =>
          `${DateTime.fromISO(subscription.dates.canceled_at)
            .setLocale('pt-BR')
            .toFormat('dd/MM/yyyy')}`;
        break;
      case 'trialing':
        title = 'Teste gratuito';
        content = () =>
          `Até ${DateTime.fromISO(subscription.dates.trial_end_at)
            .setLocale('pt-BR')
            .toFormat('dd/MM/yyyy')}`;
        break;
      case 'trial-ended':
        title = 'Teste encerrado em';
        content = () =>
          `${DateTime.fromISO(subscription.dates.trial_end_at)
            .setLocale('pt-BR')
            .toFormat('dd/MM/yyyy')}`;
        break;
      case 'past_due':
        title = hasPendingInvoice ? `Fatura pendente` : 'Assinatura vencida';
        content = () => (
          <>
            <span className="d-flex justify-content-center align-items-center">
              {FORMATTERS.NUMBER(pending_invoice.total_amount)}
            </span>
          </>
        );
        sideContent = () => (
          <>
            <Button
              size="sm"
              variant="success-2"
              className="d-flex justify-content-center align-items-center"
              onClick={() => {
                window.location.href = pending_invoice.provider_payment_url;
              }}
            >
              Pagar online
              <LuArrowRight className="ml-1" />
            </Button>
          </>
        );
        footerContent = () => (
          <div
            style={{ flex: 1 }}
            className="d-flex justify-content-between align-items-center"
          >
            <small className="d-flex justify-content-center align-items-center text-muted">
              Vencimento: {FORMATTERS.DATE_DDMMYYYY(pending_invoice.due_date)}
            </small>
            &nbsp;
            <Button
              size="sm"
              variant="link"
              className="d-flex justify-content-center align-items-center p-0 m-0"
              onClick={() => {
                window.location.href = pending_invoice.provider_payment_url;
              }}
            >
              Mais detalhes
            </Button>
          </div>
        );
        break;
      case 'incomplete':
        title = hasPendingInvoice ? `Fatura pendente` : 'Assinatura incompleta';
        content = () => (
          <>
            <span className="d-flex justify-content-center align-items-center">
              {FORMATTERS.NUMBER(pending_invoice.total_amount)}
            </span>
          </>
        );
        sideContent = () => (
          <>
            <Button
              variant="link"
              className="m-0 p-0 d-flex align-items-center justify-content-center"
              onClick={() => {
                onChangeView('INVOICE_DETAILS', {
                  invoiceId: pending_invoice.id,
                  originView: 'DEFAULT',
                });
              }}
            >
              Ver detalhes
              <LuArrowRight className="ml-1" />
            </Button>
            {/* <Button
              size="sm"
              variant="success-2"
              className="d-flex justify-content-center align-items-center"
              onClick={() => {
                window.location.href = pending_invoice.provider_payment_url;
              }}
            >
              Pagar fatura
            </Button> */}
          </>
        );
        footerContent = () => (
          // <div
          //   style={{ flex: 1 }}
          //   className="d-flex justify-content-between align-items-center"
          // >
          //   <small className="d-flex justify-content-center align-items-center">
          //     {PAYMENT_METHOD_ICON[pending_invoice.payment_method]}{' '}
          //     {PAYMENT_METHOD_NAME[pending_invoice.payment_method]}
          //   </small>
          //   <small className="d-flex justify-content-center align-items-center text-muted">
          //     {pending_invoice.provider_invoice_number}
          //   </small>
          // </div>
          <div className="d-flex justify-content-start align-items-center pt-1">
            <Button
              variant="link"
              className="m-0 p-0 d-flex align-items-center justify-content-center"
              size="sm"
              onClick={() => {
                window.open(pending_invoice.metadata.pdf, '_blank');
              }}
            >
              <LuPrinter className="mr-2" />
              Imprimir boleto
            </Button>
            <Button
              variant="link"
              className="m-0 p-0 ml-3 d-flex align-items-center justify-content-center"
              size="sm"
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();

                await navigator.clipboard.writeText(
                  pending_invoice.metadata.boleto_identification_field,
                );
              }}
            >
              <LuCopy className="mr-2" />
              Copiar linha digitável
            </Button>
          </div>
        );
        break;
      default:
        break;
    }

    return {
      title,
      content,
      sideContent,
      footerContent,
    };
  }, [subscription, renewalDate]);

  if (!subscription) {
    return null;
  }

  return (
    <CustomCard>
      <CustomCardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Label>{info.title}</Label>
            <Value variant="large" className="mt-2">
              {info.content()}
            </Value>
          </div>
          <div>{info.sideContent()}</div>
        </div>
      </CustomCardBody>
      {info.footerContent() && (
        <CustomCardFooter>{info.footerContent()}</CustomCardFooter>
      )}
    </CustomCard>
  );
}

SubscriptionRenewalDateCard.defaultProps = {
  subscription: null,
};

SubscriptionRenewalDateCard.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func.isRequired,
};

export default SubscriptionRenewalDateCard;
