import { connect } from 'react-redux';
import { compose } from 'redux';

import paymentsActionsCreators from '_store/_actions/payments';
import subscriptionActionCreators from '_store/_actions/subscription';

import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Overview from './Overview';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchPayments: paymentsActionsCreators.fetchPayments,
  onFetchPlans: subscriptionActionCreators.fetchSubscriptionPlans,
  onFetchSubscription: subscriptionActionCreators.getUserSubscriptionStatus,
  onGetUpdatePaymentSessionUrl: subscriptionActionCreators.getUpdatePaymentSessionUrl,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['buy_services']),
)(Overview);
