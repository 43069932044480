import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { IoLogOutOutline } from 'react-icons/io5';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { FaArrowLeft } from 'react-icons/fa6';

import isUserMigrated from 'helpers/isUserMigrated';
import {
  getMenuItems,
  useMediaQuery,
  ZENPLY_PRIVACY_POLICY_URL,
  ZENPLY_TERMS_URL,
} from 'helpers';
import { Button } from '_components/_core';

import {
  Overview,
  Logs,
  ApiKey,
  Subscription as SubscriptionV2,
  MenuItems,
} from './components';

import Users from '../Users/UsersContainer';
import Subscription from '../Subscription/SubscriptionContainer';
import Profile from '../Profile/ProfileContainer';
import Companies from '../Companies/CompaniesContainer';
import Accounts from '../Accounts/AccountsContainer';
import Categories from '../Categories/CategoriesContainer';
import CostCenters from '../CostCenter/CostsCenterContainer';
import Tags from '../Tags/TagsContainer';
import Preferences from '../Preferences/PreferencesContainer';
import ExportFinancialData from '../ExportFinancialData/ExportFinancialDataContainer';
import DeleteFinancialData from '../DeleteFinancialData/DeleteFinancialDataContainer';
import AllowedPeriod from '../AllowedPeriod/AllowedPeriodContainer';

import { Grid, LeftPanel, RightPanel } from './styles';

function SettingsV2({ user, activeCompany, onFetchMarketSegments, onLogout }) {
  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { isMobile } = useMediaQuery();

  const [activeTab, setActiveTab] = useState(() => {
    const urlParams = new URLSearchParams(location.search);

    const tab = urlParams.get('tab');

    return tab || null;
  });

  const isMigrated = useMemo(() => {
    return isUserMigrated(user);
  }, [user]);

  const addArrowToTabs = useCallback((tabs, isMobile) => {
    if (!isMobile) return tabs;

    const arrow = <MdOutlineKeyboardArrowRight className="ml-auto text-muted" />;

    return tabs.map(tab => ({ ...tab, arrow }));
  }, []);

  useEffect(() => {
    onFetchMarketSegments();
  }, [onFetchMarketSegments]);

  useEffect(() => {
    if (location.pathname === path) {
      setActiveTab(null);
    }
  }, [location, path]);

  const handleTabChange = useCallback(
    tabId => {
      setActiveTab(tabId);

      history.push(`${path}/${tabId}`);
    },
    [history, path],
  );

  const renderRoutes = useCallback(
    () => (
      <Switch>
        <Route path={`${path}/dados-empresa`} component={Overview} />
        <Route
          path={`${path}/usuarios`}
          render={props => <Users {...props} isCompact />}
        />
        {!isMigrated && (
          <Route
            path={`${path}/assinatura`}
            render={props => <Subscription {...props} isCompact />}
          />
        )}
        {isMigrated && (
          <Route
            path={`${path}/assinatura`}
            render={props => <SubscriptionV2 {...props} isCompact />}
          />
        )}
        <Route path={`${path}/logs`} component={Logs} />
        <Route path={`${path}/integracoes`} component={ApiKey} />
        <Route
          path={`${path}/exportar-dados`}
          render={props => <ExportFinancialData {...props} isCompact />}
        />
        <Route
          path={`${path}/excluir-dados`}
          render={props => <DeleteFinancialData {...props} isCompact />}
        />
        <Route
          path={`${path}/dados-pessoais`}
          render={props => <Profile {...props} isCompact />}
        />
        <Route
          path={`${path}/empresas`}
          render={props => <Companies {...props} isCompact />}
        />
        <Route
          path={`${path}/preferencias`}
          render={props => <Preferences {...props} isCompact />}
        />
        <Route
          path={`${path}/contas-bancarias`}
          render={props => <Accounts {...props} isCompact />}
        />
        <Route
          path={`${path}/categorias`}
          render={props => <Categories {...props} isCompact />}
        />
        <Route
          path={`${path}/centros-de-custo`}
          render={props => <CostCenters {...props} isCompact />}
        />
        <Route path={`${path}/tags`} render={props => <Tags {...props} isCompact />} />

        <Route
          path={`${path}/bloquear-periodo`}
          render={props => <AllowedPeriod {...props} isCompact />}
        />

        <Route path={path} exact>
          <Container>
            <h3>Selecione uma opção</h3>
          </Container>
        </Route>
      </Switch>
    ),
    [path, isMigrated],
  );

  const isManager = useMemo(() => {
    if (!isMigrated) {
      if (!user) {
        return false;
      }

      return user.manager_id === null;
    }

    if (!activeCompany) {
      return false;
    }

    const { manager } = activeCompany;

    return manager.id === user.id;
  }, [user, activeCompany, isMigrated]);

  const isPersonalAccountType = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.manager_id === null && user.document_type === 'PF';
  }, [user]);

  const handleNavigate = useCallback(
    tabId => {
      handleTabChange(tabId);
    },
    [handleTabChange],
  );

  const handleOutsideRedirect = useCallback(redirectTo => {
    switch (redirectTo) {
      case 'HELP_CENTER':
        window.open('https://ajuda.zenply.com.br', '_blank');
        break;
      case 'SUPPORT':
        window.open('mailto:contato@zenply.com.br', '_blank');
        break;
      case 'UPDATES':
        window.open('https://novidades.zenply.com.br/', '_blank');
        break;
      case 'TERMS_OF_SERVICE':
        window.open(ZENPLY_TERMS_URL, '_blank');
        break;
      case 'PRIVACY_POLICY':
        window.open(ZENPLY_PRIVACY_POLICY_URL, '_blank');
        break;
      default:
        break;
    }
  }, []);

  const menuItems = useMemo(() => {
    const params = {
      user,
      isManager,
      isPersonalAccountType,
      isMigrated,
      activeCompany,
    };

    return getMenuItems(params);
  }, [user, isManager, isPersonalAccountType, isMigrated, activeCompany]);

  if (!activeCompany) {
    return null;
  }

  if (isMobile && activeTab) {
    return (
      <Container fluid className="content-wrapper">
        <Button
          variant="link"
          className="p-0 m-0 pb-3 d-flex align-items-center"
          onClick={() => {
            setActiveTab(null);

            history.goBack();
          }}
        >
          <FaArrowLeft className="mr-2" />
          Voltar
        </Button>
        {renderRoutes()}
      </Container>
    );
  }

  if (isMobile && !activeTab) {
    return (
      <Container fluid className="content-wrapper">
        <MenuItems
          isMobile={isMobile}
          menuItems={menuItems}
          user={user}
          onLogout={onLogout}
          onNavigate={handleNavigate}
          activeTab={activeTab}
        />
      </Container>
    );
  }

  return (
    <Container fluid className="content-wrapper">
      <Grid>
        <LeftPanel>
          <MenuItems
            isMobile={isMobile}
            menuItems={menuItems}
            user={user}
            onLogout={onLogout}
            onNavigate={handleNavigate}
            activeTab={activeTab}
          />
          <hr />
          <div className="d-flex flex-column align-items-start justify-content-center ml-3 mt-4">
            <Button
              variant="link"
              className="m-0 p-0 d-flex justify-content-center align-items-center"
              size="sm"
              onClick={() => handleOutsideRedirect('HELP_CENTER')}
            >
              Central de ajuda
              {/* <LuArrowUpRight className="ml-2" /> */}
            </Button>
            <Button
              variant="link"
              className="m-0 p-0 d-flex justify-content-center align-items-center mt-2"
              size="sm"
              onClick={() => handleOutsideRedirect('TERMS_OF_SERVICE')}
            >
              Termos de uso
              {/* <LuArrowUpRight className="ml-2" /> */}
            </Button>
            <Button
              variant="link"
              className="m-0 p-0 d-flex justify-content-center align-items-center mt-2"
              size="sm"
              onClick={() => handleOutsideRedirect('PRIVACY_POLICY')}
            >
              Política de privacidade
              {/* <LuArrowUpRight className="ml-2" /> */}
            </Button>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center ml-3 mr-3 mt-3 mb-5">
            <Button
              variant="outline-secondary"
              className="d-flex justify-content-center align-items-center w-100 mt-2 mr-3"
              size="md"
              onClick={onLogout}
            >
              Sair do sistema
              <IoLogOutOutline size="1.3em" className="text-muted ml-2" />
            </Button>
          </div>
        </LeftPanel>
        <RightPanel>{renderRoutes()}</RightPanel>
      </Grid>
    </Container>
  );
}

SettingsV2.defaultProps = {
  activeCompany: {
    manager: {},
  },
};

SettingsV2.propTypes = {
  activeCompany: PropTypes.object,
  onFetchMarketSegments: PropTypes.func.isRequired,
};

export default SettingsV2;
