import { connect } from 'react-redux';

import userActionCreators from '_store/_actions/user';
import authActionCreators from '_store/_actions/auth';

import AvatarMenuV2 from './AvatarMenuV2';

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  onLogout: authActionCreators.logout,
  onFetchUser: userActionCreators.getUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AvatarMenuV2);
