import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const getInvoice = (invoiceId, callback) => {
  return dispatch => {
    dispatch(request());

    service
      .fetchSubscriptionInvoice(invoiceId)
      .then(response => {
        const { data } = response;

        dispatch(success({ data }));

        if (callback) {
          callback(data);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: constants.GET_INVOICE_BY_ID_REQUEST,
      payload: {},
    };
  }
  function success(subscriptionStatus) {
    return {
      type: constants.GET_INVOICE_BY_ID_SUCCESS,
      payload: subscriptionStatus,
    };
  }
  function failure(error) {
    return {
      type: constants.GET_INVOICE_BY_ID_FAILURE,
      payload: { error },
    };
  }
};

export default getInvoice;
