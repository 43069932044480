import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const fetchUserSeatsPricing = () => {
  return (dispatch) => {
    dispatch(request());

    service.fetchUserSeatsPricing().then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.FETCH_USER_SEATS_PRICING_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_USER_SEATS_PRICING_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_USER_SEATS_PRICING_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default fetchUserSeatsPricing ;