import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActions from '../alert.actions';

const archiveBankAccount = (id) => {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const response = await service.archiveBankAccount(id);
      dispatch(success(response.data));
      dispatch(alertActions.success('Conta bancária arquivada com sucesso'));
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request(id) {
    return {
      type: constants.ARCHIVE_BANK_ACCOUNT_REQUEST,
      payload: { id },
    };
  }
  function success(account) {
    return {
      type: constants.ARCHIVE_BANK_ACCOUNT_SUCCESS,
      payload: account,
    };
  }
  function failure(error) {
    return {
      type: constants.ARCHIVE_BANK_ACCOUNT_FAILURE,
      payload: error,
    };
  }
};

export default archiveBankAccount;