import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaRedo, FaTimes, FaPencilAlt, FaEye, FaCheck, FaStar } from 'react-icons/fa';
import { LuArchive, LuArchiveRestore } from 'react-icons/lu';

import Button from '_components/_core/Button';

function ActionsColumn({
  id,
  onEdit,
  editHint,
  onView,
  viewHint,
  onRefresh,
  refreshHint,
  onDelete,
  deleteHint,
  onConfirm,
  confirmHint,
  onFavorite,
  favoriteHint,
  onArchive,
  onUnarchive,
  isArchived,
  archiveHint,
  unarchiveHint,
}) {
  const handleEdit = useCallback(() => {
    onEdit(id);
  }, [id, onEdit]);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const handleView = useCallback(() => {
    onView(id);
  }, [id, onView]);

  const handleRefresh = useCallback(() => {
    onRefresh(id);
  }, [id, onRefresh]);

  const handleConfirm = useCallback(() => {
    onConfirm(id);
  }, [id, onConfirm]);

  const handleFavorite = useCallback(() => {
    onFavorite(id);
  }, [id, onFavorite]);

  return (
    <>
      {onEdit && (
        <>
          <Button
            data-tip={editHint}
            variant="link"
            className="pt-0 pb-0"
            size="sm"
            onClick={handleEdit}
            name="edit-button"
          >
            <FaPencilAlt className="text-info" />
          </Button>
        </>
      )}
      {onView && (
        <>
          <Button
            data-tip={viewHint}
            variant="link"
            className="pt-0 pb-0"
            size="sm"
            onClick={handleView}
          >
            <FaEye size="1.2em" className="text-dark" />
          </Button>
        </>
      )}
      {onRefresh && (
        <>
          <Button
            data-tip={refreshHint}
            variant="link"
            className="pt-0 pb-0"
            size="sm"
            onClick={handleRefresh}
          >
            <FaRedo className="text-success" />
          </Button>
        </>
      )}
      {onDelete && (
        <>
          <Button
            data-tip={deleteHint}
            variant="link"
            className="pt-0 pb-0"
            size="sm"
            onClick={handleDelete}
            name="delete-button"
          >
            <FaTimes className="text-danger" size="1.1em" />
          </Button>
        </>
      )}
      {onConfirm && (
        <>
          <Button
            data-tip={confirmHint}
            variant="link"
            className="pt-0 pb-0"
            size="sm"
            onClick={handleConfirm}
          >
            <FaCheck className="text-success" size="1.1em" />
          </Button>
        </>
      )}
      {onFavorite && !isArchived && (
        <>
          <Button
            data-tip={favoriteHint}
            variant="link"
            className="pt-0 pb-0"
            size="sm"
            onClick={handleFavorite}
          >
            <FaStar style={{ color: '#d46b08' }} size="1.1em" />
          </Button>
        </>
      )}
      {onArchive && onUnarchive && (
        <>
          {isArchived ? (
            <Button
              data-tip={unarchiveHint}
              variant="link"
              className="pt-0 pb-0"
              size="sm"
              onClick={() => onUnarchive(id)}
            >
              <LuArchiveRestore className="text-success" size="1.2em" />
            </Button>
          ) : (
            <Button
              data-tip={archiveHint}
              variant="link"
              className="pt-0 pb-0"
              size="sm"
              onClick={() => onArchive(id)}
            >
              <LuArchive className="text-muted" size="1.2em" />
            </Button>
          )}
        </>
      )}
    </>
  );
}

ActionsColumn.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onView: PropTypes.func,
  onRefresh: PropTypes.func,
  onConfirm: PropTypes.func,
  onFavorite: PropTypes.func,
  editHint: PropTypes.string,
  deleteHint: PropTypes.string,
  viewHint: PropTypes.string,
  refreshHint: PropTypes.string,
  confirmHint: PropTypes.string,
  favoriteHint: PropTypes.string,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  isArchived: PropTypes.bool,
  archiveHint: PropTypes.string,
  unarchiveHint: PropTypes.string,
};

export default ActionsColumn;
