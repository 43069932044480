import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { LuArrowLeft, LuBuilding, LuMail, LuRocket, LuUser } from 'react-icons/lu';

import { Button } from '_components/_core';
import { useMediaQuery, ZENPLY_PRIVACY_POLICY_URL, ZENPLY_TERMS_URL } from 'helpers';

import { SignUpForm } from './components';
import { Container, Title, Text, StepsGuide, Step, FormContainer } from './styles';

const publicFolder = process.env.PUBLIC_URL;

function ZenplyForm({ segments, onSignUp, onEmailConfirmation }) {
  const history = useHistory();
  const location = useLocation();

  const { isMobile, isTablet } = useMediaQuery();

  const [selectedMenu, setSelectedMenu] = useState(() => {
    const urlParams = new URLSearchParams(location.search);

    const type = urlParams.get('type');

    if (type === 'empresarial') {
      return 'PJ';
    }

    if (type === 'pessoal') {
      return 'PF';
    }

    return 'PJ';
  });

  const [activeStep, setActiveStep] = useState(1);

  const steps = [
    {
      id: 1,
      title: 'Sobre você',
      description: 'Seu nome, sobrenome e dados de login',
      icon: <LuUser />,
    },
    {
      id: 2,
      title: 'Sobre seu negócio',
      description: 'Nome, segmento e local do seu negócio',
      icon: <LuBuilding />,
    },
    {
      id: 3,
      title: 'Valide seu e-mail',
      description: 'Preencha o código de verificação',
      icon: <LuMail />,
    },
    {
      id: 4,
      title: 'Bem vindo ao Zenply!',
      description: 'Agora você pode começar a usar o Zenply',
      icon: <LuRocket />,
    },
  ];

  const renderBackButton = () => (
    <Button
      variant="link"
      className="p-0 m-0 d-flex justify-content-center align-items-center"
      onClick={() => history.push('/entrar')}
    >
      <LuArrowLeft className="mr-2" />
      Voltar para o login
    </Button>
  );

  return (
    <Container isMobile={isMobile || isTablet}>
      <div className="left-panel">
        <img
          src={`${publicFolder}/assets/img/logo-zenply-icon.svg`}
          alt="logo"
          width={145}
          className="ml-1 mr-1"
          onClick={() => history.push('/entrar')}
        />
        <StepsGuide>
          {steps.map(step => (
            <Step key={step.id} active={step.id === activeStep}>
              <div className="icon-box">{step.icon}</div>
              <div className="step-info">
                <h5>{step.title}</h5>
                <p>{step.description}</p>
              </div>
            </Step>
          ))}
        </StepsGuide>
        <div className="footer-links-left">{renderBackButton()}</div>
      </div>
      <div className="right-panel">
        <div className="header-links-right">{renderBackButton()}</div>
        <FormContainer>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <img
              src={`${publicFolder}/assets/img/logo-zenply-icon.svg`}
              alt="logo"
              width={150}
              onClick={() => history.push('/entrar')}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div>
              <Title>{steps.find(step => step.id === activeStep).title}</Title>
              <Text>{steps.find(step => step.id === activeStep).description}</Text>
            </div>
            <div className="ml-auto">
              <small>
                Passo {activeStep} de {steps.length}
              </small>
            </div>
          </div>
          <hr />
          <SignUpForm
            step={activeStep}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            onSignUp={onSignUp}
            onEmailConfirmation={onEmailConfirmation}
            segments={segments}
            onChangeStep={setActiveStep}
          />
        </FormContainer>
        <div className="footer-links-right">
          <p className="p-0 m-0">Copyright © Zenply - {DateTime.now().year}</p>
          <div className="ml-auto">
            <Button
              variant="link"
              className="p-0 m-0 mr-1"
              onClick={() => {
                window.open(ZENPLY_TERMS_URL, '_blank');
              }}
            >
              Termos de uso
            </Button>
            <span className="text-muted">•</span>
            <Button
              variant="link"
              className="p-0 m-0 ml-1"
              onClick={() => {
                window.open(ZENPLY_PRIVACY_POLICY_URL, '_blank');
              }}
            >
              Política de privacidade
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}

ZenplyForm.defaultProps = {
  segments: [],
};

ZenplyForm.propTypes = {
  segments: PropTypes.array,
  onSignUp: PropTypes.func.isRequired,
  onEmailConfirmation: PropTypes.func.isRequired,
};

export default ZenplyForm;
